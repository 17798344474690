.optanon-show-settings-popup-wrapper {
  display: inline-block !important;
  .optanon-show-settings-button {
    display: block;
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      float: none;
      padding-top: 0;
      display: inline-block;
      .optanon-show-settings {
        font-size: 13px;
        font-weight: 300;
        text-decoration: underline !important;
        font-family: $main-font;
        color: $color-black !important;
        letter-spacing: 0.034em;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $color-black !important;
    font-size: 13px !important;
    font-family: $main-font;
    text-decoration: underline;
    text-transform: capitalize;
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(100% - 190px);
        }
        .pc-close-button {
          &:focus {
            outline: 0;
          }
        }
        #pc-title {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
