.site-header {
  position: relative;
  // This can be set to 10 in most browsers but not ie8/9 for some reason.
  z-index: 901;
  border-bottom: 1px solid $color-black;
  height: $mobile-nav-height + $mobile-top-offer-height;
  @include breakpoint($medium-up) {
    height: auto;
  }
  .site-utils__item--account {
    .site-utils__link-text {
      display: none;
    }
  }
  &__main {
    display: block;
    background-color: $color-black;
    .site-utils__item--search {
      display: inline-block;
      .site-utils__icon {
        display: inline-block;
        font-size: $base-font-size;
      }
      .site-utils__link-text {
        display: none;
      }
      .site-utils__dropdown {
        top: 16px;
      }
    }
  }
  &__offers {
    @if $cr19 == true {
      position: relative;
      z-index: 9999;
      width: 100%;
      @include breakpoint($large-up) {
        width: 50%;
      }
      @include breakpoint($xxlarge-up) {
        width: 60%;
      }
    }
  }
}
/* .js-sticky-header .site-header {
  @include breakpoint($medium-up) {
    padding-top: $utils-height;
  }
} */

.site-logo {
  display: none;
  padding: 11px 0 22px;
  text-align: center;
  margin: 0;
  @include breakpoint($medium-up) {
    display: block;
  }
  &__icon {
    font-size: 33px;
    color: $color-black;
    background: url('/media/images/global/logo.png') no-repeat 0 0 $color-black;
    text-decoration: none;
    width: 314px;
    height: 59px;
    text-indent: 9999px;
    &:hover {
      text-decoration: none;
    }
    &:before {
      content: none;
    }
  }
  &__text {
    @include hide-text;
    display: block;
  }
}

.site-nav,
.glamland-navigation {
  // MENUS:
  .menu {
    // MENU LIST:
    &__list--lvl-1 {
      // Display logic, pt. 2: show the top-level menu
      display: block;
      text-align: $ldirection;
      @include breakpoint($medium-up) {
        text-align: center;
      }
    }
    // MENU ITEMS:
    &__item {
      &--lvl-1 {
        display: block;
        @include breakpoint($medium-up) {
          height: 47px;
          line-height: get-line-height($base-font-size, 47px);
          display: inline-block;
        }
        // MENU LINKS:
        a {
          @include h8;
          cursor: pointer;
          border: none;
          position: relative;
          color: $color-black;
          text-decoration: none;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            margin: 0 7px;
            font-size: 10px;
            padding-bottom: 4px;
          }
          @include breakpoint($landscape-up) {
            margin: 0 10px;
            font-size: $base-font-size;
          }
          @include breakpoint($large-up) {
            margin: 0 13px;
            padding: 0 4px 4px;
          }
        }
        .content {
          .shop {
            @include swap_direction(padding, 18px 40px 58px 20px);
            width: 728px;
            .basic-responsive {
              margin-#{$ldirection}: 45px;
              margin-#{$rdirection}: 4px;
              margin-top: 27px;
              border: 1px solid $color-gray;
              a {
                margin: 0;
              }
            }
            .shop_treat {
              float: $ldirection;
              margin-#{$rdirection}: 20px;
              .shop-treatments {
                div:first-child {
                  @include swap_direction(padding, 0 8px 0 0);
                  margin: 0;
                }
                .menu__item--lvl-2 {
                  float: none;
                  padding-top: 9px;
                  a {
                    margin: 0 0 8px;
                  }
                  &:first-child {
                    @include breakpoint($medium-up) {
                      padding-top: 0;
                    }
                  }
                }
                .menu__item--lvl-3 {
                  margin: 0 0 8px;
                  a {
                    @include swap_direction(margin, 0 0 0 15px);
                  }
                }
              }
            }
            .pro_treat {
              margin: 0 20px;
              .pro-treatments {
                .menu__item--lvl-2 {
                  @include swap_direction(padding, 9px 8px 0 15px);
                  margin: 0 0 8px;
                  float: none;
                  &:first-child {
                    @include breakpoint($medium-up) {
                      padding-top: 0;
                    }
                  }
                }
                .menu__item--lvl-3 {
                  float: none;
                  margin: 0 0 8px;
                  a {
                    margin: 0 31px;
                  }
                }
              }
            }
          }
          .glamland {
            @include swap_direction(padding, 20px 29px 24px 40px);
            width: 453px;
            .basic-responsive {
              border: 1px solid $color-gray;
              a {
                margin: 0;
              }
            }
            .menu__item--lvl-2 {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}

.site-content,
.glamland-navigation {
  .menu__item--lvl-1 {
    text-decoration: none;
    & > a:hover {
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 2px solid $color-black;
    }
  }
}

.site-nav {
  .menu {
    // Display logic, pt. 1: hide all menus
    display: none;
    @include breakpoint($medium-up) {
      position: relative;
    }
    // MENUS:
    &--lvl-3 {
      padding: 10px 0;
      text-transform: uppercase;
      border-bottom: solid 1px #ddd;
      // Display logic, pt. 3: show all third-level menus on desktop:
      @include breakpoint($medium-up) {
        border-bottom: none;
        padding: 0 0 0 15px;
        display: block;
      }
    }
    // MENU LIST:
    &__list {
      &--lvl-2 {
        @include breakpoint($medium-up) {
          padding: 19px 20px 12px;
          width: 464px;
        }
        @include breakpoint($medium-up) {
          div:first-child {
            float: $rdirection;
          }
        }
      }
      p {
        margin: 0;
      }
    }

    // MENU ITEMS:
    &__item {
      &--lvl-1 {
        .site-utils & > a {
          color: $color-white;
        }
        &:hover {
          background-color: $color-white;
          a {
            color: #484848;
            border-bottom: 2px solid $color-black;
          }
          & > .menu__content {
            display: block;
            opacity: 1;
            visibility: visible;
            a {
              color: $color-white;
              border-bottom: none;
              padding: 0;
              &:hover {
                color: $color-black;
                border-bottom: none;
                padding: 0;
              }
            }
          }
        }
        .menu__content {
          background-color: rgba(0, 0, 0, 0.9);
          color: $color-white;
          @include breakpoint($medium-up) {
            // @include transition(opacity 0.4s ease, visibility 0.4s ease);
            border-bottom: none;
            display: none; // Using opacity and transition on desktop, not display:none
            margin: 0;
            position: absolute;
            opacity: 0;
            top: 140px; // == Level 1 item height
            visibility: hidden;
            z-index: 1;
            #{$ldirection}: 0;
            width: 100%;
          }
          @if $cr19 == true {
            @include breakpoint($large-up) {
              top: auto;
            }
          }
          .menu__list {
            @include pie-clearfix;
            max-width: $max-width;
            margin: 0 auto;
          }
        }
        // Hack to display the words bigger than the line space y two lines.
        &:nth-child(3) .menu__item--lvl-2:nth-child(3) > a {
          display: table-caption;
        }
        &:nth-child(6) .menu__item--lvl-2:nth-child(4) > a {
          display: table-caption;
        }
        &:nth-child(2) .menu__content {
          #{$ldirection}: 0;
        }
        &:nth-child(4) .menu__content {
          #{$ldirection}: -80px;
        }
        &:nth-child(5) .menu__content {
          #{$ldirection}: -21px;
        }
      }
      @include breakpoint($medium-up) {
        &--lvl-2,
        &--lvl-3 {
          max-width: 185px;
        }
      }
      &--lvl-2 {
        clear: $ldirection;
        display: block;
        text-align: $ldirection;
        line-height: get-line-height($base-font-size, 35px);
        padding-#{$ldirection}: 20px;
        color: $color-white;
        @include breakpoint($medium-up) {
          height: auto;
          margin: 0 0 9px;
          line-height: get-line-height($base-font-size, 21px);
          padding-#{$ldirection}: 0;
          float: $ldirection;
          max-width: 235px;
        }
        &:first-child,
        &.first {
          clear: none;
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 8px 0 11px);
            float: $rdirection;
          }
        }
        &:last-child,
        &.last {
          @include breakpoint($medium-up) {
            margin: 0;
          }
        }
        a {
          margin: 0;
        }
      }
      &--lvl-3 {
        clear: $ldirection;
        text-align: $ldirection;
        line-height: get-line-height($base-font-size, 35px);
        color: $color-white;
        padding-#{$ldirection}: 40px;
        @include breakpoint($medium-up) {
          line-height: inherit;
          margin: 0 0 10px;
          padding: 0;
        }
      }
      // Display logic, pt. 4: show any menu with a "selected" parent item
      &--selected {
        > .menu__link--lvl-1.menu__link--has-children,
        > .menu__link--lvl-2.menu__link--has-children {
          @include breakpoint($medium-up) {
            color: inherit;
          }
          &:after {
            @include transform(rotate(0deg));
          }
        }
        > .menu {
          display: block;
        }
      }
      @include breakpoint($medium-up) {
        &:hover > .menu {
          display: block;
          visibility: visible;
          &--lvl-2 {
            opacity: 1;
          }
        }
      }
      // MENU LINKS:
      &--lvl-1 a,
      &--lvl-2 a {
        @include h8;
        &.menu__link--has-children {
          &::after {
            @include transform(rotate(270deg));
            position: absolute;
            top: 14px;
            #{$rdirection}: 15px;
            content: '';
            width: 11px;
            height: 11px;
            background: url('#{$base-theme-path}img/icons/src/arrow_down.svg') no-repeat;
            background-size: cover;
            @include breakpoint($medium-up) {
              display: none;
            }
          }
        }
      }
      &--lvl-2,
      &--lvl-3 {
        &:hover,
        &:hover a {
          background-color: $color-white;
          color: $color-black;
          @include breakpoint($medium-up) {
            background-color: inherit;
            color: inherit;
          }
        }
        a {
          color: $color-white;
          text-decoration: none;
          text-transform: uppercase;
          border: none;
          &:hover {
            color: $color-black;
            background-color: $color-white;
          }
        }
      }
      &--lvl-2 a {
        font-size: 15px;
        position: relative;
        font-weight: 700;
        letter-spacing: 0;
        @include breakpoint($medium-up) {
          height: auto;
          font-size: 13.25px;
          line-height: get-line-height(13.25px, 15px);
          margin: 0 0 7px;
          border-bottom: none;
          padding: 0;
        }
        // Remove hover styling from level-2 link
        &:hover {
          @include breakpoint($medium-up) {
            color: $color-black;
          }
        }
      }
      &--lvl-3 a {
        font-size: $base-font-size;
        line-height: get-line-height($base-font-size, 17px);
        @include breakpoint($medium-up) {
          padding: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: get-line-height(12px, 15px);
          display: block;
        }
      }
    }
  }
  .display-desktop-up {
    display: none;
    @include breakpoint($large-up) {
      display: inline-block;
    }
  }
  .display-tablet-up {
    display: none;
    @include breakpoint($medium-up) {
      display: inline-block;
    }
  }

  // Secondary nav (mobile-only)
  .block-nodeblock:nth-child(2) {
    @if $cr19 == false {
      padding: 20px 0 50px;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .menu {
      &__item--lvl-1 {
        margin: 0 0 12px;
        &.last {
          display: block;
        }
      }
      &__link--lvl-1 {
        border: none;
        text-transform: none;
        font-size: $base-font-size;
        line-height: get-line-height($base-font-size, 19px);
        font-family: $main-font;
        letter-spacing: 1px;
        height: auto;
      }
      .icon-livechat {
        font-size: 19px;
        top: 4px;
        margin-#{$ldirection}: 7px;
      }
    }
  }
}

.site-utils {
  width: 100%;
  z-index: 10;
  position: fixed; // always fixed on mobile
  top: 0;
  #{$ldirection}: 0;
  .js-nav-expanded & {
    height: 100%;
    overflow: auto;
  }
  @if $cr19 == false {
    @include breakpoint($medium-up) {
      padding: 0 12px;
      position: absolute;
    }
  }
  @if $cr19 == true {
    @include breakpoint($large-up) {
      position: relative;
    }
  }
  &__inner {
    @include pie-clearfix;
    @include wrap;
    margin: 0 auto;
    padding: 0;
    color: $color-white;
    position: relative;
    text-align: center;
    max-width: 1340px;
    @if $cr19 == false {
      @include breakpoint($medium-up) {
        background: transparent;
        text-align: $ldirection;
      }
    }
    &::after {
      content: none;
      @include breakpoint($medium-up) {
        content: '';
      }
    }
    @if $cr19 == true {
      top: 0;
      text-align: center;
      background: $color-black;
      @include breakpoint($large-up) {
        position: absolute;
        top: auto;
        #{$rdirection}: 5%;
      }
    }
  }
  &__logo {
    width: 100%;
    margin: 0;
    display: block;
    height: $mobile-nav-height;
    background-color: $color-black;
    @include breakpoint($small-down) {
      z-index: 999;
      position: fixed;
    }
    a {
      color: $color-white;
      text-decoration: none;
    }
    @include breakpoint($medium-up) {
      display: none;
      float: $ldirection;
      width: 210px;
      height: 40px;
      margin: 7px 10px 0;
      padding: 0;
    }
  }
  &__logo-icon {
    display: inline-block;
    width: 180px;
    height: 100%;
    font-size: 20px;
    text-indent: 9999px;
    background: url('/media/images/global/logo_mobile.png') no-repeat center center $color-black;
    background-size: 100%;
    @include breakpoint($medium-up) {
      width: 210px;
      background: url('/media/images/global/logo_site_util.png') no-repeat 0 0 $color-black;
      height: 40px;
    }

    // Samsung Galaxy S3: Proprietary Browser/Android 4.3 - Logo Font Icon Fix
    @media only screen and (min-device-width: 719px) and (max-device-width: 721px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      font-size: 16px;
      width: 100%;
      background: url('/media/images/global/logo_mob.png') no-repeat center center $color-black;
      height: 100%;
    }
    @media only screen and (max-device-width: 1279px) and (max-device-width: 1281px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      font-size: $base-font-size;
      width: 100%;
      background: url('/media/images/global/logo_mob.png') no-repeat center center $color-black;
      height: 100%;
    }
    // End Fix
    &:before {
      width: 100%;
      display: block;
      content: none;
    }
    @include breakpoint($medium-up) {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 24px;
    }
  }
  &__promo {
    @include h9;
    display: none;
    font-family: $main-font;
    float: $ldirection;
    height: 31px;
    line-height: get-line-height(13px, $utils-height);
    overflow: hidden;
    .utility-promo {
      font-size: 13px;
      a {
        color: $color-white;
        text-decoration: none;
      }
    }
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__nav {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    display: none;
    width: $mobile-nav-width;
    height: 100%;
    background: transparent;
    .js-nav-expanded & {
      display: block;
    }
    @include breakpoint($medium-up) {
      @include box-shadow(none);
      display: none;
      float: $ldirection;
      position: static;
      background: transparent;
      height: auto;
      width: auto;
      overflow-y: visible;
    }
    .content {
      @if $cr19 == false {
        background-color: rgba(0, 0, 0, 0.9);
      }
      .content {
        background-color: inherit;
      }
    }
    .menu {
      &__list {
        @include breakpoint($small-down) {
          margin-top: 96px;
        }
      }
      &--lvl-1 {
        position: static;
        @include breakpoint($medium-up) {
          float: $rdirection;
        }
      }
      &--lvl-2 {
        color: $color-black;
        @include breakpoint($medium-up) {
          top: $utils-height--sticky + $utils-offset--sticky;
        }
      }
      // This class can be added to a span wrapper editorially and will be
      // detected and applied to the parent li in template.php
      &__item.static-only {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      .only-static-text {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      &__link {
        @include breakpoint($medium-up) {
          color: $color-white;
        }
        &--lvl-2,
        &--lvl-3 {
          color: $color-black;
        }
        &--lvl-2 {
          @include breakpoint($medium-up) {
            font-size: 13px;
            font-weight: bold;
          }
        }
        &--lvl-3 {
          @include breakpoint($medium-up) {
            font-size: 11px;
          }
        }
      }
      &__link--lvl-1 {
        font-size: 13px;
        height: 40px;
        line-height: get-line-height(13px, 40px);
        padding: 0 20px;
        border-bottom: solid 1px $color-black;
        @include breakpoint($medium-up) {
          padding: 0 7px;
          height: $utils-height--sticky;
          line-height: get-line-height(13px, $utils-height--sticky);
          border-bottom: none;
        }
      }
      &__item--lvl-1:first-child {
        .menu__link--lvl-1 {
          @include breakpoint($medium-up) {
            // This tiny bit of padding can cause the nav to collide with the
            // logo at tablet portrait and break to a new line, better to just
            // have it be a little too close.
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
  &__links {
    @if $cr19 == false {
      @include breakpoint($medium-up) {
        position: relative;
        height: auto;
        float: $rdirection;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__right {
    @include breakpoint($medium-up) {
      line-height: get-line-height($base-font-size, $utils-height);
      float: $rdirection;
      text-align: $rdirection;
    }
    .mobile-checkout-back {
      display: none;
    }
    @include breakpoint($small-down) {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
      @if $cr19 == true {
        top: 80px;
      }
    }
  }
  &__link {
    color: $color-white;
    @include main-font-bold;
    font-size: 11.5px;
    letter-spacing: 0;
    @include transition(
      color 0.2s ease-in-out,
      border 0.2s ease-in-out,
      background-color 0.2s ease-in-out
    );
    text-decoration: none;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 12px;
      padding: 0;
      display: block;
    }
    .site-utils__item--cart & {
      position: relative;
      display: inline-block;
    }
    @include breakpoint($medium-up) {
      .site-utils__item--cart & {
        padding: 1px 0 0 0;
      }
      .site-utils__item--account & {
        padding: 0;
      }
    }
    .site-utils__icon--search {
      display: none;
    }
    &-text {
      position: relative;
      display: none;
      top: 3px;
      @include breakpoint($medium-up) {
        position: static;
        display: inline;
        top: initial;
      }
    }
  }
  &__menu {
    width: 58%;
    margin: 0;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    @include breakpoint($medium-up) {
      margin-top: 6px;
      position: relative;
      width: auto;
      display: inline;
    }
  }
  &__item {
    // Default to hidden for mobile:
    display: none;
    list-style: none;
    line-height: get-line-height($base-font-size, 27px);
    padding-#{$ldirection}: 0;
    &:first-child {
      padding-#{$ldirection}: 0;
    }
    @include breakpoint($medium-up) {
      display: inline-block;
    }
    &--dropdown.active .site-utils__dropdown {
      opacity: 1;
      visibility: visible;
    }
    &--account {
      &.active {
        border: none;
      }
    }
    &--hamburger {
      display: block;
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: auto;
      border-#{$rdirection}: none;
      margin-#{$ldirection}: 14px;
      line-height: get-line-height($base-font-size, $mobile-nav-height);
      @include breakpoint($medium-up) {
        display: none;
        border: none;
        #{$ldirection}: 10px;
        width: auto;
        height: auto;
      }
    }
    // These are the only items visible on mobile:
    &--search,
    &--stores,
    &--cart {
      display: inline-block;
      width: 100%;
      @include breakpoint($medium-up) {
        width: unset;
        margin: unset;
        padding: unset;
      }
    }
    &--search {
      order: 4;
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
      width: auto;
      border-bottom: none;
      border-#{$ldirection}: none;
      margin-#{$rdirection}: 10%;
      line-height: get-line-height($base-font-size, $mobile-nav-height);
      @include breakpoint($medium-up) {
        position: relative;
        #{$rdirection}: auto;
        border: none;
        width: auto;
        line-height: get-line-height(13px, 27px);
        margin-#{$rdirection}: 0;
        .site-utils__link {
          font-size: 13px;
          &-text {
            font-size: 12px;
            padding-#{$rdirection}: 3px;
          }
        }
      }
      .site-utils__dropdown__arrow {
        @include breakpoint($small-down) {
          position: fixed;
          top: 100px;
          #{$ldirection}: 66.6666%;
        }
      }
      .site-utils__dropdown__close {
        display: none;
      }
      .site-utils__dropdown__close,
      .site-utils__dropdown__arrow {
        &.header-search__close {
          display: block;
          &:after {
            content: normal;
          }
        }
        @include breakpoint($medium-up) {
          display: block;
          &.header-search__close {
            display: none;
          }
        }
      }
    }
    &--stores {
      order: 1;
      -webkit-order: 1;
    }
    &--cart {
      order: 3;
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
      width: auto;
      border-bottom: none;
      border-#{$ldirection}: none;
      margin-#{$rdirection}: 16%;
      line-height: get-line-height($base-font-size, $mobile-nav-height);
      @include breakpoint($medium-up) {
        position: relative;
        #{$rdirection}: auto;
        border: none;
        width: auto;
        line-height: get-line-height($base-font-size, 27px);
        margin-#{$rdirection}: 0;
        .site-utils__link {
          font-size: 13px;
          &-text {
            font-size: 12px;
            padding-#{$rdirection}: 3px;
          }
        }
      }
      .site-utils__cart-qty {
        width: 100%;
        text-align: center;
        color: $color-white;
        font-family: $main-font;
        font-size: 12px;
        font-weight: bold;
        top: 2px;
        #{$rdirection}: 2px;
        position: absolute;
        @include breakpoint($medium-up) {
          color: $color-white;
          position: inherit;
          font-size: 13px;
          line-height: 1;
          top: 0;
          &::after {
            content: ')';
          }
          &::before {
            content: '(';
          }
          .js-sticky-header & {
            top: 23px;
            #{$ldirection}: inherit;
          }
        }
      }
    }
  }
  &__dropdown {
    @include transition(opacity 0.4s, visibility 0.4s);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 999;
    top: $utils-dropdown-height;
    #{$rdirection}: 100px;
    height: auto;
    line-height: normal;
    color: $color-black;
    text-align: $ldirection;
    &__arrow {
      position: absolute;
      top: 14px;
      width: 25px;
      height: 12px;
      background: url(/media/images/global/util-dropdown-arrow.png) no-repeat 0 0;
      z-index: 10;
      .js-sticky-header & {
        top: 26px;
      }
    }
    &__content {
      position: relative;
      margin-top: 25px;
      background: $color-white;
      @include breakpoint($medium-up) {
        border: 1px solid $color-gray-alt;
      }
      .js-sticky-header & {
        margin-top: 37px;
      }
      // The search box appears in the gnav, not the site utils area, so we hack
      .site-utils__dropdown--search & {
        background: transparent;
      }
    }
    &__close {
      position: absolute;
      height: 18px;
      width: 19px;
      top: 10px;
      #{$rdirection}: 10px;
      z-index: 10;
      cursor: pointer;
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        background: url('#{$base-theme-path}img/icons/src/close.svg') no-repeat;
        background-size: cover;
        display: block;
      }
    }
    &--search {
      .site-utils__dropdown {
        &__arrow {
          display: none;
          visibility: hidden;
          opacity: 0;
          #{$rdirection}: 27px;
          .js-sticky-header & {
            #{$rdirection}: 70px;
          }
        }
      }
    }
    &--account {
      .site-utils__dropdown__arrow {
        #{$rdirection}: 87px;
        .js-sticky-header & {
          #{$rdirection}: 42px;
        }
      }
      .site-utils--icons-count-2 &,
      .site-utils--icons-count-3 &,
      .site-utils--icons-count-4 &,
      .site-utils--icons-count-5 & {
        .site-utils__dropdown__arrow {
          #{$rdirection}: 52px;
        }
      }
    }
    &--email {
      .site-utils__dropdown {
        &__arrow {
          #{$rdirection}: 40px;
        }
        &__content {
          width: 374px;
        }
      }
      .site-utils--icons-count-2 & .site-utils__dropdown__arrow {
        #{$rdirection}: 137px;
      }
      .site-utils--icons-count-3 &,
      .site-utils--icons-count-4 &,
      .site-utils--icons-count-5 & {
        .site-utils__dropdown__arrow {
          #{$rdirection}: 96px;
        }
      }
    }
  }
  &__icon {
    display: inline-block;
    font-size: 17px;
    line-height: 1;
    padding-#{$rdirection}: 5px;
    @include breakpoint($medium-up) {
      top: -2px;
      position: relative;
      display: none;
    }
    &--hamburger {
      height: 25px;
      background: url('/media/images/global/icon-hamburger-updated.png') no-repeat center center
        transparent;
      width: 25px;
      background-size: contain;
      position: relative;
      top: 0;
      &:before {
        content: none;
      }
      @include breakpoint($medium-up) {
        padding: 0;
        font-size: 20px;
        background: none;
        width: inherit;
        &:before {
          content: inherit;
        }
      }
    }
    &--cart {
      display: inline-block;
      position: relative;
      top: -3px;
      background: url('/media/images/global/bagMobile.png') no-repeat 0 0 transparent;
      background-size: contain;
      width: 26px;
      height: 31px;
      margin: 0;
      &:before {
        content: none;
      }
      @include breakpoint($medium-up) {
        display: inline-block;
        font-size: 17px;
        width: 14px;
        height: 17px;
        margin: 0;
        background: url('/media/images/global/bag.png') no-repeat 0 0 transparent;
        background-size: inherit;
        top: 0;
      }
    }
    &--head {
      font-size: 18px;
    }
    &--marker {
      font-size: 19px;
      margin-#{$rdirection}: 1px;
      @include breakpoint($medium-up) {
        font-size: 17px;
        margin-#{$rdirection}: 0;
      }
    }
    &--search {
      position: relative;
      background: url(/media/images/global/search-updated.png) no-repeat center center transparent;
      height: 25px;
      width: 25px;
      top: 0;
      margin-#{$ldirection}: 100%;
      background-size: contain;
      &:before {
        content: '';
      }
      @include breakpoint($medium-up) {
        font-size: 17px;
        margin: 0;
        top: 1px;
        height: 18px;
        #{$ldirection}: unset;
        display: inline-block;
      }
    }
  }
  &__item--icon {
    .site-utils__icon {
      display: inline-block;
    }
    .site-utils__link-text {
      display: none;
    }
  }
  .site-footer-social-links {
    margin: 0;
    &__menu {
      background-color: $color-black;
      padding: 0;
    }
    &__item {
      margin-#{$rdirection}: 4px;
      margin-top: 8px;
      .icon {
        color: $color-white;
        text-decoration: none;
        font-size: 20px;
        &:hover {
          color: $color-light-gray;
        }
        &.icon-facebook {
          background: url('/media/images/global/icon_facebook.png') no-repeat 0 0 transparent;
          width: 13px;
          height: 26px;
          &:before {
            content: none;
          }
        }
        &.icon-twitter {
          background: url('/media/images/global/icon_twitter.png') no-repeat 0 0 transparent;
          width: 21px;
          height: 26px;
          &:before {
            content: none;
          }
        }
        &.icon-instagram {
          background: url('/media/images/global/icon_instagram.png') no-repeat 0 0 transparent;
          width: 20px;
          height: 26px;
          &:before {
            content: none;
          }
        }
        &.icon-youtube {
          background: url('/media/images/global/icon_youtube.png') no-repeat 0 0 transparent;
          width: 20px;
          height: 26px;
          &:before {
            content: none;
          }
        }
        &.icon-pinterest {
          background: url('/media/images/global/icon_pinterest.png') no-repeat 0 0 transparent;
          width: 18px;
          height: 26px;
          &:before {
            content: none;
          }
        }
      }
    }
  }
  .gnav-search-v1 {
    display: block;
    position: absolute;
    #{$rdirection}: 45px;
    top: 29px;
    @include breakpoint($medium-up) {
      position: fixed;
      top: 1px;
      #{$rdirection}: 13%;
    }
    .search-term.field {
      @include main-font-bold;
      @include swap_direction(padding, 5px 40px 5px 7px);
      height: 28px;
      width: 89%;
      letter-spacing: 0;
      font-size: $base-font-size;
      color: #b3b3b3;
      @include breakpoint($medium-up) {
        margin-top: unset;
        width: 50%;
        margin: 0 auto;
        position: relative;
      }
    }
  }

  .contextual-links-region {
    position: static;
  }
}

// Sticky header (overrides static utility nav)
.js-sticky-header .js-site-header-sticky {
  position: fixed;
  @include breakpoint($medium-up) {
    height: auto;
    position: absolute;
  }
  .site-utils {
    &__logo {
      display: block;
    }
    &__promo {
      display: none;
    }
    &__right {
      float: $rdirection;
    }
    &__links {
      @include breakpoint($medium-up) {
        line-height: get-line-height($base-font-size, $utils-height--sticky);
      }
    }
    &__link {
      position: relative;
      @include breakpoint($medium-up) {
        padding: 0 1px;
        line-height: get-line-height($base-font-size, $utils-height--sticky);
      }
    }
    &__item {
      &--hamburger {
        top: 0;
      }
      &--live-chat,
      &--stores,
      &--email,
      &--search {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      &--cart {
        top: 0;
      }
      &--search,
      &--account {
        .site-utils__link-text {
          display: none;
        }
      }
    }
  }
}

body {
  @include transition(#{$ldirection} 0.3s ease);
  position: relative;
  #{$ldirection}: 0;
  @include breakpoint($medium-up) {
    position: static;
  }
  &.js-nav-expanded {
    @include breakpoint($small-down) {
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
    #navigation_control {
      display: block;
      background: none repeat scroll 0 0 transparent;
      height: 100%;
      opacity: 0.3;
      position: absolute;
      width: 35%;
      z-index: 1000;
    }
  }
  #navigation_control {
    display: none;
  }
  // GNAV simplified on all checkout pages, excluding the cart page.
  @if $cr19 == true {
    &.opc__samples,
    &.opc__signin,
    &.opc__shipping,
    &.opc__payment,
    &.opc__review,
    &.opc__registration {
      .site-header {
        height: auto;
        @include breakpoint($medium-down) {
          position: static;
        }
        min-height: $mobile-nav-height;
        @include breakpoint($large-up) {
          min-height: $nav-height;
        }
        &__nav,
        &__meta-nav {
          display: none;
        }
        .site-logo {
          float: none;
          padding: 24px 0 0 0;
        }
      }
      > .samples-page,
      > .checkout {
        @include breakpoint($large-up) {
          margin-top: $nav-height;
        }
      }
      .site-header-menu__hamburger,
      .mobile-checkout-back {
        display: none;
      }
    }
  }
  &.account,
  &#signin,
  &#address_book,
  &#past_purchases,
  &#profile_preferences {
    .site-header {
      @include breakpoint($medium-down) {
        height: auto;
        position: fixed;
      }
    }
  }
}

// Variations (eg. for the homepage slides)
.site-header {
  background-color: $color-black;
  &__hr {
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    max-width: $max-width;
    margin: 0 auto;
  }
  &--solid {
    @include breakpoint($medium-up) {
      background-color: $color-white;
      background-color: rgba(255, 255, 255, 1);
    }
  }
  &--bordered {
    @include breakpoint($medium-up) {
      .site-header__hr {
        background-color: $color-black;
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }
  &--light {
    @include breakpoint($medium-up) {
      &.site-header--bordered .site-header__hr {
        background-color: $color-white;
        background-color: rgba(255, 255, 255, 1);
      }
      .site-logo__icon,
      .site-nav .menu__link--lvl-1 {
        color: $color-white;
      }
    }
  }
  &__nav {
    background-color: $color-white;
    display: none;
    @if $cr19 == false {
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    @if $cr19 == true {
      @include breakpoint($large-up) {
        display: block;
      }
    }
  }
  &__meta-nav {
    @if $cr19 == true {
      @include breakpoint($large-up) {
        display: flex;
        min-height: $top-offer-height;
        width: 100%;
        background-color: $color-white;
        align-items: center;
      }
    }
  }
  &__utils {
    @if $cr19 == true {
      position: relative;
      z-index: 9999;
      @include breakpoint($large-up) {
        display: block;
        width: 50%;
      }
      @include breakpoint($xxlarge-up) {
        width: 40%;
      }
    }
  }
}

.nav-checkout {
  //hid everything but logo for checkout
  .site-utils {
    @include breakpoint($medium-up) {
      @include hidden;
    }
  }
  .site-utils__promo,
  .site-utils__links {
    @include breakpoint($small-down) {
      @include hidden;
    }
  }
  .site-nav {
    @include hidden;
  }
  .site-header {
    @include breakpoint($small-down) {
      height: $mobile-checkout-nav-height;
    }
  }
}

.site-header__addtobag {
  color: $color-black;
  display: none;
  max-width: $max-width;
  &.scrolled-past {
    display: none;
    width: 100%;
    background: $color-white;
    @include breakpoint($medium-up) {
      display: table;
      margin: auto;
      border-bottom: 1px solid $color-black;
    }
  }
}

.site-header__addtobag-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
  width: 49%;

  // TODO: never style any classes starting with "js-"
  .js-sku__shade {
    margin-#{$ldirection}: -6px;
    margin-#{$rdirection}: 15px;
  }
  &.site-header__addtobag-cell--product-info {
    width: 49%;
  }
  .site-header__addtobag__image,
  .site-header__addtobag__product-info {
    display: inline-block;
  }
  .site-header__addtobag__product-info {
    margin-#{$ldirection}: 5px;
  }
  .site-header__addtobag__image {
    width: 90px;
  }
  .site-header__addtobag__product-info {
    padding-top: 25px;
    vertical-align: top;
  }
  .shade-picker__color-name {
    width: 200px;
    display: inline-block;
    margin-#{$rdirection}: 20px;
    padding-top: 0;
    border-bottom: none;
    @include breakpoint($small-up) {
      width: 250px;
    }
  }
  .site-header__addtobag-button {
    display: inline-block;
    vertical-align: top;
  }
}

.site-header__addtobag-cell--actions {
  text-align: $rdirection;
  .shade-picker__selectbox.selectBox-dropdown {
    border: 1px solid $color-black;
  }
  .shade-picker__color-name {
    .selectBox-dropdown {
      .selectBox-label {
        display: inline-block;
        padding-#{$ldirection}: 10px;
        padding-#{$rdirection}: 30px;
        text-align: $ldirection;
      }
    }
  }
}

.privacy_policy {
  text-align: center;
  background: $color-white;
  margin: 0 auto;
  color: $color-black;
  text-transform: uppercase;
  font-size: $base-font-size;
  .legal_close {
    position: absolute;
    cursor: pointer;
    top: 14px;
    font-size: 18px;
  }
}

.site-wide-banner {
  text-align: center;
  background: $color-white;
  margin: 0 auto;
  padding: 0;
  color: $color-black;
  text-transform: uppercase;
  font-size: $base-font-size;
  @include breakpoint($small-down) {
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0px);
    padding: 0;
    margin: 0;
    display: none;
  }
  @include breakpoint($small-port) {
    height: 58px;
  }
  @include breakpoint($small-portup) {
    height: 67px;
  }
  @include breakpoint($small-land) {
    height: 103px;
  }
  @include breakpoint($small-landup) {
    height: 124px;
  }
  @include breakpoint($medium-port) {
    height: 35px;
  }
  @include breakpoint($medium-land) {
    height: 47px;
  }
  @include breakpoint($large-up) {
    height: 58px;
  }
  p {
    margin: 0;
  }
  &__link {
    text-decoration: none;
  }
  &__container {
    text-align: center;
    letter-spacing: -0.01em;
  }
  &__description,
  &__image {
    display: inline-block;
    @include breakpoint($small-down) {
      display: inherit;
    }
  }
  &__image {
    img {
      width: 100%;
      display: block;
    }
  }
  &__title {
    margin-bottom: 0;
    font-size: 28px;
    line-height: get-line-height(28px, 13px);
    @include breakpoint($small-down) {
      font-size: $base-font-size;
    }
    sup {
      font-size: 71%;
      top: -0.3em;
    }
  }
  &__subtitle {
    font-size: 16px;
    padding-top: 4px;
    letter-spacing: 0.035em;
    @include breakpoint($small-down) {
      font-size: 12px;
    }
    span.capitalize {
      text-transform: capitalize;
    }
  }
  &__button {
    display: inline-block;
    position: relative;
    top: -20px;
    @include breakpoint($small-down) {
      padding-top: 10px;
      top: 0;
    }
  }
}

.device-mobile {
  #extole_zone_global_header {
    display: none;
  }
}

// Glamglow Online Desktop Timer
.countdown-clock {
  display: none;
}

.countdown_timer {
  display: inline-block;
  min-width: 200px;
  text-align: center;
  line-height: get-line-height($base-font-size, 25px);
  font-weight: 700;
  @include breakpoint($medium-up) {
    line-height: 1;
  }
  .counter-block {
    &__watch {
      min-width: 130px;
      @include breakpoint($medium-up) {
        text-align: $ldirection;
      }
    }
    &__watch,
    &__comingsoon {
      display: inline-block;
    }
  }
}

.banner-visibility {
  display: block;
}

@if $cr19 == true {
  .site-content,
  .checkout {
    margin-top: $mobile-nav-height + $mobile-top-offer-height;
    @include breakpoint($large-up) {
      margin-top: calc(#{$nav-height} + #{$top-offer-height});
    }
  }
  .site-header {
    @include transition(transform 0.7s ease);
    position: fixed;
    top: 0;
    width: 100%;
    .site-header-menu--hidden & {
      position: fixed;
      transform: translateY(-100%)
        translateY(calc(-1 * (#{$mobile-nav-height} + #{$mobile-top-offer-height} + 33px)));
    }
    &__main {
      @include breakpoint($large-up) {
        overflow: hidden;
        width: 100%;
        height: $nav-height;
      }
    }
    &__nav {
      @include breakpoint($large-up) {
        background-color: $color-black;
        float: $ldirection;
        padding-#{$ldirection}: 20px;
        width: calc(100% - 264px);
        .site-header__trustmark ~ .site-logo ~ & {
          width: calc(100% - 264px - 170px);
        }
      }
    }
    &__trustmark {
      display: none;
      @include breakpoint($large-up) {
        display: block;
        float: $ldirection;
        padding-#{$ldirection}: 30px;
        width: 170px;
      }
    }

    // Nav Menu
    .site-utils {
      background-color: $color-black;
      .search-active & {
        position: relative;
      }
      &__links {
        position: absolute;
        #{$ldirection}: 0;
      }
      &__nav {
        position: relative;
        display: block;
        z-index: 9999;
        width: auto;
        height: auto;
        float: $ldirection;
        @include breakpoint($large-up) {
          display: none;
        }
        .site-header-menu--opened & {
          position: fixed;
          #{$ldirection}: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }
      }
      &__logo {
        display: block;
        position: relative;
        float: $ldirection;
        top: 0;
        margin: 0;
        width: 100%;
        height: $mobile-nav-height;
        @include breakpoint($large-up) {
          display: none;
        }
      }
      &__logo-icon {
        background-image: url('/media/images/global/icons/logo_white.svg');
        background-position: center;
        display: inline-block;
        width: 180px;
        height: 100%;
      }
    }

    // Logo
    .site-logo {
      @include breakpoint($large-up) {
        @include swap_direction(padding, 18px 0 0 30px);
        float: $ldirection;
      }
      &__icon {
        background: url('/media/images/global/icons/logo_white.svg') no-repeat center $color-black;
        width: 234px;
        height: 42px;
      }
    }
    .site-header__trustmark ~ .site-logo {
      padding-#{$ldirection}: 0;
    }
  }
}

.svg-icon-container {
  padding: 5px;
  margin-top: 15px;
  @include breakpoint($medium-up) {
    height: 41px;
    $ldirection: 0;
    top: 16px;
    background-color: transparent;
    display: block;
    border: none;
    border-bottom: 1px solid $color-white;
    text-transform: none;
    font-size: 14px;
    color: $color-white;
    min-width: 180px;
    padding: 16px 0 0;
    margin-top: 0;
    &::placeholder {
      color: $color-white;
      font-family: $main-font;
    }
  }
  font-family: $main-font;
  @include breakpoint($xxlarge-up) {
    min-width: 212px;
  }
}

@include breakpoint($medium-up) {
  [contentEditable='true']:empty:not(:focus)::before {
    content: attr(data-text);
  }
}

.svg-search-icon {
  @include breakpoint($medium-up) {
    padding-#{$ldirection}: 4px;
  }
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background: url('#{$base-theme-path}img/icons/src/search_white_mobile.svg') center center no-repeat transparent;
    display: inline-block;
    line-height: 0;
    @include breakpoint($medium-up) {
      background: url('#{$base-theme-path}img/icons/src/search_white.svg') no-repeat left transparent;
      line-height: 20px;
      width: auto;
      height: 17px;
      padding-#{$ldirection}: 26px;
      padding-top: 1px;
    }
  }
}
