$poutmud-pink: #e60895;

.section-page-header {
  max-width: 1320px;
  background-color: $poutmud-pink;
  color: $color-white;
  letter-spacing: 0;
  margin: 0 auto;
  width: 100%;
  .page-header {
    &__inner {
      color: $color-white;
      max-width: 1320px;
    }
    &--fit-to-img .page-header__text-wrapper {
      background: url('/media/export/cms/Holiday/Poutmud/poutmud_introducing.jpg');
      background-size: 100% 100%;
      position: relative;
      max-width: none;
      margin: auto;
      padding-top: 20px;
    }
  }
  .icon-sexy {
    background: transparent url('/media/images/global/icon-sexy1.png') no-repeat scroll 0 0;
    height: 60px;
    margin-left: 103px;
    position: absolute;
    top: 73%;
    vertical-align: top;
    width: 94px;
  }
  .text--intro {
    padding: 0 20px;
    &__header_title {
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0;
      margin-left: 0;
      padding-top: 16px;
      text-align: center;
      text-transform: uppercase;
      text-align: inherit;
    }
    &__sub_header {
      line-height: 30px;
      margin-left: 0;
      text-align: center;
      text-transform: uppercase;
      text-align: inherit;
      strong {
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0;
      }
      .trade-character {
        position: absolute;
      }
      &.text--intro__subtitle {
        margin-top: 0;
      }
      .subheader1 {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0;
        margin-left: 5px;
      }
      .subheader2 {
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0;
        margin-left: 0;
      }
      .subheader3 {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0;
        margin-left: 5px;
      }
    }
    &__description .intro_results {
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0;
      margin-left: 0;
      .icon-sexy {
        margin-left: 10px;
        top: inherit;
        position: relative;
      }
    }
    .shop_button {
      margin-top: 0;
      a {
        background: url(/media/images/global/arrow_right_white.png) no-repeat center right 11px $color-black;
        border: 1px solid $color-black;
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        font-family: $main-font;
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1;
        padding: 16px 52px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
      }
    }
    &.left,
    .left {
      text-align: left;
      clear: both;
    }
    &.right,
    .right {
      text-align: right;
      clear: both;
    }
    &.center,
    .center {
      text-align: center;
      clear: both;
    }
  }
}

@include breakpoint($large-up) {
  .section-page-header {
    .page-header__text-wrapper {
      margin-left: 256px;
      max-width: 810px;
      padding-top: 20px;
      margin: auto;
      width: 100%;
      max-width: none;
    }
    .text--intro {
      &__header_title {
        font-size: 35px;
        margin-bottom: 24px;
      }
      &__sub_header {
        margin-top: 59px;
        strong {
          font-size: 99px;
        }
        .trade-character {
          font-size: 25px;
          top: 89px;
        }
        .subheader1 {
          font-size: 41px;
          line-height: 60px;
        }
        .subheader2 {
          font-size: 57px;
          line-height: 35px;
        }
        .subheader3 {
          font-size: 40px;
          line-height: 40px;
        }
      }
      &__description .intro_results {
        font-size: 29px;
        line-height: 35px;
        margin-bottom: 10px;
        margin-top: 22px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .section-page-header {
    .text--intro {
      &__header_title {
        margin: 5px 0;
        padding: 0;
      }
      .shop_button a {
        font-size: inherit;
        padding: 5px 27px 6px 13px;
      }
      &__description {
        .intro_results {
          margin-bottom: 5px;
        }
        .icon-sexy {
          background-size: 50%;
        }
      }
      font-size: 12px;
    }
  }
}

@include breakpoint($small-landscape-range) {
  .section-page-header {
    .text--intro {
      &__description .intro_results .icon-sexy {
        height: 30px;
      }
      &__sub_header {
        strong {
          font-size: 50px;
          line-height: 50px;
        }
      }
      font-size: 14px;
    }
  }
}

@include breakpoint($small-potrait-range) {
  .section-page-header {
    .page-header__text-wrapper {
      padding: 5px 0;
    }
    .text--intro {
      padding: 0 5px;
      &__sub_header {
        line-height: normal;
        font-size: 1em;
        strong {
          font-size: 36px;
        }
      }
      &__header_title {
        font-size: 1.2em;
        margin: 0 0 5px;
      }
      &__description .intro_results .icon-sexy {
        height: 30px;
      }
    }
  }
}

@include breakpoint($medium-potrait-range) {
  .section-page-header {
    .page-header__text-wrapper {
      padding-left: 49px;
      padding-top: 0;
    }
    .text--intro {
      &__header_title {
        font-size: 24px;
        margin-bottom: 10px;
      }
      &__sub_header {
        margin-top: 30px;
        strong {
          font-size: 66px;
        }
        .trade-character {
          font-size: 17px;
          top: 45px;
        }
        .subheader1 {
          font-size: 18px;
        }
        .subheader2 {
          font-size: 25px;
        }
        .subheader3 {
          font-size: 18px;
        }
      }
      &__description .intro_results {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 5px;
      }
      .shop_button {
        margin-left: -21px;
        margin-top: 0;
      }
      .button {
        font-size: 20px;
        padding: 10px 37px;
      }
    }
  }
}

@include breakpoint($medium-landscape-range) {
  .section-page-header {
    .text--intro {
      &__header_title {
        font-size: 26px;
        margin-bottom: 10px;
      }
      &__sub_header {
        margin-top: 30px;
        strong {
          font-size: 68px;
        }
        .trade-character {
          font-size: 19px;
          top: 23%;
        }
        .subheader1 {
          font-size: 20px;
        }
        .subheader2 {
          font-size: 27px;
        }
        .subheader3 {
          font-size: 20px;
        }
      }
      &__description .intro_results {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .shop_button {
        margin-left: -21px;
        margin-top: 0;
      }
      .button {
        font-size: 20px;
        padding: 10px 37px;
      }
    }
  }
}
