.gnav-search-v1 {
  position: relative;
  top: 91px;
  @if $cr19 == true {
    top: 171px;
  }
  @include breakpoint($landscape-up) {
    bottom: 48px;
    top: unset;
  }
  @include breakpoint($medium-up) {
    height: 0;
  }
  .typeahead-wrapper {
    background-color: $color-white;
    @include breakpoint($medium-up) {
      background-color: unset;
    }
  }
  .product {
    &__image-wrapper {
      width: 35%;
      padding: 1px;
      @include breakpoint($medium-up) {
        display: unset;
        width: unset;
      }
    }
    &--typeahead {
      list-style-type: none;
      width: 100%;
      display: flex;
      border: 1px solid $color-light-gray;
      border-bottom: none;
      @include breakpoint($medium-up) {
        border: 1px solid $color-black;
        display: block;
      }
      @include breakpoint($landscape-up) {
        display: inline-block;
        float: left;
        width: 19%;
        border: none;
        padding: 16px 5px;
      }
    }
    &--typeahead.last {
      border-bottom: 1px solid $color-light-gray;
      @include breakpoint($medium-up) {
        border-bottom: unset;
      }
    }
    &__abstract {
      width: 65%;
      display: inline-block;
      margin: 6%;
      position: relative;
      height: 60px;
      @include breakpoint($medium-up) {
        text-align: left;
        height: unset;
        max-width: 250px;
        margin: unset;
        margin-top: unset;
        position: unset;
        display: block;
        width: unset;
        padding-left: unset;
      }
    }
    &__headline {
      font-weight: bold;
    }
    &__headline a {
      text-decoration: none;
    }
    &__description {
      font-size: 11px;
      width: 100%;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: 32px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include breakpoint($medium-up) {
        display: block;
        overflow: unset;
        text-overflow: unset;
        max-height: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
      }
    }
    &__rating {
      display: none;
    }
    &-results {
      background: $color-white;
      color: $color-black;
      margin-top: 44px;
      @include breakpoint($medium-up) {
        display: flex;
        justify-content: center;
        margin-top: unset;
      }
    }
    &__image {
      display: inline-block;
      @include breakpoint($medium-up) {
        width: 306px;
        float: left;
        display: block;
      }
    }
    &__price {
      display: none;
    }
  }
  .icon-search {
    color: $color-gray;
    margin-top: 8px;
    position: absolute;
    left: 7.5%;
    @include breakpoint($medium-up) {
      left: 6.5%;
    }
  }
}

.search-term.field {
  height: 28px;
  width: 88%;
  border-radius: 0;
  padding: 14px 34px;
  margin: 0 6%;
  &.search-result--show {
    display: block;
  }
}

.see-results li {
  padding-right: 35px;
  @include breakpoint($medium-up) {
    padding: 3px 57px 0 0;
  }
}

.see-results {
  background: $color-black;
  height: 32px;
  margin: 2px 6%;
  position: absolute;
  top: 77px;
  width: 88%;
  padding-top: 4px;
  @include breakpoint($medium-up) {
    margin: 0 auto;
    position: unset;
    padding-top: unset;
    top: unset;
    width: unset;
  }
  text-align: center;
  border: 2px solid $color-white;
  display: none;
}

.search-suggestions {
  &__item {
    list-style-type: none;
  }
  &__link {
    color: $color-white;
  }
}

.form-type-textfield {
  width: 100%;
  background: $color-black;
  border: 1px solid $color-white;
  margin-top: 1px;
  padding: 21px 0;
  @include breakpoint($landscape-up) {
    display: inline-flex;
    justify-content: center;
    margin-top: unset;
  }
}

.search-overlay {
  &__close {
    position: absolute;
    top: 29px;
    right: 9%;
    color: $color-black;
    @include breakpoint($landscape-up) {
      right: 7%;
    }
  }
}

.icon-arrow_left {
  color: $color-white;
  @include breakpoint($landscape-up) {
    display: none;
  }
}
