.module-mpp-quick-shop {
  background: #fff;
  position: relative;
  top: -20px;
  z-index: 1002;
  &.show-quick-shop {
    display: block;
  }
}

.module-mpp-quick-shop__container {
  @include clearfix;
  background: image-url('global/bg-vertical-line.png') repeat-y top center #fff;
  border: 1px solid #d0d0d0;
  position: relative;
  margin: 10px 0;
}

.module-mpp-quick-shop__photos {
  width: 50%;
  float: left;
}

.module-mpp-quick-shop__photos__main {
  width: 100%;
  height: auto;
  padding: 22px 22px 5px;
}

.module-mpp-quick-shop__photos__thumbs {
  padding: 5px 17px 22px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    @include clearfix;
    li {
      @include float-left;
      height: auto;
      padding: 0 5px 10px 5px;
      vertical-align: top;
      width: 25%;
      a {
        display: block;
      }
    }
  }
}

.module-mpp-quick-shop__details {
  width: 50%;
  float: right;
  .content {
    padding: 22px;
  }
}

.module-mpp-quick-shop__title {
  @include h8;
  font-family: $main-font;
  text-transform: uppercase;
}

.module-mpp-quick-shop__rating {
  @include h8;
  text-transform: inherit;
  font-family: $main-font;
  padding: 10px 0;
}

.module-mpp-quick-shop__shades {
  @include clearfix;
  @include h8;
  font-family: $main-font;
  text-transform: inherit;
  border-top: 1px solid #000;
  padding: 10px 0;
  p {
    padding: 6px 0;
    margin: 0;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 3px;
      margin: 3px;
      @include breakpoint(portrait) {
        margin: 3px 2px;
      }
      float: left;
      overflow: visible;
      display: block;
    }
    a {
      display: block;
    }
  }
  .colorshade {
    @include circle(41px, #999);
    @include transition(all 0.2s ease-in-out);
    display: block;
    border: 1px solid #fff;
    @include box-shadow(#fff 0 0 1px 1px);
    &.selected {
      border: 1px solid #fff;
      @include box-shadow(#000 0 0 1px 1px);
    }
    &:hover {
      border: 1px solid #fff;
      @include box-shadow(#000 0 0 1px 1px);
    }
    // IE8 does not support box-shadow
    .lt-ie9 & {
      &.selected,
      &:hover {
        border: 1px solid #000;
      }
    }
  }
}

.module-mpp-quick-shop__color-name {
  @include h8;
  padding: 6px 0 0 0;
  .selectBox-dropdown {
    min-width: 100%;
    border-left: none;
    border-right: none;
    .selectBox-label {
      min-width: 100%;
      padding: 0;
    }
  }
  .touch & select {
    min-width: 100%;
    border-left: none;
    border-right: none;
    padding: 0;
    height: 40px;
  }
}

.color-name-selectBox-dropdown-menu {
  background: #fff;
}

.module-mpp-quick-shop__info {
  @include pie-clearfix;
  border-bottom: 1px solid #000;
  padding: 10px 0;
}

.module-mpp-quick-shop__weight {
  @include h9;
  float: left;
}

.module-mpp-quick-shop__price {
  @include h8;
  letter-spacing: 0.24em;
  float: right;
}

.module-mpp-quick-shop__addtobag {
  @include clearfix;
  text-align: right;
  padding: 30px 0;
  margin-bottom: 30%;
}

.module-mpp-quick-shop__favorite {
  padding-right: 10px;
  font-size: 18px;
  span {
    display: inline-block;
    text-align: center;
    min-width: 22px;
    vertical-align: middle;
  }
  .icon {
    color: #000;
    &:hover {
      color: $color-pink;
    }
    &.icon-heart {
      color: $color-pink;
    }
  }
}

.module-mpp-quick-shop__button {
}

.module-mpp-quick-shop__more-info {
  background: $color-light-brown;
  font-size: 13px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 50%;
  margin-left: 1px;
  @include breakpoint(landscape) {
    //letter-spacing: 0.1em;
  }
  @include breakpoint(portrait) {
    width: 100%;
    left: 0;
    border-top: 1px solid #d0d0d0;
    margin-left: 0;
  }
  .content {
    padding: 22px;
  }
  strong {
    font-weight: normal;
    font-family: $main-font;
  }
}
