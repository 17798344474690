.section-shop-all {
  .reg,
  .trade {
    font-size: 50%;
    font-weight: 300;
    top: -0.6em;
  }
  .shop-all-carousel {
    .beauty-counter__description {
      strong {
        @include breakpoint($medium-up) {
          font-size: 18px;
        }
      }
    }
  }
  .dual-product-section {
    background-repeat: no-repeat;
    background-size: inherit;
    margin: 0 auto 15px;
    &__inner {
      @include breakpoint($medium-up) {
        max-width: 1024px;
      }
    }
    &__product {
      &__title {
        display: block;
      }
      &__description {
        @include breakpoint($medium-up) {
          font-size: 20px;
        }
      }
    }
    &__headline__subheader {
      font-weight: 400px;
    }
    &__description {
      text-transform: none;
      @include breakpoint($medium-up) {
        text-transform: uppercase;
      }
    }
    &__content--dark .dual-product-section__product-right {
      background: url('/media/export/cms/shop_landing/bg_gradient_shop_all.png') no-repeat scroll left center;
      background-size: auto;
      border: none;
      &:before {
        border: none;
        background: none;
      }
    }
    &__content--light .dual-product-section__product-right {
      background: url('/media/export/cms/shop_landing/bg_gradient_white_shop_all.png') no-repeat scroll left center;
      background-size: auto;
      border: none;
      &:before {
        border: none;
        background: none;
      }
    }
  }
  .poutmud {
    .dual-product-section__product__title {
      background: transparent none repeat scroll 0 0;
      margin: 0;
    }
    .dual-product-section__products {
      @include breakpoint($medium-up) {
        padding-top: 10px;
      }
    }
  }
  .brightening {
    .dual-product-section {
      &__product {
        &__title {
          background: transparent linear-gradient(90deg, transparent, #fdb783, #fdb783, transparent);
        }
        &-right {
          display: none;
          @include breakpoint($medium-up) {
            display: block;
          }
          .dual-product-section__product {
            &__title {
              background: transparent;
              @include breakpoint($medium-up) {
                height: 57px;
              }
            }
            &__description {
              text-align: left;
              @include breakpoint($medium-up) {
                margin-bottom: 49px;
                margin-left: 28px;
                strong {
                  font-size: 35px;
                }
              }
            }
          }
        }
      }
      &__header--image {
        background: linear-gradient(90deg, transparent, #fdb783, #fdb783, transparent);
      }
    }
  }
  .text--poutmud {
    font-weight: 700;
    font-size: 30px;
    padding-top: 20px;
    @include breakpoint($medium-up) {
      font-size: 39px;
    }
  }
  .sexy_lips {
    .dual-product-section {
      &__product {
        &-right {
          &::before {
            width: 50%;
            @include breakpoint($medium-up) {
              width: 100%;
            }
          }
        }
        &__title {
          background: transparent;
          margin: 0;
        }
        &__header,
        &__subheader {
          font-size: 13px;
          font-weight: 300;
          font-weight: 300;
          margin-right: 12.5%;
          text-align: right;
        }
      }
      &__header--image {
        background: linear-gradient(90deg, transparent, #ee59b6, #ee59b6, transparent);
      }
      &__headline__subheader {
        text-transform: none;
      }
    }
  }
  .eyes_brightmud .dual-product-section__product-right {
    .dual-product-section {
      &__product {
        &__title {
          background: transparent;
          font-weight: 300;
          @include breakpoint($medium-up) {
            font-size: 33px;
            padding: 14px 0;
          }
        }
        &__header {
          font-weight: 300;
          @include breakpoint($medium-up) {
            font-size: 33px;
          }
        }
      }
    }
  }
  .anti-ageing .dual-product-section__product__description {
    margin-left: 0;
  }
}

//Mobile Specific Responsive Styles
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .section-shop-all {
    .dual-product-section {
      &__content--dark .dual-product-section__product-right {
        // background-image: url('/media/export/cms/shop_landing/Mobile/backgrounds/problems_skin_daily_2.png');
        background-position: 0 100%;
        background-size: 100%;
        &:before {
          background-image: linear-gradient(to right, transparent, $color-gray-dark-alt, transparent);
        }
      }
      &__content--light .dual-product-section__product-right {
        // background-image: url('/media/export/cms/shop_landing/Mobile/backgrounds/anti_ageing_daily_2.png');
        background-position: 0 100%;
        background-size: 100%;
        &:before {
          background-image: linear-gradient(to right, transparent, $color-gray-light, transparent);
        }
      }
      &__product {
        &__description {
          margin-left: 24%;
        }
      }
      &__buttons {
        margin-left: 26%;
        .button,
        .viewcart-buttons .go-shopping,
        .recommended-item__button {
          font-size: 15px;
          font-weight: 700;
          padding: 8px 35px 6px 18px;
        }
      }
      .reg {
        top: -0.9em;
      }
      .trade {
        top: -0.8em;
      }
    }
    .anti-ageing {
      .dual-product-section {
        &__product__description {
          margin-left: 30%;
        }
        &__buttons {
          .button,
          .viewcart-buttons .go-shopping,
          .recommended-item__button {
            font-size: 15px;
            font-weight: 700;
            padding: 8px 35px 6px 18px;
          }
        }
      }
    }
    .brightening {
      .dual-product-section {
        &__product__description {
          .trade {
            font-size: 60%;
            top: -1em;
          }
        }
      }
    }
    .sexy_lips {
      .dual-product-section {
        &__product {
          &__description {
            margin-left: 29%;
          }
        }
        &__product__description {
          .trade {
            font-size: 50%;
            top: -0.7em;
          }
        }
        &__buttons {
          margin-left: 39%;
        }
      }
    }
    .hydration {
      .dual-product-section {
        &__product__description {
          .trade {
            font-size: 61%;
            top: -1em;
          }
        }
      }
    }
    .deep-cleansing {
      .dual-product-section {
        &__product__description {
          .trade {
            font-size: 70%;
          }
        }
      }
    }
    .eyes {
      .dual-product-section {
        &__product {
          &__description {
            margin-left: 29%;
          }
        }
        &__buttons {
          margin-left: 34%;
        }
        &__product__description {
          .reg {
            font-size: 55%;
            top: -1.2em;
          }
        }
      }
    }
  }
}
