@mixin opc_updates__checkout-payment {
  .opc__payment {
    .card-number {
      position: relative;
      .related-media {
        position: absolute;
        top: 9px;
        #{$rdirection}: 10px;
      }
    }
    .expires-month {
      width: 66.666%;
      float: $ldirection;
      .expires-date {
        &__month,
        &__year {
          width: 47%;
          float: $ldirection;
          margin-#{$rdirection}: 3%;
        }
      }
    }
    .cvv {
      width: 33.333%;
      float: $ldirection;
    }
    .alternate-address-links {
      float: $ldirection;
      width: 100%;
      &__link-wrapper {
        position: absolute;
        #{$rdirection}: 0;
        top: 10px;
      }
    }
    .payment-panel {
      .payment-container {
        .paypal-address {
          margin-bottom: 1em;
        }
        .address {
          .delivery-address,
          .existing-address,
          .saved-address,
          .new-address {
            float: $ldirection;
            width: 100%;
            margin: 10px 0 0;
            .country label {
              display: none !important;
            }
          }
        }
        .select-address {
          overflow: hidden;
          position: relative;
          .address-to-use {
            margin-top: 10px;
            .radio {
              margin: 5px 0;
            }
          }
        }
        .checkout__subtitle {
          margin-bottom: 0px;
        }
        .checkout_giftcard {
          padding: 15px;
        }
        .address-form {
          overflow: visible;
        }
        .payment-control {
          &__add-new-method-link {
            padding-bottom: 18px;
            border-bottom: 1px solid;
            float: $ldirection;
            width: 100%;
          }
        }
        .form-container {
          .first-name,
          .last-name {
            width: 49%;
            float: $ldirection;
            margin-#{$rdirection}: 2%;
          }
          .last-name {
            margin-#{$rdirection}: 0;
          }
        }
        .expires-month {
          .expiration_month {
            height: 3em;
          }
        }
      }
      .delivery-address {
        .customer-name {
          padding: 5px 0;
          font-weight: bold;
        }
      }
      .saved-payments {
        .payment-method-id {
          display: block;
          .payment-method-id {
            &__option {
              padding: 18px 0;
              border-bottom: 1px solid $color-black;
              position: relative;
              &-new-method {
                border: none;
              }
              label {
                padding-#{$ldirection}: 30px;
                &:before {
                  position: absolute;
                  #{$ldirection}: 0px;
                  top: 20px;
                }
                .payment-cc-info__card-expires {
                  display: block;
                }
                p {
                  margin-bottom: 0;
                  margin-top: 3px;
                }
              }
              .payment-cc-info {
                &__customer-name {
                  text-transform: uppercase;
                  font-size: 15px;
                  display: block;
                }
                &__cc-image {
                  float: $ldirection;
                  margin-top: 4px;
                  margin-#{$rdirection}: 10px;
                }
              }
              a.link {
                position: absolute;
                top: 8px;
                #{$rdirection}: 0;
                border-bottom: 1px solid $color-gray-border-section;
                padding: 0;
                margin-top: 15px;
                line-height: get-line-height(14px, 13px);
              }
            }
          }
        }
      }
      .sub-section {
        .alternate-address-links {
          a.alternate-address-links__link {
            text-transform: uppercase;
          }
        }
      }
      .save-options {
        .do-not-save-payment {
          display: none;
        }
      }
    }
    .payment-giftcard {
      margin-bottom: 40px;
    }
    &.index {
      .adpl {
        label {
          &.hidden {
            display: none !important;
          }
        }
      }
    }
  }
}
