.site-nav,
.glamland-navigation {
  .menu__item--lvl-1 {
    .content {
      .new-and-sexy {
        padding: 20px 29px 24px 40px;
        width: 537px;
        .basic-responsive {
          img {
            border: 1px solid $color-gray;
          }
          a {
            margin: 0;
          }
        }
        .menu__item--lvl-2 {
          margin-top: -2px;
        }
      }
    }
    &:nth-child(3) .menu__content {
      left: -80px;
    }
    &:nth-child(4) {
      .menu__content {
        left: -11px;
      }
      .basic-responsive {
        margin-left: -17px;
      }
    } /* holiday nav image */
    &:nth-child(5) {
      .basic-responsive {
        @include breakpoint($large-up) {
          margin-left: -17px;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .site-utils__nav {
    .menu__list {
      letter-spacing: 0;
    }
    .menu__item--lvl-2 {
      margin-left: 16px;
      padding-left: 4px;
      width: 60%;
      a {
        font-weight: 500;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.login-signup {
        margin-top: 10px;
      }
    }
    .menu__item--lvl-3 {
      padding-left: 4px;
      margin-left: 36px;
      width: 52.3%;
    }
  }
  .product-thumb {
    border-bottom: none;
    &__headline-link,
    &__price {
      font-weight: 400;
    }
    &--price {
      color: $color-gray-dark-alt;
    }
    .button.product__button--add-to-bag {
      font-size: 12px;
      color: $color-white;
      padding-top: 6px;
      height: 40px;
      margin: 0;
      border: 1px solid $color-black;
      background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px
        $color-black;
      &:hover {
        color: $color-black;
        background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px
          $color-white;
      }
    }
  }
  .site-header {
    .loyalty-signup {
      .email-signup-overlay {
        &__close {
          display: inline-block;
          background: none;
        }
        &__headline p {
          font-size: 28px;
        }
      }
    }
  }
}

@include breakpoint($medium-up) {
  .product-thumb--price {
    color: $color-black;
  }
  .site-header {
    .site-utils__promo {
      .content {
        .site-wide-banner {
          visibility: hidden;
        }
      }
    }
  }
}

.product--full {
  .product__weight,
  .product__unit-price {
    visibility: hidden;
  }
}

.spp {
  .product--full {
    // .product__unit-price,
    .product__weight {
      visibility: visible;
    }
  }
}

.no-touch {
  .esearch_results--pc {
    .product-thumb__button {
      position: static;
    }
    .product-thumb__headline {
      min-height: 42px;
    }
  }
  .product-grid__content {
    .product-grid__item {
      .product-thumb__abstract {
        transform: none !important;
      }
    }
  }
}

.section-store-locator {
  .store-locator__controls {
    a.selectBox {
      height: 30px;
      line-height: 30px;
    }
    input.search {
      height: 30px;
    }
    .search-submit {
      background: #ffffff url('/media/images/global/arrow_right_black.png') no-repeat scroll right
        11px center;
      margin: 0 0 0 5px;
      font-weight: bold;
      line-height: normal;
      height: 30px;
    }
  }
}

.how-to-use__wrap .how-to-use__ingredients {
  display: block;
}

.site-content {
  .bazaarvoice_ratings_n_reviews.resp-tab-content {
    display: block;
  }
}

#lpChat .lp_radio_button input[type='radio'] {
  position: absolute;
}

.endeca-product-result {
  .product-thumb__abstract {
    .sku_count {
      @include breakpoint($small-down) {
        font-size: 13px;
        color: $color-gray-dark-alt;
      }
      font-size: 14px;
      margin: 4px 0 6px 0;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
}

.mpp__content {
  .product-grid__item {
    .product-thumb__cta {
      .desktop-text {
        @include breakpoint($medium-up) {
          font-weight: normal;
        }
      }
    }
    .product-thumb__abstract__inner {
      & > a {
        @include breakpoint($small-down) {
          text-transform: uppercase;
          color: $color-gray-dark-alt;
        }
      }
    }
    .text-shop__hide {
      display: none;
    }
    .product-thumb--price {
      @include breakpoint($small-down) {
        color: $color-black;
      }
    }
  }
}

#search-wrapper {
  #product-results {
    .product-thumb__button {
      display: none;
    }
    .product-thumb {
      .product-thumb--price {
        @include breakpoint($small-down) {
          height: auto;
          line-height: normal;
        }
        & span {
          font-weight: bold;
          font-size: 15px;
        }
      }
    }
    .product-thumb__headline {
      & a {
        @include breakpoint($small-down) {
          font-weight: bold;
          font-size: 15px;
          color: $color-black;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  #cboxLoadedContent {
    .popup-offer {
      .popup-offer__body {
        .popup-offer__body-text {
          font-size: 22px;
        }
      }
      .popup-offer__submit-wrap {
        .popup-offer__email-input {
          width: 278px;
          border: 0;
          border-radius: 0;
          margin-right: 0;
        }
        .popup-offer__submit-input {
          width: 278px;
        }
      }
      padding: 15px;
    }
  }
}

.site-wide-banner {
  .site-wide-banner__container {
    padding: 14px 0 10px;
    @include breakpoint($small-down) {
      padding: 20px 0 0;
    }
    & p {
      line-height: 1.6em;
    }
  }
  .site-wide-banner__description {
    padding: 0 60px;
    position: relative;
    @include breakpoint($small-down) {
      padding: 0;
    }
  }
  .site-wide-banner__title {
    @include breakpoint($medium-up) {
      max-width: 830px;
    }
    @include breakpoint($small-down) {
      padding: 0 35px;
    }
    & strong a {
      text-align: center;
      display: inline-block;
    }
    & p {
      text-align: center;
    }
    .site-wide-banner__link {
      &:hover {
        text-decoration: none;
      }
    }
    letter-spacing: 1px;
  }
  .site-wide-banner__subtitle {
    padding-top: 0;
  }
  .site-wide-banner__button {
    & span {
      display: none;
    }
  }
  #cboxClose {
    top: 0;
    right: -31px;
    position: absolute;
    text-decoration: none;
    padding: 0px;
    @include breakpoint($small-down) {
      right: 10px;
    }
  }
  @include breakpoint($small-down) {
    .site-wide-banner {
      position: fixed;
      top: 0;
    }
  }
}

.sign-in-overlay {
  .sign-in-overlay__login-container {
    margin-top: 15px;
    margin-bottom: 15px;
    .sign-in-overlay__input {
      input[type='submit'] {
        margin: 10px 0 0;
      }
    }
    .facebook-login {
      padding: 10px 0 0;
    }
  }
}

.facebook-login {
  &__container {
    padding-bottom: 0;
    .facebook-login__fb-btn {
      padding-bottom: 0;
    }
    display: flex;
    flex-direction: column;
    .facebook-login {
      &__divider {
        margin-bottom: 10px;
      }
      &__email-opt-in {
        margin-top: 0;
        label {
          vertical-align: middle;
        }
      }
      &__terms {
        margin-top: 0;
        line-height: 1.45;
      }
    }
  }
  &__legal-text {
    order: 1;
    text-align: #{$ldirection};
  }
  &__email-container {
    order: 2;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  &__fb-btn {
    order: 3;
  }
}

.email-signup-overlay {
  &__email-content {
    input.email-signup-overlay__field {
      margin-bottom: 10px;
    }
  }
  &__legal-text {
    margin-bottom: 15px;
    a {
      text-decoration: underline !important;
    }
  }
}

#colorbox {
  &.popup_offer_bg {
    height: 500px !important;
    @include breakpoint($medium-up) {
      height: 470px !important;
    }
    #cboxWrapper,
    #cboxContent,
    #cboxLoadedContent {
      height: 500px !important;
      @include breakpoint($medium-up) {
        height: 470px !important;
      }
      .popup-offer {
        &__body {
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 90px 0 30px 0);
            width: 100%;
          }
        }
        &__footer {
          top: auto;
        }
        a.popup-link {
          text-decoration: underline;
          border: 0;
          letter-spacing: 1px;
          font-size: 15.17px;
          color: $color-gray-light;
          padding: 0;
        }
      }
    }
  }
  #cboxContent {
    background-position-y: -20px;
  }
}

html:not([lang='en-US']) {
  .LPMcontainer {
    visibility: hidden;
  }
}

.site-footer {
  &__middle-row {
    .site-footer__column {
      &:first-child {
        padding-top: 20px;
        @include breakpoint($medium-up) {
          padding-top: 0;
        }
      }
      .links-behind-terms-guarenteed {
        width: 100%;
        padding-#{$ldirection}: 0;
        .ftr_links {
          padding-bottom: 5px;
        }
        .optanon-show-settings {
          font-size: 12px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
