.glamland-navigation {
  .menu {
    &__item {
      &--lvl-1 {
        a {
          margin-right: 537px;
          font-size: 16px;
        }
      }
    }
  }
}

.second-paragraph {
  .about-glam {
    &__story {
      &-glam {
        margin-top: 15px;
        &__content {
          padding-top: 25px;
        }
      }
    }
  }
}
