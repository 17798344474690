@import 'search_vars';
@import '../../10-base/helpers';

.search-form {
  padding: 10px 20px 0 20px;
  background: $color-black;
  border-top: 1px solid $color-white;
  // background: rgba(240, 240, 240, 0.95);
  @include breakpoint($medium-up) {
    background: $color-white;
    background: rgba(255, 255, 255, 0.95);
    padding: 25px 43px 25px 25px;
    border: none;
    width: 744px;
  }
  &__fields {
    @include clearfix;
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
    font-family: $main-font;
    font-weight: 500;
    letter-spacing: 0.14em;
  }
  input[type='text'].search-form__field {
    border: 0;
    // border-bottom: 1px solid $color-black;
    border-radius: 0;
    width: 100%;
    // background: transparent;
    height: auto;
    padding: 0;
    text-transform: uppercase;
    line-height: 35px;
    letter-spacing: 1.5px;
    padding: 0 30px 0 20px;
    &:focus {
      border-color: $color-black;
    }
    @include breakpoint($medium-up) {
      border: 1px solid $color-black;
      width: $pc-search-width;
      padding: 0 7px;
      float: left;
      height: 35px;
      &:focus {
        border-color: $color-black;
      }
    }
  }
  input[type='submit'].search-form__submit {
    display: none;
    @include breakpoint($medium-up) {
      height: 35px;
      line-height: 35px;
      display: block;
      width: 117px;
      margin: 0 0 0 5px;
      float: left;
    }
  }
  &__results {
    @include breakpoint($medium-up) {
      width: $pc-search-width; // width of text field
    }
  }
  .typeahead-wrapper {
    text-align: left;
    @include breakpoint($medium-up) {
      width: $pc-search-width; // width of text field
    }
  }
  .search-suggestions__link {
    @include h8;
    font-size: 14px;
    display: block;
    line-height: 35px;
    padding: 0 7px 0 0;
    @include breakpoint($medium-up) {
      padding: 0 7px;
    }
  }
  // .search-suggestions__item:last-child .search-suggestions__link {
  //   border-bottom: none;
  //   @include breakpoint($medium-up) {
  //     border-bottom: solid 1px $color-gray-alt;
  //   }
  // }
  .highlighted {
    color: $color-white;
  }
  .result {
    @include clearfix;
    padding: 8px 0;
    border-bottom: solid 1px $color-gray-alt;
    @include breakpoint($medium-up) {
      padding: 15px 0;
    }
    a,
    a:active {
      text-decoration: none;
      color: $color-white;
    }
  }
  .product {
    &__image {
      width: 80px;
    }
    &__image-wrapper,
    &__abstract {
      display: block;
      float: left;
    }
    &__image-wrapper {
      margin-right: 15px;
    }
    &__abstract {
      @include h9;
      width: 289px;
    }
    &__headline {
      @include h15;
      margin-bottom: 10px;
      a {
        color: $base-font-color;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    &__description {
      margin-bottom: 6px;
      line-height: normal; // JGA - not crazy about having to do this
      p {
        margin-bottom: 8px; // overrides default typography
        line-height: normal; // JGA - not crazy about having to do this
      }
    }
    &__price {
      @include h15;
      float: right;
      text-align: right;
      width: 70px;
      font-weight: bold;
    }
    // @TODO: re-enable this once the rating stars are working
    &__rating {
      display: none;
    }
  }
  .search-related-stories {
    margin-top: 15px;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .search-related-story {
    @include clearfix;
    color: $color-white;
    background-color: $color-black;
    height: 80px;
    a {
      color: $color-white;
    }
    &__image-wrapper {
      float: left;
      margin-right: 15px;
    }
    &__abstract {
      padding: 10px 20px 0 0;
    }
    &__headline {
      @include h15;
      margin-bottom: 10px;
    }
    &__description {
      @include h9;
      margin-bottom: 6px;
    }
  }
  &--results {
    margin: 0 auto;
    position: relative;
    min-height: $mobile_search_header_height; // Same height as .search-results__header--mobile. We do this so that it completely covers it.
    @include breakpoint($medium-up) {
      min-height: auto;
    }
    .search-form__results-count {
      @include h9;
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      right: 169px;
      // background-color: $color-white;
      height: 35px;
      line-height: 35px;
      padding: 0 12px;
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 20px;
  }
}

.search-results-spacer {
  height: 100px;
}

.search-results__more-link-wrapper {
  text-align: center;
  padding-bottom: 33px;
}

.search-results__header--mobile {
  background: $color-light-brown;
  text-align: center;
  height: $mobile_search_header_height;
  line-height: $mobile_search_header_height;
}

.search-results__header--desktop {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

@include breakpoint($medium-up) {
  .search-results__header--mobile {
    display: none;
  }
}

// endeca body class
body.section-esearch {
  // Always hide the search dropdown on the non-mobile dedicated search page,
  // reuse the static field instead.
  @include breakpoint($medium-up) {
    .site-utils__dropdown {
      display: none !important;
    }
  }
}

.endeca-product-result {
  .product__button--add-to-bag {
    display: none;
  }
  // &--1 means shaded, &--0 means un-shaded
  &--0 {
    @include breakpoint($small-down) {
      .product__button--quickshop {
        display: none;
      }
    }
  }
}

// Summary emptly page:
.summary-search-form,
.summary-contact-us {
  background: transparent linear-gradient(0deg, #f0f0f0, transparent);
  border: 1px solid #b9b9b9;
  display: inline-block;
  padding: 30px 20px;
  margin: 10px;
  @include breakpoint($medium-up) {
    width: 49%;
    margin: 0;
  }
  input.search-again,
  a.contact-us {
    @include button--inverted;
  }
  input.search-term,
  .search-again.form-submit,
  a.contact-us {
    height: 32px;
    line-height: normal;
    @include breakpoint($small-down) {
      margin-top: 10px;
      width: 100%;
    }
  }
  input.search-term {
    border: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      width: 300px;
    }
  }
  .label {
    @include breakpoint($medium-up) {
      font-size: 21px;
    }
    margin-bottom: 21px;
  }
}

.summary-contact-us {
  @include breakpoint($medium-up) {
    float: right;
  }
  .icon-contact-us {
    float: left;
    padding-right: 35px;
    &::before {
      content: url('/media/export/images/endeca/icon-contact-us.png');
    }
  }
}

.summary-search-form {
  .icon-search-again {
    float: left;
    &::before {
      content: url('/media/export/images/endeca/icon-search.png');
    }
  }
}
