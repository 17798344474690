@mixin opc__update__page--checkout {
  .checkout-progress-bar {
    &__list {
      overflow: hidden;
      counter-reset: step;
      width: 100%;
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
    }
    &__list-item {
      color: $color-gray;
      width: 33.33%;
      float: $ldirection;
      position: relative;
      text-align: center;
      font-size: 12px;
      line-height: get-line-height(12px, 15px);
      border: none;
      a {
        border-bottom: none;
        color: $color-gray-dark-alt;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 11px;
        &:hover,
        &:visited {
          color: $color-gray-dark-alt;
          text-decoration: none;
        }
      }
      &::before {
        content: counter(step);
        counter-increment: step;
        width: 16px;
        line-height: get-line-height(8px, 16px);
        font-size: 8px;
        display: block;
        color: $color-gray-dark-alt;
        background: $color-gray-dark-alt;
        margin: 0 auto 5px;
        @include border-radius(8px);
        @include box-shadow($color-white 0 0 0 5px);
      }
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background: $color-black;
        position: absolute;
        #{$ldirection}: -50%;
        top: 8px;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &--complete {
        color: $color-gray-dark-alt;
        font-weight: bold;
        a {
          color: $color-gray-dark-alt;
          &:hover,
          &:visited {
            color: $color-gray-dark-alt;
          }
        }
        &::before {
          background: $color-gray-dark-alt;
          color: $color-gray-dark-alt;
        }
        &.active {
          a {
            color: $color-dark-pink;
            &:hover,
            &:visited {
              color: $color-dark-pink;
            }
          }
          &::before {
            background: $color-dark-pink;
            color: $color-dark-pink;
          }
        }
      }
    }
  }
  .confirm.opc__registration {
    .checkout-confirmation-page {
      margin: 0;
      padding: 0;
      .message {
        margin: 0 1em 1em;
      }
      .banner_top {
        display: none;
      }
      .confirmation-panel {
        padding: 0 15px;
        &__title {
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid $color-black;
        }
        &__content p {
          padding: 0 10px;
        }
        &__actions {
          display: none;
        }
      }
      .registration-panel {
        &__title {
          padding: 10px 20px;
          background-color: $color-gray-background;
          border-top: none;
          font-weight: bold;
        }
        .content {
          padding: 0 25px;
        }
      }
      .sign-in-confirmation-panel__title {
        padding: 1em;
        background-color: $color-gray-background;
        border-top: 1px solid $color-black;
        line-height: get-line-height(14px, 26px);
        text-indent: 0;
      }
      .checkout__panel-content {
        .checkout__subtitle {
          display: none;
        }
      }
      .checkout-registration {
        &__email-address {
          span {
            border: none;
            float: $ldirection;
            margin: 0;
          }
        }
      }
      .continue-button-wrapper {
        padding: 15px 0 0;
      }
      .email-address {
        float: $ldirection;
      }
      .single-message {
        margin: 1em 1.5em;
      }
    }
  }
}
