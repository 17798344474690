.gdpr-checkbox {
  &-label {
    text-transform: none;
  }
}

.site-footer {
  @include breakpoint($small-down) {
    .gdpr-checkbox {
      margin-top: 1em;
    }
  }
}

.tooltip {
  &-container {
    position: relative;
    text-transform: none;
    margin: 0.5em 0;
    cursor: pointer;
    &:focus,
    &:active {
      .tooltip-overlay {
        visibility: visible;
      }
    }
  }
  &-text {
    text-decoration: underline;
  }
  &-overlay {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    visibility: hidden;
    text-decoration: none;
    border: 1px solid $color-black;
    background-color: $color-white;
    font-size: 0.75em;
    padding: 1rem;
    cursor: text;
  }
  &-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.3em;
    font-size: 1.25em;
    cursor: pointer;
  }
}

.info-icon {
  &-background {
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    color: $color-white;
    text-align: center;
  }
  &-background & {
    &-text {
      padding-bottom: 0.5em;
    }
  }
}
