// Open Sans Regular
@font-face {
  font-family: 'Open-Sans-Regular';
  src: url('#{$netstorage-fonts-path}OpenSans/OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Open Sans Medium
@font-face {
  font-family: 'Open-Sans-Medium';
  src: url('#{$netstorage-fonts-path}OpenSans/OpenSans-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Open Sans Bold
@font-face {
  font-family: 'Open-Sans-Bold';
  src: url('#{$netstorage-fonts-path}OpenSans/OpenSans-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}