.node-elc-spp,
.spp,
.node-spp-related-content-item-set {
  .page-header {
    background-color: transparent; // Default page background
    &__inner {
      padding: 0;
      max-width: $max-width-pages;
    }
    &.page-header--fit-to-img {
      .page-header {
        &__inner {
          max-width: 100%;
        }
        &__img {
          width: 100%;
        }
      }
    }
  }
}

.spp {
  &_wrap {
    padding: 0;
    max-width: $max-width-pages;
    margin: 0 auto;
    position: relative;
  }
  .product--full {
    margin-bottom: 42px;
    margin-top: 50px;
  }
  .spp-page__breadcrumb {
    padding-bottom: 6px;
    padding-top: 17px;
    a {
      @include h9;
      color: $color-gray-dark;
      text-decoration: none;
      text-transform: uppercase;
      &:after {
        content: ' /';
      }
      &:last-child {
        color: #000;
        &:after {
          content: '';
        }
      }
    }
  }
  .instagram-feed {
    width: 100%;
    .section-header {
      width: 100%;
      &__inner {
        @include main-font-bold;
      }
      @include breakpoint($medium-up) {
        margin-bottom: 39px;
        &__inner {
          font-size: 21px;
        }
        .start {
          vertical-align: bottom;
          background-color: $color-white;
        }
      }
    }
  }

  // SPP Desktop Styles
  @include breakpoint($tablet) {
    // margin-top: 16px;
    .product-gallery {
      margin-top: 20px;
      padding-top: 22px;
    }
    .product__sku-categories {
      padding: 10px 0;
    }
    .product__sku-categories-nav-item {
      text-transform: none;
      font-family: $main-font;
      font-size: 14px;
      margin-bottom: 0;
      padding: 12px;
      letter-spacing: 1px;
      margin-bottom: 15px;
      line-height: 1em;
    }
    .shade-picker__shades {
      padding: 0;
    }
  }
  .responsive-tabs--how_to_use-wrap {
    .resp-tab-item {
      letter-spacing: 4px;
      margin-bottom: 0;
      padding: 8px 20px;
    }
    .resp-tabs-list {
      margin-bottom: 15px;
    }
  }
  .responsive-tabs--how_to_use {
    text-align: center;
  }
  &__related-item {
    margin-bottom: 20px;
  }
  @include breakpoint($small-down) {
    .product--full {
      padding: 0 20px;
      margin-bottom: 0;
      .product__actions {
        padding: 17px 0;
      }
    }
    .responsive-tabs--how_to_use-wrap {
      margin-top: 0;
    }
    .product__details .resp-accordion {
      display: none;
    }
    .resp-accordion {
      position: relative;
    }
    .responsive-tabs--bazaarvoice {
      display: none !important; //there is an inline display:block on this element
    }
  }

  // Reuse the existing desktop block for share icons
  &-mobile-page {
    &.wrap {
      padding: 0 5px;
    }
    .instagram-feed {
      .section-header {
        width: 100%;
        margin-top: 30px;
        &__inner {
          @include main-font-bold;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
    .spp__share {
      @include h8;
      text-transform: uppercase;
      border: none;
      padding: 10px 20px;
      position: relative;
      background: $color-white;
      .product__social {
        display: block;
        float: right;
        a {
          display: inline-block;
          margin-left: 12px;
          font-size: 18px;
          color: $color-black;
          text-decoration: none;
          &:hover {
            color: $color-pink;
          }
        }
      } // end .product__social
    } // end .spp__share
  } // end &-mobile-page
  &__works-with {
    .section-header__inner {
      @include main-font-bold;
      font-size: 21px;
      letter-spacing: 0;
    }
    .product-thumb {
      // height: 410px;
      &__headline-link {
        @include main-font-book;
        font-size: 15px;
        letter-spacing: 0;
      }
      &--price {
        @include main-font-book;
        font-size: 14px;
        letter-spacing: 0;
        color: #666666;
        line-height: 24px;
      }
      &__abstract {
        @include breakpoint($medium-up) {
          padding-top: 10px;
        }
      }
      &__badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 8px;
        color: $color-dark-pink;
        font-weight: 700;
        text-align: left;
        z-index: 1;
        @include breakpoint($medium-up) {
          padding: 10px;
        }
        @include breakpoint($large-up) {
          padding: 10px 24px;
        }
      }
    }
    @include breakpoint($medium-up) {
      .product-grid {
        &__content {
          max-width: 100%;
        }
        &__item {
          margin-right: 4%;
          padding: 0;
          width: 22%;
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
      .fluid-image {
        max-height: 268px;
        width: auto;
        height: auto;
      }
    }
  }
}

.spp-hero {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  &__bg_inner {
    color: $color-white;
    margin: 0 auto;
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    text-indent: -9999px;
    @include breakpoint($medium-up) {
      height: 459px;
    }
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    color: $color-black;
    text-decoration: none;
    text-indent: 9999px;
  }
} //.spp-hero
