.node-elc-mpp,
.mpp {
  .page-header {
    background-color: transparent; // Default page background
    &__inner {
      padding: 0;
      max-width: 100%;
    }
    &__text-wrapper {
      @include breakpoint($small-down) {
        padding: 10px 0;
      }
    }
  }
}

.mpp {
  @include breakpoint($medium-up) {
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
  }
  .mpp-page__heading {
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-dark-pink;
    padding: 8% 0 0 5%;
    text-align: left;
    @include breakpoint($medium-up) {
      padding: 25px 0 10px 0;
    }
  }
  .mpp-page__breadcrumb {
    display: none;
    padding-bottom: 6px;
    padding-top: 17px;
    a {
      @include h9;
      color: $color-gray-dark;
      text-decoration: none;
      text-transform: uppercase;
      &:after {
        content: ' /';
      }
      &:last-child {
        color: #000;
        &:after {
          content: '';
        }
      }
    }
    @include breakpoint($small-down) {
      padding: 0 6%;
      a {
        font-size: 11px;
      }
    }
  }
  .product-thumb {
    &__subheading,
    &--price {
      font-size: 12px;
      line-height: 15px;
    }
    &__subheading {
      padding-top: 15px;
      @include breakpoint($medium-up) {
        padding-top: 10px;
        line-height: 19px;
      }
    }
    &--price {
      padding-bottom: 5px;
      margin-top: 13px;
      @include breakpoint($medium-up) {
        padding-bottom: 5px;
        font-size: 15px;
        line-height: 20px;
        margin-top: 9px;
      }
    }
  }
  &__filters {
    background: $color-light-brown;
    border-top: 1px solid $color-white;
    &__no-matches {
      display: none;
      color: red;
      padding: 50px 20px 0;
      font-family: $main-font-bold;
      text-align: center;
    }
    &__container {
      @include wrap;
      @include pie-clearfix;
      background: $color-light-brown;
      border-top: 1px solid $color-black;
      margin-top: -1px;
      padding: 20px 0;
      @include breakpoint($medium-up) {
        border-top: none;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: 0;
      }
    }
    &__summary {
      @include h9;
      display: none;
      text-align: center;
      width: 100%;
      @include breakpoint($medium-up) {
        position: absolute;
        top: 28px;
      }
      .icon {
        font-size: 17px;
        top: 3px;
      }
      .mpp__filters__summary-all {
        display: none;
      }
      &--empty {
        .mpp__filters__summary-all {
          display: inline;
        }
        .mpp__filters__summary-text {
          display: none;
        }
      }
    }
    &__trigger {
      position: absolute;
      right: 20px;
      top: 13px;
      color: $color-black;
      font-size: 30px;
      cursor: pointer;
      @include breakpoint($medium-up) {
        top: 24px;
      }
      .icon {
        display: inline-block;
        // Original design:
        // @include breakpoint($medium-up) {
        //   @include transform(rotate(-180deg));
        // }
      }
      &.active {
        .icon {
          @include transform(rotate(270deg));
          // Original design:
          // @include breakpoint($medium-up) {
          //   @include transform(rotate(0));
          // }
        }
      }
    }
    &__headline {
      @include h8;
      margin: 0 20px 20px;
      @include breakpoint($medium-up) {
        margin: 0;
        padding: 0 0 0 20px;
        width: 190px;
        float: left;
      }
    }
    &__nav-container {
      // This is what the padding *should* be, according to the designs, but
      // they were missing an item so we have to compress everything a bit to
      // get them not to break to a new line at 320px.
      // padding: 0 17px;
      padding: 0 0 0 10px;
      // This looks a little better when there's enough space for all the items:
      @media screen and (min-width: 450px) {
        padding-left: 10%;
      }
      @include breakpoint($medium-up) {
        // float: left;
        // width: 75%;
        position: relative;
        top: -5px;
        padding: 0 0 0 190px;
      }
      @media screen and (min-width: 830px) {
        padding-left: 25%;
      }
    }
    .product-filter {
      text-align: left;
      position: relative;
      &__label {
        @include h9;
        display: block;
        margin: 0 0 13px 0;
        @include breakpoint($medium-up) {
          width: 125px;
          padding-left: 25px;
          position: absolute;
          top: 6px;
          float: left;
          // width: 20%;
          // display: inline-block;
          margin: 0 12px 0 0;
        }
      }
      &__item {
        background-color: $color-light-brown;
        // When filters break to a new line on mobile they specifically asked to
        // not have this nice spacing, which is why the margin is set on &__list.
        // margin-bottom: 20px;
      }
      &__list {
        margin-bottom: 20px;
        text-align: left;
        @include breakpoint($medium-up) {
          // width: 80%;
          // float: right;
          display: inline-block;
          width: auto;
          padding-left: 137px;
        }
      }
      &__filter {
        padding: 0 8px; // Helps squeeze filters onto one line. Use 0 12px to match design.
        @include breakpoint($medium-up) {
          padding: 0 12px;
        }
      }
    }
    &--collapsed {
      .mpp__filters__nav-container {
        display: none;
      }
      .mpp__filters__headline {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          width: auto;
        }
      }
      @include breakpoint($medium-up) {
        .mpp__filters__container {
          padding-bottom: 30px;
        }
      }
      .mpp__filters__summary {
        display: block;
        top: 13px;
        @include breakpoint($medium-up) {
          margin-top: 13px;
        }
      }
    }
    &--sticky {
      overflow: hidden;
      position: fixed;
      top: 54px;
      width: 100%;
      display: block;
      z-index: 900; //underneath the header which is 901
      background: none;
      border-top: none;
      @include breakpoint($medium-up) {
        padding-left: 12px;
        padding-right: 12px;
        top: 69px;
      }
      .mpp__filters__headline {
        @include breakpoint($medium-up) {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
      .mpp__filters__container {
        //padding: 10px 0;
        padding-top: 10px;
        padding-bottom: 19px;
        border-top: none;
        margin-top: 0;
        @include breakpoint($medium-up) {
          padding-bottom: 10px;
        }
      }
      .mpp__filters__trigger {
        font-size: 30px;
        top: 11px;
        @include breakpoint($medium-up) {
          font-size: 18px;
          margin-top: 3px;
          // top: auto;
        }
      }
      .mpp__filters__nav-container {
        top: 5px;
      }
      .mpp__filters__list {
        margin-bottom: 10px;
      }
      .mpp__filters__summary {
        margin-top: 4px;
        @include breakpoint($medium-up) {
          margin-top: 5px;
        }
      }
    }
  }
}

.mpp,
.esearch_results--pc {
  .product-grid {
    padding: 20px 0 50px;
    .product-thumb {
      height: 100%;
      &__abstract {
        text-align: left;
      }
      &__headline,
      &__headline-link {
        line-height: 20px;
        font-size: 15px;
        font-weight: bold;
      }
      &__button {
        display: none;
      }
      &--price {
        height: auto;
        padding-top: 4px;
        line-height: 20px;
        font-size: 15px;
        font-weight: bold;
      }
      &__item {
        margin: 0 0 20px;
        float: left;
        padding: 0 3%;
        width: 50%;
        min-height: 400px;
      }
      &__badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        color: $color-dark-pink;
        font-weight: 700;
        text-align: left;
        z-index: 1;
      }
    }
    @include breakpoint($medium-up) {
      &__item {
        padding: 0 12px 30px 12px;
        float: left;
        width: 33.333%;
        min-height: 550px;
      }
      &__content {
        margin: 0;
      }
      .product-thumb {
        padding-bottom: 25px;
        &__image-wrapper img {
          height: auto;
          position: relative;
        }
        &__cta,
        &.active {
          opacity: 1;
          visibility: visible;
          position: static;
          .product-thumb__quickshop {
            transform: inherit;
            position: absolute;
            bottom: 0px;
            left: 0px;
            top: auto;
            background: transparent;
            color: #000;
            padding: 0px;
            border: 0px;
            text-decoration: none;
            .desktop-text {
              border-bottom: 1px solid $color-light-gray;
            }
          }
        }
      }
    }
  }
}

.product {
  &__flag {
    text-transform: uppercase;
    color: $color-pink;
    font-size: 10px;
    letter-spacing: 0.24em;
    font-family: $main-font-bold;
    margin-bottom: 2px;
    @include breakpoint($medium-up) {
      font-size: 12px;
    }
  }
}
