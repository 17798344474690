///
/// @file base/_helpers.scss
///
/// Presentational classes
///

.hidden {
  @include hidden;
}

.pc-hidden {
  @include breakpoint($large-up) {
    display: none !important;
  }
}

///
/// Hide only visually, but have it available for screenreaders: h5bp.com/v
///

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/// Extends the .visuallyhidden class to allow the element to be focusable
/// when navigated to via the keyboard: h5bp.com/p
///

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

///
/// Hide visually and from screenreaders, but maintain layout
///

.invisible {
  visibility: hidden;
}

///
/// Clearfix: contain floats
///
/// For modern browsers
/// 1. The space content is one way to avoid an Opera bug when the
///    `contenteditable` attribute is included anywhere else in the document.
///    Otherwise it causes space to appear at the top and bottom of elements
///    that receive the `clearfix` class.
/// 2. The use of `table` rather than `block` is only necessary if using
///    `:before` to contain the top-margins of child elements.
///

.clearfix:before,
.clearfix:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

///
/// Text Alignment
///
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

///
/// Floats & Clearing floats
///
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both,
.clear {
  clear: both;
}

///
/// Fluid Images for responsive designs
///
.fluid-image {
  max-width: 100%;
  width: 100%;
  height: auto;
  @include breakpoint($medium-up) {
    height: 100%;
    width: 100%;
  }
}

.preload {
  display: none;
}

// 2019 Redesign
// @todo - move to content_block.scss when is available.
.content-block {
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: auto;
  }
}

// 2019 Redesign
// Text Alignment
.text-align--left {
  text-align: left;
}

.text-align--center {
  text-align: center;
}

.text-align--right {
  text-align: right;
}

// 2019 Redesign
// Mantle helpers
.mantle-media-asset {
  width: 100%;
  height: 100%;
  img,
  picture,
  video {
    max-width: 100%;
    width: 100%;
  }
}

// 2019 Redesign
// percentages helper
$width-percentages: 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
$width-percentages-unit: unquote('%');

@each $percentage in $width-percentages {
  .max-width-#{$percentage} {
    max-width: #{$percentage}$width-percentages-unit;
  }
  // Margins - percentages
  .margin-right-#{$percentage} {
    margin-right: #{$percentage}$width-percentages-unit;
  }
  .margin-left-#{$percentage} {
    margin-left: #{$percentage}$width-percentages-unit;
  }
  .margin-top-#{$percentage} {
    margin-top: #{$percentage}$width-percentages-unit;
  }
  .margin-bottom-#{$percentage} {
    margin-bottom: #{$percentage}$width-percentages-unit;
  }
}

// 2019 Redesign
// Flex alignment helper classes
.horizontal-align-left,
.horizontal-align-default,
.horizontal-align-start,
.align-items-start {
  align-items: flex-start;
}

.horizontal-align-center,
.align-items-center {
  align-items: center;
}

.horizontal-align-right,
.horizontal-align-end,
.align-items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center,
.justify-default {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

// Container max widths
// The following are selectable choices in content blocks via the width_rules_v(X) partial

.container-max-width {
  &-full {
    @include container-max-width-full;
  }
  &-large {
    @include container-max-width-landscape;
  }
  &-desktop-x-wide {
    @include container-max-width-desktop-x-wide;
  }
  &-desktop-wide {
    @include container-max-width-desktop-wide;
  }
  &-desktop {
    @include container-max-width-desktop;
  }
  &-tablet {
    @include container-max-width-tablet;
  }
}

// Container max widths padding rules
.container-max-width--padded {
  @include container-padding-rules;
}
