.account-overlay,
.email-overlay {
  width: 370px;
  text-align: left;
  background: $color-white;
  border: 1px solid $color-gray-tooltip;
  .icon-close {
    position: absolute;
    right: 8px;
    top: 8px;
    color: $color-gray;
  }
  input[type='submit'] {
    display: block;
    width: 100%;
    margin-top: 20px;
    border: none;
  }
  .account-overlay__next {
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    .account-overlay__next-trigger {
      @extend input[type='submit'];
      margin: 0;
      margin-top: 20px;
      text-transform: uppercase;
      background: $color-white;
      border: none;
      color: $color-black;
      display: block;
      width: 100%;
    }
  }
  .account-overlay__section,
  .overlay__container--email {
    padding: 20px 25px;
  }
  .account-inline,
  .overlay__container--email {
    font-size: 14px;
    .account-inline__title {
      line-height: 1em;
      margin-bottom: 20px;
    }
    .field-container {
      margin-bottom: 10px;
    }
    .link--regular {
      padding-bottom: 0;
    }
    input[type='text'],
    input[type='password'] {
      width: 100%;
    }
  }
}

.email-overlay {
  input[type='text'] {
    margin-top: 20px;
  }
}

.account-overlay {
  .account-register__field-container {
    input[type='text'],
    input[type='password'] {
      width: 50%;
    }
    label {
      margin-left: 12px;
      float: right;
      margin-top: 4px;
    }
  }
}

.tooltipster-base.overlay {
  .tooltipster-arrow {
    z-index: 9999;
    height: 0;
    span {
      width: 25px;
      height: 12px;
      background: image-url('/media/images/global/tooltip-arrow.png');
      background-repeat: no-repeat;
      border: none !important;
      top: -11px;
    }
  }
}

// Youtube Video Overlay
.colorbox__youtube {
  overflow: visible;
  #cboxContent {
    padding: 5px;
  }
  &#colorbox,
  #cboxWrapper,
  #cboxContent {
    overflow: visible;
  }
  &#cboxOverlay {
    opacity: 0.68;
    background-color: black;
  }
  #cboxClose {
    background-color: black;
    border-color: white;
    border-radius: 18px;
    border-style: solid;
    border-width: 2px;
    font-size: 11px;
    height: 28px;
    padding: 6.5px;
    position: absolute;
    right: -17px;
    text-indent: 9999px;
    top: -17px;
    width: 28px;
    z-index: 1;
    &::before {
      color: white;
      float: left;
      text-indent: 0;
    }
  }
  #colorbox {
    border: none;
  }
}

// IE8
.ie8 {
  #cboxOverlay {
    background: image-url('/media/images/global/overlay.png');
  }
}

.ie8 {
  #cboxLoadingGraphic {
    display: none !important;
  }
}

.ie8 {
  .us-ie-upgrade__content {
    width: 668px;
  }
}
