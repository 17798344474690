.section-product-satisfaction {
  .site-content {
    .page-header {
      &__inner {
        margin-top: 50px;
        max-width: 1320px;
      }
    }
  }
}
