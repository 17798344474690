.product-fave-confirm {
  text-align: center;
  padding-top: 60px;
  &__copy,
  &__copy p {
    font-family: $main-font;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 37px;
  }
  &__copy p {
    margin: 0;
  }
  &__cta {
    color: $color-pink;
    border-color: $color-pink;
  }
}

#cboxOverlay.product-fave-overlay-cbox {
  background: $color-white;
}

#colorbox.product-fave-overlay-cbox {
  border: solid 2px $color-gray-light;
}
