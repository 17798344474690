//
// Product Landing Node Styles
//

.product-landing__section {
  max-width: 1346px;
  margin: 0 auto;
  position: relative;
}

.product-landing__button {
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  text-transform: uppercase;
  padding: 8px 27px 8px 13px;
  min-width: 130px;
  max-width: 180px;
  @include breakpoint($large-up) {
    font-size: 16px;
    padding: 12px 27px 12px 13px;
    width: 180px;
  }
}

// Header Section
.product-landing-header {
  max-width: 1246px;
  margin: 0 auto;
  background-color: #000;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12px 0;
  position: relative;
  @include breakpoint($large-up) {
    padding: 0;
    min-height: 450px;
    @include pie-clearfix;
    overflow: hidden;
  }
}

.product-landing-header__content {
  position: relative;
  z-index: 1;
  @include breakpoint($large-up) {
    position: static;
    z-index: 0;
  }
}

.product-landing-header__copy {
  color: #fff;
  text-align: center;
  margin: 0 0 7px 0;
  @include breakpoint($large-up) {
    width: 58%;
    padding: 5% 30px 25px 30px;
    float: left;
    z-index: 1;
    position: relative;
  }
}

.product-landing-header__headline {
  padding: 0 20px;
  margin: 0 0 13px 0;
  position: relative;
  @include breakpoint($large-up) {
    margin-bottom: 20px;
    padding: 0;
  }
}

.product-landing-header__headline-subhead {
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 5px 0;
  position: relative;
  display: inline-block;
  @include breakpoint($large-up) {
    font-size: 33px;
    line-height: 33px;
    letter-spacing: 0;
    margin-bottom: 12px;
  }
}

.product-landing-header__headline-label {
  display: block;
  position: absolute;
  top: -3px;
  left: -40px;
  z-index: 1;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  @include rotate(-20deg);
  @include breakpoint($large-up) {
    font-size: 34px;
    top: 0;
    left: -130px;
  }
}

.product-landing-header__headline-title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 34px;
  font-weight: 900;
  letter-spacing: -0.03em;
  @include breakpoint($large-up) {
    font-size: 85px;
    line-height: 74px;
  }
  sup,
  span {
    font-size: 8px;
    text-transform: uppercase;
    vertical-align: super;
    position: relative;
    top: -7px;
    letter-spacing: -0.01em;
    @include breakpoint($large-up) {
      font-size: 12px;
      top: -24px;
    }
  }
}

.product-landing-header__headline-description {
  text-transform: uppercase;
  font-size: 17px;
  line-height: 17px;
  @include breakpoint($large-up) {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0;
  }
}

.product-landing-header__image {
  text-align: center;
  position: relative;
  margin: 0 auto 10px auto;
  max-width: 280px;
  @include breakpoint($large-up) {
    margin: 0;
    top: 5%;
    right: -55px;
    width: 43%;
    z-index: 0;
    position: absolute;
    max-width: 660px;
  }
}

.product-landing-header__description {
  font-size: 12px;
  line-height: 14px;
  padding: 0 10px;
  width: 85%;
  margin: 0 auto;
  @include breakpoint($large-up) {
    font-size: 19px;
    line-height: 23px;
  }
  > img {
    max-width: 305px;
    margin: 0 auto;
    position: relative;
    left: -2px;
    @include breakpoint($large-up) {
      max-width: 517px;
    }
  }
}

.product-landing-header__cta {
  text-align: center;
  padding: 0 15px;
  max-width: 480px;
  margin: 0 auto;
  @include pie-clearfix;
  @include breakpoint($large-up) {
    clear: left;
    padding: 0;
    width: 58%;
    margin: 0 0 30px 0;
    max-width: none;
  }
  .button {
    display: block;
    float: left;
    width: 50%;
    @include breakpoint($large-up) {
      float: none;
      display: inline-block;
      font-size: 16px;
      width: 180px;
    }
  }
  .product-landing__button--learn-more {
    width: 48%;
  }
  .product-landing__button--shop {
    margin-left: 5%;
    width: 47%;
    @include breakpoint($large-up) {
      margin-left: 25px;
    }
  }
}

.product-landing-header__copy--right {
  //float: right;
  //width: auto;
}

// Video Section
.product-landing-video {
  max-width: 1246px;
  margin: 0 auto;
  background-position: 75% 207px;
  background-repeat: no-repeat;
  background-size: 440px;
  min-height: 465px;
  @include breakpoint(480px) {
    background-position: 50% 207px;
  }
  @include breakpoint($large-up) {
    background-position: right 0;
    background-size: auto 100%;
    min-height: 500px;
    @include clearfix;
  }
  &--left {
    @include breakpoint($large-up) {
      background-position: 0 0;
    }
  }
  &--center {
    @include breakpoint($large-up) {
      background-position: 50% 0;
    }
  }
}

.product-landing-video__content {
  padding: 10px 0 0 0;
  text-align: center;
  @include breakpoint($large-up) {
    width: 57%;
    padding: 30px 30px 30px 40px;
    float: left;
    z-index: 1;
    position: relative;
  }
  &--right {
    @include breakpoint($large-up) {
      float: right;
    }
  }
  &--center {
    @include breakpoint($large-up) {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
}

.product-landing-video__headline {
  padding: 0 10px;
  color: #000;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: -0.01em;
  margin: 0 0 5px 0;
  @include breakpoint($large-up) {
    font-size: 49px;
    line-height: 49px;
    margin-bottom: 20px;
    padding: 0;
    letter-spacing: 0;
  }
  strong {
    font-weight: 900;
  }
  sup {
    font-size: 8px;
    text-transform: uppercase;
    vertical-align: super;
    position: relative;
    top: -3px;
    @include breakpoint($large-up) {
      font-size: 12px;
      top: -9px;
    }
  }
}

.product-landing-video__videoplayer {
  position: relative;
  z-index: 2;
}

.product-landing-video__image {
  max-width: 320px;
  margin: 0 auto 10px auto;
  display: block;
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -2;
  }
  @include breakpoint($large-up) {
    max-width: 512px;
    margin-bottom: 30px;
  }
}

.product-landing-video__cta {
  @include breakpoint($large-up) {
    max-width: 512px;
    text-align: center;
    margin: 0 auto;
  }
}
/* Curved shadows */

.product-landing-video__image--shadows:before {
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 50%;
  -webkit-box-shadow: 0 0 25px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 25px 7px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 25px 7px rgba(0, 0, 0, 0.5);
  -moz-border-radius: 10px / 100px;
  border-radius: 10px / 100px;
}

.product-landing-video__image--shadows-v2:before {
  right: 0;
}

.product-landing__button--video {
  @include box-shadow(rgba(255, 255, 255, 0.95) 0 -20px 60px 45px);
  @include breakpoint($large-up) {
    @include box-shadow(none);
  }
}

// Content Node
.product-landing-content {
  max-width: 1246px;
  margin: 0 auto;
  background-color: #000;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
  position: relative;
  @include breakpoint($large-up) {
    padding: 0 0 40px 0;
  }
}

.product-landing-content__copy {
  color: #fff;
  text-align: center;
}

.product-landing-content__headline {
  margin: 0 0 13px 0;
  @include breakpoint($large-up) {
    margin: 0 auto 20px auto;
    width: 85%;
    padding: 35px 30px 30px 30px;
  }
}

.product-landing-content__headline-title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 34px;
  font-weight: 900;
  letter-spacing: -0.03em;
  @include breakpoint($large-up) {
    font-size: 85px;
    line-height: 74px;
  }
}

.product-landing-content__image {
  text-align: center;
  margin: 0 auto;
}

.product-landing-content__description {
  font-size: 12px;
  line-height: 14px;
  padding: 0 10px;
  width: 85%;
  margin: 0 auto;
  @include breakpoint($large-up) {
    font-size: 19px;
    line-height: 23px;
  }
}

.product-landing-content__cta {
  text-align: center;
  margin: 0 auto;
}

// Tout
.product-landing-tout {
  max-width: 1246px;
  margin: 0 auto;
  background-color: #000;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 12px 0 0px 0;
  position: relative;
  @include breakpoint($large-up) {
    padding-bottom: 0;
    @include pie-clearfix;
  }
}

.product-landing-tout__image {
  max-width: 280px;
  position: relative;
  @include breakpoint($medium-up) {
    max-width: 320px;
  }
  @include breakpoint($large-up) {
    float: left;
    width: 45%;
    max-width: 705px;
  }
}

.product-landing-tout__price {
  position: absolute;
  bottom: 40%;
  right: 12%;
  font-size: 18px;
  color: #fff;
  @include breakpoint($large-up) {
    right: 14%;
    font-size: 27px;
    bottom: 50%;
    font-weight: normal;
  }
}

.product-landing-tout__copy {
  color: #fff;
  text-align: center;
  padding: 0 20px;
  margin-top: -25px;
  position: relative;
  @include breakpoint($large-up) {
    float: right;
    width: 55%;
    padding: 75px 35px 20px 35px;
    margin-top: 0;
  }
}

.product-landing-tout__headline {
  font-size: 25px;
  line-height: 25px;
  letter-spacing: -0.01em;
  margin: 0 0 5px 0;
  @include breakpoint($large-up) {
    font-size: 49px;
    line-height: 49px;
    margin-bottom: 10px;
  }
}

.product-landing-tout__headline-image {
  display: block;
  margin: 0 auto;
}

.product-landing-tout__description {
  font-size: 12px;
  line-height: 14px;
  @include breakpoint($large-up) {
    font-size: 22px;
    line-height: 26px;
  }
}

.product-landing-tout__cta {
  text-align: center;
  margin: 15px 0 0 0;
  @include breakpoint($large-up) {
    margin-top: 30px;
  }
}

.product-landing-tout__highlights {
  margin-top: -20px;
  text-align: center;
}

.product-landing-tout__highlights-item {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -0.01em;
  font-size: 18px;
  padding: 0;
  margin: 0 -2px 6px 0;
  @include breakpoint($large-up) {
    font-size: 38px;
    margin: 0 2px 15px 0;
  }
  &:after {
    content: '';
    background: url('/media/export/cms/gravitymud-star.png') no-repeat 50%;
    background-size: contain;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0 0 0 3px;
    position: relative;
    top: 0;
    @include breakpoint($large-up) {
      width: 32px;
      height: 32px;
      margin-left: 6px;
    }
  }
  &:first-child {
    &:before {
      content: '';
      background: url('/media/export/cms/gravitymud-star.png') no-repeat 50%;
      background-size: contain;
      width: 16px;
      height: 16px;
      display: inline-block;
      margin: 0 3px 0 0;
      position: relative;
      top: 0;
      @include breakpoint($large-up) {
        width: 32px;
        height: 32px;
        margin-right: 6px;
      }
    }
  }
}
