.password-update-overlay {
  .error_messages {
    color: $color-red;
  }
}

.change-password {
  .profile-password-update {
    &__body {
      position: relative;
    }
    &__header {
      font-size: 22px;
      margin-top: auto;
      margin-bottom: 10px;
    }
    &__rules {
      @include swap_direction(margin, 10px 0);
      li {
        display: inline-block;
        color: $color-red;
        font-style: italic;
        &:before {
          @include swap_direction(margin, 0 6px 0 0);
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 1em;
          line-height: 0;
        }
        &.pass {
          color: $color-green;
          &:before {
            content: $tick;
            color: $color-green;
          }
        }
      }
    }
    &__fieldset {
      margin-top: 20px;
      padding-top: 7px;
      overflow: hidden;
      .form-item {
        width: 100%;
        input[type='password'] {
          width: 100%;
        }
      }
    }
    &__meter-status {
      font-weight: bold;
    }
    &__meter {
      height: 8px;
      border-radius: 4px;
      &--container {
        margin-top: 6px;
        background-color: $color-light-gray;
      }
      &--progress {
        background-color: $color-light-gray;
        width: 0;
        transition: all 0.3s linear;
      }
    }
    &__meter-wrapper {
      .profile-password-update {
        &__strength-list {
          display: inline-block;
          li {
            display: none;
            font-style: italic;
          }
        }
      }
      &.weak,
      &.no-score {
        .profile-password-update {
          &__meter {
            &--progress {
              width: 0;
            }
          }
          &__strength-list {
            .no-score {
              display: inline-block;
            }
          }
        }
      }
      &.bad {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-red;
              width: 25%;
            }
          }
          &__strength-list {
            .bad {
              display: inline-block;
            }
          }
        }
      }
      &.average {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-average;
              width: 50%;
            }
          }
          &__strength-list {
            .average {
              display: inline-block;
            }
          }
        }
      }
      &.good {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-good;
              width: 75%;
            }
          }
          &__strength-list {
            .good {
              display: inline-block;
            }
          }
        }
      }
      &.strong {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-green;
              width: 100%;
            }
          }
          &__strength-list {
            .strong {
              display: inline-block;
            }
          }
        }
      }
    }
    &__action {
      display: flex;
      width: 100%;
      margin: 20px 0;
      input[type='submit'] {
        &::disabled,
        &.disabled {
          color: $color-light-gray;
          background-color: $color-white;
          cursor: not-allowed;
        }
      }
    }
    &__button {
      flex-grow: 1;
      height: 40px;
      &.form-submit {
        &:disabled,
        &.disabled {
          border: 1px solid $color-light-gray;
          color: $color-light-gray;
          background-color: $color-white;
          cursor: not-allowed;
        }
      }
      &:first-child {
        margin-#{$rdirection}: 10px;
      }
    }
    &__loader {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      display: none;
      background: $color-white;
      opacity: 0.5;
      &.show {
        display: block;
      }
    }
    &__loading {
      top: 20%;
      #{$ldirection}: 45%;
      position: absolute;
    }
    &.registration-page__content {
      .profile-password-update__button {
        margin-top: 0;
        width: inherit;
        height: 40px;
        flex-grow: 1;
        font-size: 16px;
      }
    }
  }
  fieldset {
    overflow: visible;
  }
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        #{$rdirection}: 102%;
        width: 31%;
      }
      @include breakpoint($medium-potrait-range) {
        #{$rdirection}: 102%;
        width: 31%;
      }
      @include breakpoint($ipad-pro-portrait-only) {
        width: 37%;
      }
      @include breakpoint($landscape-up) {
        position: absolute;
        #{$rdirection}: 103%;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
        border: 1px solid $color-gray;
      }
      &-checkout {
        width: 100%;
        margin-#{$ldirection}: 8%;
        @include breakpoint($medium-up) {
          width: 42%;
          #{$rdirection}: 79%;
        }
        @include breakpoint($medium-potrait-range) {
          width: 42%;
          #{$rdirection}: 79%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          width: 35%;
          #{$rdirection}: 73%;
        }
        @include breakpoint($landscape-up) {
          #{$rdirection}: 67%;
          width: 30%;
        }
      }
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$ldirection}: 100%;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      &-reset {
        bottom: auto;
        top: 40%;
        @include breakpoint($medium-up) {
          #{$ldirection}: 32%;
          width: 28%;
          position: absolute;
          border: px solid $color-gray;
          top: -51px;
          &:before {
            #{$ldirection}: -5%;
            top: 47%;
            transform: rotate(0);
          }
        }
        @include breakpoint($medium-landscape-range) {
          width: 20%;
        }
        @include breakpoint($landscape-up) {
          width: 21%;
          top: 30%;
          #{$ldirection}: 23%;
        }
      }
      &-gnav {
        width: 55%;
      }
      &-confirm {
        &:before {
          transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          #{$ldirection}: -6%;
        }
        @include breakpoint($medium-up) {
          top: 15%;
          width: 82%;
          position: absolute;
          border: 1px solid $color-gray;
          #{$ldirection}: 106%;
        }
        @include breakpoint($landscape-up) {
          width: 60%;
          top: 55%;
          #{$ldirection}: 102%;
        }
      }
    }
    &__rules {
      column-count: 2;
      @include breakpoint($medium-up) {
        column-count: 1;
      }
      padding: 10px;
      font-size: 12px;
      list-style: none;
      > li {
        display: flex;
        align-items: center;
        padding: 3px 0;
        color: $color-black;
        transition: 0.2s;
        white-space: nowrap;
        &:before {
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 0;
          margin: 0 6px 0 0;
          transition: 0.2s;
        }
        &.pass {
          color: $color--success;
          &:before {
            color: $color--success;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
    }
    &__popup {
      column-count: 2;
      padding: 0;
      border: 0;
      > li {
        white-space: normal;
      }
    }
  }
  .sign-in-page__indent {
    .password-field {
      &__info {
        border: 1px solid $color-gray;
        @include breakpoint($medium-up) {
          width: 55%;
          position: absolute;
          top: -48px;
        }
        @include breakpoint($landscape-up) {
          width: 53%;
          #{$ldirection}: auto;
          top: 35%;
        }
      }
    }
  }
}
