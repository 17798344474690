.product-thumb {
  @include clearfix;
  margin: 0;
  position: relative;
  text-align: center;
  padding: 0;
  /* border-bottom: 1px solid $color-border-gray; */
  border-bottom: medium none;
  .spp__works-with & {
    padding: 0;
  }
  @include breakpoint($medium-up) {
    margin: 0;
    padding: 0;
    height: 409px;
  }
  .slick-dots {
    bottom: 10px;
    li {
      width: 10px;
      height: 10px;
    }
  }
  &__button {
    .button {
      // width: 100%;
      padding: 5px 25px 6px 13px;
    }
    width: 100%;
    bottom: 0;
    position: absolute;
    @include breakpoint($medium-up) {
      bottom: 11px;
      .button {
        // width: auto;
        padding: 5px 27px 6px 13px;
      }
    }
  }
  &__image-link {
    display: block;
    // float: left;
    // width: 47%;
    @include breakpoint($medium-up) {
      float: none;
      width: auto;
    }
  }
  &__image {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  &__abstract {
    float: right;
    // width: 53%;
    height: 130px;
    @include breakpoint($portrait-mobile) {
      height: 150px;
    }
    margin: 20px 0 30px;
    padding: 0;
    position: relative;
    // @include breakpoint($medium-up) {
    float: none;
    // width: auto;
    width: 100%;
    background: $color-white;
    background: rgba(255, 255, 255, 0.95);
    @include breakpoint($medium-up) {
      position: static;
      padding: 20px 0 0;
      height: auto;
      margin: 0;
    }
  }

  // CTA/ABSTRACT ANIMATIONS
  // This gets somewhat complicated, so please read if you find yourself getting
  // confused.
  // 1. Product thumbs can be set to animate just their CTA or their entire
  //    "abstract" (prod name, price, cta, etc).
  // 2. To animate the cta the class .product-thumb--animate-cta is applied to
  //    the containing .product-thumb element. This causes the cta to fade.
  // 3. To animate the entire abstract the class .product-thumb--animate-abstract
  //    is applied to the containing .product-thumb element. This causes the
  //    abstract to fade and fly up 30px over the product image.
  // 4. In either case the CTA or the abstract is hidden by default and shown on
  //    hover for desktop.
  // 5. On touch devices this is all disabled by default, but if you use the
  //    .product-thumb--animate-cta-touch or .product-thumb--animate-abstract-touch
  //    classes it will enable them on touch, meaning the cta/abstract will be
  //    hidden by default and shown only when the user activates it. This is done
  //    by adding an "active" class to the .product-thumb element (you have to
  //    do this yourself with javascript).
  // 6. 3d transforms are used by browsers that support them to switch on
  //    hardware acceleration, older browsers use margin. Browsers that don't
  //    support css3 animations don't get animated at all, they just snap
  //    between each state.
  // 7. None of this applies below the tablet breakpoint.
  @include breakpoint($medium-up) {
    &--animate-abstract,
    &--animate-cta {
      .no-touch & .product-thumb__abstract,
      &-touch .product-thumb__abstract {
        @include transition(margin-bottom 0.3s ease, opacity 0.3s ease, visibility 0.3s ease);
        margin-bottom: -30px;
      }
      .no-touch.csstransforms3d & .product-thumb__abstract,
      .csstransforms3d &-touch .product-thumb__abstract {
        @include transition(transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease);
        //@include translate(0, 30px, 0);
        margin-bottom: 0;
      }
      // Animate margin-bottom property by default
      &.active .product-thumb__abstract,
      .no-touch &:hover .product-thumb__abstract {
        margin-bottom: 0;
      }
      // If css3 3d transforms are available animate those to enable
      // hardware acceleration.
      .csstransforms3d &.active .product-thumb__abstract,
      .no-touch.csstransforms3d &:hover .product-thumb__abstract {
        @include translate(0, 0, 0);
      }
    }

    // When animating the entire abstract:
    .no-touch &--animate-abstract,
    &--animate-abstract-touch {
      // Hide the abstract by default:
      .product-thumb__abstract {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        // Break the abstract out of the layout so it overlays the thumb image:
        position: absolute;
        bottom: 0;
      }
      // Fade in on hover/active:
      &.active .product-thumb__abstract,
      .no-touch &:hover .product-thumb__abstract {
        opacity: 1;
        visibility: visible;
      }
    }
    // When animating just the cta:
    &,
    .no-touch &--animate-cta,
    &--animate-cta-touch {
      // Hide the cta by default:
      .product-thumb__cta {
        @include transition(opacity 0.3s ease, visibility 0.3s ease);
        opacity: 0;
        visibility: hidden;
      }
    }
    // Fade in on hover/active:
    &.active .product-thumb__cta,
    &:hover .product-thumb__cta {
      opacity: 1;
      visibility: visible;
    }
  }
  &__headline {
    @include h8;
    margin: 0;
    &-link {
      font-weight: 500;
      line-height: 18px;
      text-decoration: none;
      @include breakpoint($medium-up) {
        line-height: 21px;
      }
      color: $color-black;
      &:hover {
        // BB-207 - they said to remove the underlined/pink hover state.
        // color: $color-pink;
        color: $color-black;
        text-decoration: none;
      }
    }
  }
  &__subline {
    @include h14;
  }
  &--price {
    @include h8;
    height: 40px;
    line-height: 38px;
    color: $color-black;
    font-weight: 500;
    &.sku-not-discounted {
      .old_price {
        display: none;
      }
      .discount_price {
        display: inline-block;
        padding: 10px 0 0;
        color: $color-black;
      }
    }
    span.old_price {
      @include breakpoint($medium-up) {
        display: inline-block;
        padding: 10px 0 0 0;
        text-decoration: line-through;
        line-height: 19px;
      }
      @include breakpoint($small-down) {
        text-decoration: line-through;
      }
    }
    span.discount_price {
      @include breakpoint($medium-up) {
        color: $color-red;
        line-height: 19px;
      }
      @include breakpoint($small-down) {
        color: $color-red;
      }
    }
  }
  .product-thumb__cta {
    @include breakpoint($medium-up) {
      position: relative;
    }
  }
  .product-thumb__quickshop {
    // border: 2px solid $color-pink;
    height: 34px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    padding: 0;
    @include breakpoint($medium-up) {
      // left: 36px;
      left: 50%;
      top: -55px;
      @include transform(translateX(-50%));
      border: 1px solid $color-black;
      padding: 3px 20px 3px 9px;
      position: absolute;
      // border-bottom: 2px solid $color-pink;
      // padding: 0;
      height: auto;
      line-height: 19px;
      width: auto;
      min-width: 136px;
      text-align: left;
      font-size: 14px;
    }
    @include breakpoint($small-down) {
      letter-spacing: 0.24em;
      &:hover {
        // border: 2px solid $color-pink !important;
        // color: $color-pink !important;
      }
    }
    // &.hideqs {
    // border-color: $color-light-gray;
    // color: $color-light-gray;
    // }
  }
  // Displays on mobile MPP instead of quickshop when the item is non-shaded or
  // palette:
  .product__button--add-to-bag {
    // border: 2px solid $color-black;
    height: 27px;
    line-height: 27px;
    text-align: center;
    // font-size: 12px;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
    color: $color-black;
    padding: 0px 27px 0px 10px;
    width: 100%;
    margin-top: 10px;
    @include breakpoint($medium-up) {
      margin-top: 0;
      padding: 0;
    }
  }
  .product__favorite {
    top: 5px;
    left: 5px;
    text-decoration: none;
    font-size: 14px;
    margin-left: 5px;
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
} //.product-thumb
.no-touch {
  .product-thumb__image-link > div {
    display: none;
    &:first-child {
      display: block;
    }
  }
}
