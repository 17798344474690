.foundation-finder {
  text-align: center;
  padding: 26px 0 34px;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  &__image {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__image--mobile {
    display: block;
    position: relative;
    top: -17px;
    width: 100%;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  &__image--tablet {
    top: 45px;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__image--desktop {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  @include breakpoint($medium-up) {
    border-bottom: solid 1px $color-gray;
    margin-bottom: 25px;
  }
  .section-header {
    margin: 0 auto 14px;
  }
  &__header {
    position: relative;
    padding: 0;
    @include breakpoint($medium-up) {
      height: 354px;
      padding: 0 20px;
      margin: 0 auto;
      max-width: 1024px;
      text-align: left;
    }
  }
  &__title {
    display: none;
    font-family: $main-font;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0.1em;
    line-height: 43px;
    text-transform: uppercase;
    width: 420px;
    margin: 0 0 24px;
    padding: 19px 0 0;
    position: relative;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__subtitle {
    font-family: $main-font;
    font-size: 21px;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    @include breakpoint($medium-up) {
      font-family: $main-font;
      text-transform: none;
      letter-spacing: 0.1em;
      padding: 0;
    }
  }
  &__find-match,
  &__hide,
  .product-filter__label:before {
    @include breakpoint($medium-up) {
      font-family: $main-font;
      text-transform: uppercase;
      color: $color-pink;
      letter-spacing: 0.14em;
      font-size: 18px;
      font-weight: 500;
    }
  }
  &__find-match,
  &__hide {
    cursor: pointer;
    &:after {
      margin: -5px 0 0 13px;
    }
  }
  &__find-match {
    position: absolute;
    bottom: 60px;
    &::after {
      content: '';
      width: 18px;
      height: 18px;
      background: url('#{$base-theme-path}img/icons/src/arrow_down.svg') no-repeat;
      background-size: cover;
      display: block;
    }
  }
  &__hide {
    padding: 40px 0;
    &::after {
      content: '';
      width: 18px;
      height: 18px;
      background: url('#{$base-theme-path}img/icons/src/arrow_up.svg') no-repeat;
      background-size: cover;
      display: block;
    }
  }
  &__quiz {
    display: none;
    position: relative;
    border: solid 1px $color-light-gray;
    margin: 0 20px;
    padding: 0 32px;
    @include breakpoint($landscape-up) {
      margin: 0 auto;
      padding: 0 20px;
    }
    &::before {
      position: absolute;
      z-index: 2;
      color: $color-gray;
      background: $color-white;
      letter-spacing: 0;
      top: -14px;
      left: 50%;
      margin-left: -13px;
      content: '';
      width: 18px;
      height: 18px;
      background: url('#{$base-theme-path}img/icons/src/quickshop_arrow.svg') no-repeat;
      background-size: cover;
      display: block;
    }
  }
  &__mpp-cta,
  &__shop-all-link {
    font-size: 14px;
    letter-spacing: 0.2em;
  }
  &__shop-all-link {
    color: $color-black;
    border-color: $color-black;
  }
  &__footer {
    @include pie-clearfix;
    margin-top: 42px;
  }
  &__tips {
    border-top: solid 1px $color-light-gray;
    border-bottom: solid 1px $color-light-gray;
    padding: 28px 20px;
    margin-bottom: 32px;
    @include breakpoint($landscape-up) {
      float: left;
      width: 68%;
      min-height: 197px;
      margin-bottom: 0;
    }
    &__header {
      font-family: $main-font;
      font-size: 14px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 0 0 20px;
    }
    &__copy {
      font-style: italic;
      font-size: 18px;
      line-height: 1.65;
      letter-spacing: 0.12em;
      margin: 0;
    }
  }
  &__chat {
    background: #eff0f0;
    padding: 18px 28px 20px;
    position: relative;
    @include breakpoint($landscape-up) {
      float: right;
      width: 31%;
      min-height: 197px;
      padding-bottom: 35px;
    }
    &__copy {
      font-size: 18px;
      line-height: 1.47;
      letter-spacing: 0.06em;
      font-family: $main-font;
      font-weight: 500;
      margin: 0 0 10px;
      @include breakpoint($landscape-up) {
        margin: 0;
      }
      &::before {
        font-size: 34px;
        margin-bottom: 10px;
        content: '';
        width: 34px;
        height: 34px;
        background: url('#{$base-theme-path}img/icons/src/livechat.svg') no-repeat;
        background-size: cover;
        display: block;
      }
    }
    &__link-wrapper {
      @include breakpoint($landscape-up) {
        position: absolute;
        left: 0;
        bottom: 15px;
        width: 100%;
      }
    }
    &__link {
      color: $color-pink;
      border-color: $color-pink;
      &:hover {
        color: $color-black;
        border-color: $color-black;
      }
    }
  }
  .content .node-elc-nodeblock {
    @include breakpoint($landscape-up) {
      padding: 28px 0 0;
    }
  }
  .product-filter {
    border-bottom: solid 1px $color-light-gray;
    padding: 28px 0;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    @include breakpoint($landscape-up) {
      float: left;
      width: 50%;
      padding: 0 0 8px;
      border-bottom: none;
    }
    &__label {
      font-size: 20px;
      letter-spacing: 0.1em;
      line-height: 1.53em;
      text-transform: uppercase;
      font-family: $main-font;
      font-weight: 400;
      margin: 0 auto 10px;
      min-height: 98px;
      max-width: 335px;
      &:before {
        display: block;
      }
    }
    &:first-child {
      @include breakpoint($landscape-up) {
        border-right: solid 1px $color-light-gray;
      }
      .product-filter__label:before {
        content: '1.';
      }
    }
    &:last-child .product-filter__label:before {
      content: '2.';
    }
  }
  &__results {
    .product-grid {
      // Show with js after filtering
      display: none;
    }
  }
  &__no-matches {
    display: none;
    color: red;
    padding: 8px 0 28px;
    font-family: $main-font;
    font-weight: 500;
  }
  &--no-matches {
    .foundation-finder__no-matches {
      display: block;
    }
  }
  &__shop-all {
    display: none;
    margin-top: 17px;
  }
  &--filtered {
    .foundation-finder__shop-all {
      display: block;
    }
    .product-filter:last-child {
      border-bottom: solid 1px $color-light-gray;
      padding-bottom: 28px;
      @include breakpoint($landscape-up) {
        padding-bottom: 8px;
        border-bottom: none;
      }
    }
    .content .node-elc-nodeblock {
      margin-bottom: 25px;
      @include breakpoint($landscape-up) {
        border-bottom: solid 1px $color-light-gray;
        padding-bottom: 28px;
      }
    }
  }
}
