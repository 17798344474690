.section-product-satisfaction {
  .site-content {
    .page-header {
      background-color: transparent;
      &__inner {
        margin-top: 50px;
        max-width: 1200px;
      }
    }
    .basic-responsive {
      max-width: 920px;
      margin: 50px auto 200px;
      strong {
        a {
          text-decoration: none;
        }
      }
    }
  }
}
