#search-wrapper {
  #col-1 {
    display: none;
  }
  .loading {
    text-align: center;
    padding-bottom: 20px;
  }
  #summary {
    padding: 10px 0;
    // text-align: center;
    text-transform: uppercase;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
    @include breakpoint($medium-up) {
      p {
        font-size: 21px;
      }
    }
  }
}

// @TODO: I'm not sure this should be here; however, when it's not, and the search results
// grid initially loads, the eqaul-height stuff doesn't work very well becasue the images aren't
// yet loaded. This seems to prevent that issue.
// Since this issues seems to be specific to the search results product grid, I'm going to leave
// it here for now.
.esearch_results--pc {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  padding: 0;
  .product-grid .product-thumb .product-thumb__button {
    display: block;
  }
  .product-thumb {
    &__image {
      width: auto;
    }
    &--price {
      line-height: 17px;
    }
    &__quickshop-cta {
      display: none;
    }
    @include breakpoint($medium-down) {
      height: 420px;
      &__button {
        position: absolute;
        bottom: 0;
      }
    }
  }
  .product-grid__content {
    max-width: 1340px;
  }
  .esearch-page__heading {
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-dark-pink;
    text-align: left;
    padding: 8% 0 0 5%;
    @include breakpoint($medium-up) {
      padding: 25px 0 10px 0;
    }
  }
  .page__breadcrumb {
    padding: 10px 0;
    display: none;
    a {
      font-size: 12px;
      color: $color-gray-dark;
      text-decoration: none;
      text-transform: uppercase;
      &:after {
        content: ' /';
      }
      &:last-child {
        color: $color-black;
        &:after {
          content: '';
        }
      }
    }
  }
  .product-thumb {
    &--price {
      span.old_price {
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
    }
  }
  .product-grid {
    &__item:nth-child(3n + 1) {
      clear: both;
    }
  }
}

.esearch_results--mobile {
  padding: 12px 0;
  .quickshop-wrapper {
    display: none;
  }
  .product-grid__item {
    height: 353px;
  }
  .product-thumb__cta {
    display: none;
  }
}

#product-results {
  .results-header {
    .product-sort-container {
      display: none;
    }
    .results-summary {
      padding: 1% 5% 6%;
      @include breakpoint($medium-up) {
        padding: 0px;
      }
      & > p {
        display: block;
        font-size: 21px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0 0 1%;
      }
    }
  }
}

// This is the main wrapper for the "no results" content
#bestseller-results {
  border-top: 1px solid $color-gray;
  padding-top: 40px;
}
