$country-select-height: 38px;
$country-select-border: 1px solid  $color-light-gray;

.footer-country-selector {
  position: relative;
  margin-bottom: 1em;
  border: $country-select-border;
  @include breakpoint($small-down) {
    margin: 1em;
  }
  .country {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1em;
    height: $country-select-height;
    user-select: none;
    cursor: pointer;
    font-size: 14px;
    &.selected::after {
      content: "";
      background: url('#{$base-theme-path}img/icons/src/arrow_down.svg') no-repeat 50% 50%;
      background-size: cover;
      width: 18px;
      height: 16px;
      position: absolute;
      right: 0;
      margin: 0 10px;
      display: flex;
      align-items: center;
    }
  }
  .country-list {
    position: absolute;
    top: #{$country-select-height};
    left: 0;
    width: 100%;
    border: $country-select-border;
    background-color: $color-white;
    visibility: hidden;
    .country {
      height: $country-select-height + 2px;
    }
  }
  &.open {
    .country-list {
      visibility: visible;
      z-index: 1;
    }
    .country {
      &::after {
        transform: rotate(180deg);
      }
      &:hover {
        border: 3px solid $color-dark-pink;
        padding: 0 11px;
        color: $color-black;
        &::after {
          @include swap_direction(margin, 0 7px 0 10px);
        }
      }
    }
  }
}
