.product--full {
  .product {
    &__social {
      .icon-facebook {
        width: 27px;
        height: 25px;
      }
      .icon-twitter {
        width: 27px;
        height: 25px;
      }
    }
  }
}
