// Search results pagination styling

#search-wrapper {
  .pagination-container {
    text-align: center;
    .pagination {
      padding: 14px 0;
      margin: 0 auto;
      a {
        text-decoration: none;
        padding: 0 10px 0 0;
      }
      > span {
        margin-left: 10px;
        &.first {
          margin-left: 0;
        }
      }
    }
  }
}
