@mixin giftcard-tab-text {
  font-family: $main-font;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin giftcard-mobile-header {
  font-family: $main-font;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  line-height: 40px;
}

.giftcard {
  &__tabs {
    @include clearfix;
    width: 480px;
    height: 38px;
    margin: 0 auto;
    border-left: 1px solid $color-black;
    border-right: 1px solid $color-black;
    &-container {
      //display: none;
      @include breakpoint($medium-up) {
        max-width: $max-width;
        margin: 8px auto 0 auto;
      }
    }
  }
  &__tab {
    @include giftcard-tab-text;
    float: left;
    height: 38px;
    line-height: 38px;
    background: $color-gray-background;
    text-align: center;
    &--purchase {
      width: 271px;
    }
    &--balance {
      width: 207px;
    }
    &-link {
      @include link-reset;
      display: block;
      line-height: 37px;
      background: $color-white;
      border-left: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      .giftcard__tabs-container--balance & {
        border-left: none;
        border-right: 1px solid $color-black;
      }
    }
  }
  &__container {
    max-width: $max-width;
    margin: 0 auto;
    input[type='text'],
    .egiftcard-message {
      width: 100%;
      @include breakpoint($medium-up) {
        background: $color-light-brown;
        border: 1px solid $color-light-brown;
        font-family: $main-font;
        font-size: 18px;
        &:focus {
          border-color: $color-beige-dark;
        }
      }
    }
  }
  &__description {
    @include breakpoint($medium-up) {
      max-width: $max-width;
      min-height: 285px;
      margin: 0 auto;
      padding: 60px 530px 60px 60px;
      background: url(/media/images/giftcards/giftcard_bg.png) no-repeat top right;
    }
    &-container {
      max-width: $max-width;
      margin: 0 auto;
      background: $color-gray-background;
      @include breakpoint($medium-up) {
        border-top: 1px solid $color-black;
        margin-top: -1px;
      }
    }
    &-header {
      font-family: $main-font;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding: 20px 0;
      margin-bottom: 0;
      text-align: center;
      @include breakpoint($medium-up) {
        font-size: 25px;
        line-height: 28px;
        letter-spacing: 0.24em;
        padding: 0;
        margin: 0 0 16px 0;
        text-align: left;
      }
    }
    &-text {
      background: $color-white;
      text-align: center;
      font-size: 15px;
      line-height: 22px;
      padding: 11px 20px;
      @include breakpoint($medium-up) {
        background: $color-gray-background;
        text-align: left;
        font-size: 18px;
        line-height: 29px;
        padding: 0;
      }
    }
    &-sub-text {
      @include breakpoint($small-down) {
        font-family: $main-font;
        font-weight: 500;
        display: block;
      }
    }
  }
  &__check-balance {
    //mobile only
    @include breakpoint($small-down) {
      @include link-reset;
      @include giftcard-mobile-header;
      position: relative;
      display: block;
      background: $color-nude;
      padding: 0 20px;
      border-top: 1px solid $color-gray-alt;
      border-bottom: 1px solid $color-gray-alt;
      &::after {
        content: '';
        width: 16px;
        height: 16px;
        background: url('#{$base-theme-path}img/icons/src/arrow_right.svg') no-repeat;
        background-size: cover;
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
  &__purchase {
    //mobile only
    @include breakpoint($small-down) {
      @include giftcard-mobile-header;
      border-bottom: 1px solid $color-gray-alt;
      margin: 0 20px;
    }
  }
  &__columns {
    @include clearfix;
  }
  &__images {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      float: left;
      width: 50%;
    }
  }
  &__image {
    display: none;
    width: 100%;
    &--placeholder {
      width: 100%;
    }
  }
  &__step {
    display: inline-block;
    color: $color-pink-red;
    @include breakpoint($medium-up) {
      display: block;
      margin-top: 32px;
      font-size: 22px;
      line-height: 24px;
    }
    &-header {
      display: inline-block;
      @include breakpoint($medium-up) {
        display: block;
        padding: 24px 0 36px 0;
        font-size: 25px;
        line-height: 28px;
      }
    }
    &-container {
      font-family: $main-font;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-top: 12px;
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
    }
  }
  &__selected {
    width: 100%;
    padding: 35px 14px 0 30px;
  }
  &__details {
    width: 100%;
    padding: 0 20px;
    @include breakpoint($medium-up) {
      float: left;
      width: 50%;
      padding: 0;
    }
  }
  &__selection {
    display: none;
    padding: 28px 20px 10px 20px;
    margin: 0;
    @include breakpoint($medium-up) {
      padding: 50px 20px;
    }
    .giftcard__theme {
      padding: 1px;
      .giftcard_slide_image {
        margin: 0 auto;
        border: 3px solid $color-white;
        border-radius: 8px;
        height: 75px;
        width: 125px;
        cursor: pointer;
        @include breakpoint($medium-up) {
          padding: 0 2px;
        }
        &.selected {
          box-shadow: 0 0 0 1px $color-black;
        }
      }
    }
    .slick-prev,
    .slick-next {
      &:before {
        font-size: 30px;
      }
    }
    .slick-prev {
    }
    .slick-next {
      left: 486px;
      padding: 2px 10px 2px 0;
      &:before {
        right: auto;
      }
    }
  }
  &__value {
    margin: 0 10px;
    border-top: 1px solid $color-gray-alt;
    border-bottom: 1px solid $color-gray-alt;
    @include breakpoint($medium-up) {
      padding-bottom: 50px;
    }
  }
  &__sku-select {
    width: 100%;
    margin: 20px 0 14px 0;
    @include breakpoint($medium-up) {
      display: none !important;
      margin: 0;
    }
    @include breakpoint($small-down) {
      display: block;
    }
  }
  &__price {
    @include clearfix;
    margin-top: 34px;
    border-top: 1px solid $color-black;
    border-right: 1px solid $color-black;
    &-option {
      float: left;
      width: 25%;
      padding: 0 4px;
      font-family: $main-font;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-black;
      text-align: center;
      border-left: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      cursor: pointer;
      &.selected {
        background: $color-black;
        color: $color-white;
      }
    }
  }
  //inputs
  &__info {
    @include clearfix;
    margin: 0 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-gray-alt;
  }
  &__email {
    margin: 20px 0 14px 0;
    @include breakpoint($medium-up) {
      float: left;
      width: 48%;
      margin: 0 0 14px 0;
    }
  }
  &__confirm-email {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      float: left;
      width: 48%;
      margin: 0 0 14px 4%;
    }
  }
  &__to {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      clear: left;
      float: left;
      width: 48%;
    }
  }
  &__from {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      float: left;
      width: 48%;
      margin: 0 0 14px 4%;
    }
  }
  &__message {
    clear: both;
    .egiftcard-message {
      min-height: 192px;
      padding: 10px;
    }
  }
  &__button {
    padding: 14px 10px;
    width: 100%;
    @include breakpoint($medium-up) {
      padding: 30px 0;
      width: auto;
    }
    input[type='submit'] {
      @include input-button--secondary;
      display: block;
      margin: 0 auto;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
  &__guides {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      margin-bottom: 20px;
    }
    &-section {
      background: $color-gray-background;
      border-top: 1px solid $color-white;
      border-bottom: 1px solid $color-white;
    }
    &-header {
      height: 58px;
      padding-left: 20px;
      font-family: $main-font;
      font-weight: 500;
      font-size: 14px;
      line-height: 58px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-black;
      background-color: $color-gray-background;
      cursor: pointer;
      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background: url('#{$base-theme-path}img/icons/src/play.svg') no-repeat;
        background-size: cover;
        display: block;
        font-size: 20px;
        margin: -3px 5px 0 0;
        .giftcard__guides--is_open & {
          @include transform(rotate(90deg));
          margin: 0 5px 0 0;
        }
      }
    }
    &-text {
      @include opacity(0);
      @include transition(opacity, 0.3s ease-out);
      overflow: hidden;
      padding: 0 40px 0 50px;
      height: 0;
      ul {
        margin-bottom: 30px;
      }
      p:last-child,
      ul:last-child {
        margin-bottom: 0;
      }
      .giftcard__guides--is_open & {
        height: auto;
        padding: 0 40px 30px 50px;
        @include opacity(1);
      }
    }
    &-link {
      color: red;
    }
  }
  &-retrieve {
    &__container {
      @extend .giftcard__container;
      padding-top: 40px;
    }
    &__container {
      overflow: hidden;
      width: auto;
    }
    &__sidebar {
      width: 194px;
      padding: 20px 20px 12px 20px;
      margin-bottom: 30px;
      margin-right: 28px;
      background: $color-gray-background;
      float: left;
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
      }
      @include breakpoint($medium-up) {
        @include h19;
        font-size: 16px;
      }
    }
    &__nav-header {
      display: none;
    }
    &__nav-item {
      @include h20;
      line-height: 22px;
    }
  }
}

.giftcard-balance {
  margin-bottom: 20px;
  @include breakpoint($medium-up) {
    max-width: $max-width;
    margin: 0 auto;
    min-height: 285px;
  }
  &__container {
    max-width: $max-width;
    border-top: 1px solid $color-gray-alt;
    @include breakpoint($medium-up) {
      background: $color-gray-background;
      border-top: 1px solid $color-black;
      margin: -1px auto 7px;
    }
  }
  &__header {
    @include giftcard-mobile-header;
    border-bottom: 1px solid $color-gray-alt;
    margin: 0 20px;
    @include breakpoint($medium-up) {
      padding: 60px 0 38px 0;
      margin: 0;
      border-bottom: 0;
      font-size: 25px;
      line-height: 28px;
      letter-spacing: 0.24em;
      color: $color-black;
      text-align: center;
    }
  }
  &__form {
    text-align: center;
    padding: 0 20px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
    #giftcard_balance {
      @include breakpoint($medium-up) {
        line-height: 32px;
        height: 32px;
      }
    }
    input[type='text'] {
      width: 100%;
    }
    &-number {
      margin-bottom: 14px;
      @include breakpoint($medium-up) {
        display: inline-block;
        min-width: 204px;
        margin: 0;
      }
    }
    &-pin {
      margin-bottom: 14px;
      @include breakpoint($medium-up) {
        display: inline-block;
        margin: 0 0 0 12px;
        min-width: 204px;
      }
    }
    &-button {
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-left: 24px;
        height: 32px;
      }
    }
    input[type='submit'].giftcard-balance__form-submit {
      @include breakpoint($small-down) {
        width: 100%;
        margin-bottom: 20px;
      }
      @include input-button--secondary;
      line-height: 32px;
      height: 32px;
      vertical-align: top;
    }
  }
  &__remaining {
    text-align: center;
    font-family: $main-font;
    font-weight: normal;
    letter-spacing: 0.04em;
    color: $color-black;
    padding: 20px;
    font-size: 20px;
    @include breakpoint($medium-up) {
      padding: 38px 0 0 0;
      font-size: 37px;
      line-height: 43px;
    }
  }
  &__amount-text {
  }
  &__amount {
    color: $color-pink;
  }
  &__purchase {
    // mobile only
    @include breakpoint($small-down) {
      @include link-reset;
      @include giftcard-mobile-header;
      position: relative;
      display: block;
      background: $color-nude;
      padding: 0 20px;
      border-top: 1px solid $color-gray-alt;
      border-bottom: 1px solid $color-gray-alt;
      &::after {
        content: '';
        width: 16px;
        height: 16px;
        background: url('#{$base-theme-path}img/icons/src/arrow_right.svg') no-repeat;
        background-size: cover;
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
// Egift card balance
.egiftcard-balance {
  .img-column {
    display: none;
  }
  .errors {
    width: 100%;
    margin: 0 auto 22px;
    @include breakpoint($medium-up) {
      width: 72%;
    }
  }
  .error_messages {
    text-align: center;
  }
  &__content {
    text-align: center;
    width: 100%;
    margin: 0 auto 25px;
    @include breakpoint($medium-up) {
      width: 70%;
      margin-bottom: 40px;
    }
    &--header {
      font-family: $header-font-bold;
      font-size: 14px;
      margin-bottom: 10px;
      letter-spacing: 1px;
      @include breakpoint($medium-up) {
        font-size: 25px;
        margin-bottom: 15px;
      }
    }
    &--subheader {
      font-size: 13px;
      width: 100%;
      line-height: 20px;
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
  &.egiftcard-wrapper .form-column {
    width: 100%;
    .giftcard-required {
      display: none;
    }
    .remaining-balance {
      font-family: $header-font-bold;
      text-align: center;
      font-size: 18px;
      margin-top: 30px;
      @include breakpoint($medium-up) {
        font-size: 25px;
        margin-top: 40px;
      }
    }
    .egift-elements {
      @include breakpoint($medium-up) {
        margin-bottom: 47px;
      }
      .form_element {
        @include breakpoint($medium-up) {
          display: inline;
        }
        input[type='text'] {
          height: 48px;
          width: 90%;
          margin: 0 8px;
          @include breakpoint($medium-up) {
            width: 36%;
          }
        }
      }
    }
    input[type='submit'] {
      background: $color-dark-pink;
      font-family: $header-font-bold;
      font-size: 20px;
      padding: 10px 20px;
      border: none;
      &:hover,
      &:focus,
      &:active {
        color: $color-white;
      }
    }
    fieldset {
      text-align: center;
    }
  }
}
