.social-login {
  &__container {
    text-align: center;
    padding: 0 0 20px;
    margin-top: 20px;
  }
  @include breakpoint($medium-up) {
    &__container {
      margin-top: 0;
    }
  }
  &__divider {
    @include h13;
    width: 100%;
    margin: 0 auto 20px;
    @include breakpoint($small-up) {
      margin: 0 auto 20px;
    }
    &:before {
      background: $color-black;
      content: '';
      display: inline-block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 43%;
      width: 100%;
    }
  }
  /* Email optin */
  &__email-opt-in {
    padding-top: 22px;
    text-align: left;
    padding-left: 0;
    @include breakpoint($landscape-up) {
      padding-left: 13%;
    }
  }
  &__terms {
    margin-top: 15px;
    padding: 0;
    text-align: left;
    @include breakpoint($landscape-up) {
      padding-left: 13%;
    }
    p {
      margin: 0;
    }
  }
  &__title,
  &__info {
    display: none;
  }
}
/* GNAV styling */
.site-utils__dropdown__content {
  .social-login {
    &__container {
      padding: 22px 28px 0 24px;
    }
  }
}
/* Disconnect button styling */
#facebook_disconnect {
  display: inline;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
    float: none;
    margin: 0 20px;
  }
}
/* Order confirmation page */
.checkout-confirmation-page {
  .confirmation-panel {
    .social-login {
      padding-left: 20px;
      &__container {
        text-align: left;
        padding-bottom: 0;
        padding-top: 40px;
      }
      &__email-opt-in {
        padding-left: 0;
      }
      &__terms,
      &__divider {
        display: none;
      }
      &__title {
        display: block;
      }
      &__info {
        margin-bottom: 10px;
        display: block;
      }
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  @include breakpoint($medium-up) {
    padding-top: 15px;
  }
  margin-top: 30px;
  &__content {
    padding-left: 0;
  }
  &__connect {
    margin-bottom: 30px;
  }
}
/* checkout sign in page */
.checkout {
  &__content {
    .social-login {
      &__email-opt-in,
      &__terms {
        padding-left: 4%;
        @include breakpoint($landscape-up) {
          padding-left: 3%;
        }
      }
    }
  }
}
