$chkt-outer-hspace: 25px;
$chkt-outer-vspace: 15px;
$chkt-inner-vspace: 1em;
$chkt-panel-spacing: 20px;
$color-green: #008000;

// White button with arrow that points to the left
// Used on PC sidebar panel edit buttons (address and cart edits)
@mixin reverse-white-button {
  @include swap_direction(padding, 5px 0.5em 5px 1.5em);
  background: transparent;
  border: 1px solid $color-black;
  color: $color-black;
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    background-color: $color-black;
    color: $color-white;
    &:before {
      @include swap_direction(border-color, transparent $color-white transparent transparent);
    }
  }
  &:before {
    // from _checkout-viewcart.scss
    @include swap_direction(border-color, transparent $color-black transparent transparent);
    @include swap_direction(border-width, 4px 6px 4px 4px);
    border-image: none;
    border-style: solid;
    content: '';
    @if $text-direction == rtl {
      left: auto;
      right: 0;
    } @else {
      left: 0;
      right: auto;
    }
    position: absolute;
    top: 9px;
  }
}

// ------------------------------------------------
// Specific styles that need to be overridden/reset
// ------------------------------------------------

// TODO consider altering opc__* scss files than overriding here

@include breakpoint($small-down) {
  .opc__review {
    .review-panel {
      margin: 0 0 $chkt-outer-hspace 0;
      &.checkout__panel .content {
        input.btn {
          display: block;
          height: 3em;
        }
      }
      .has-giftcard {
        margin: 0;
      }
      .content {
        padding: 0;
      }
      .payment_form {
        margin: 0 $chkt-outer-hspace;
      }
    }
  }

  // Override !important declaration in _checkout-panel-shipping.scss
  // to allow this panel to be hidden
  .opc__shipping,
  .opc__payment,
  .opc__review {
    #viewcart-panel.hidden {
      display: none !important;
    }
    .site-footer .menu__item--lvl-1:first-child {
      margin-top: 0;
    }
  }
  .opc__cart,
  .opc__signin {
    .site-footer .menu__item--lvl-1:first-child {
      margin-top: 60px;
    }
  }

  // Remove arrow, border in cart edit link
  .checkout__sidebar .viewcart-header .edit_button a {
    &,
    &:before {
      border: 0;
    }
  }
  .checkout .expando-block,
  .checkout .expando-block-parent {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    &.expando-block--expanded {
      & > .expando-block__content {
        padding: $chkt-outer-vspace $chkt-outer-hspace;
      }
    }
  }
}

// Specific styles to enable accordion in PC, but ONLY for payment giftcard
.expando-block {
  $expando: &;
  &.payment-giftcard,
  &.payment-giftcard-remove {
    background: $color-gray-background;
    @include breakpoint($medium-up) {
      border: 1px solid $color-gray-border;
    }
    #{$expando}__content {
      height: 0;
      overflow: hidden;
      padding: 0;
      margin-top: 0;
    }
    .expando-block__icon {
      position: relative;
      display: inline-block;
      float: right;
      &::after {
        content: '';
        width: 28px;
        height: 18px;
        background: url('#{$base-theme-path}img/icons/src/arrow_down.svg') no-repeat;
        background-size: cover;
        display: block;
      }
    }
    &.expando-block--expanded {
      .expando-block__content {
        @include breakpoint($medium-up) {
          height: auto;
          padding: 1em 1.3em;
        }
        label {
          text-transform: uppercase;
        }
      }
      .expando-block__icon {
        &::after {
          content: '';
          width: 28px;
          height: 18px;
          background: url('#{$base-theme-path}img/icons/src/arrow_up.svg') no-repeat;
          background-size: cover;
          display: block;
        }
      }
    }
    h2,
    h3 {
      margin-bottom: 0;
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-gray-border;
        padding: 0.8em 1.3em;
      }
    }
  }
}

.shipping-panel,
.payment-panel {
  .new-address {
    .form-item {
      &.postal-code {
        float: left;
        width: 28%;
        margin: 0 2% 0 0;
      }
      &.city {
        float: right;
        width: 40%;
        margin: 0;
      }
      &.state {
        float: left;
        width: 28%;
        margin: 0 2% 0 0;
      }
      @include breakpoint($medium-up) {
        &.postal-code {
          float: left;
          width: 20%;
          margin: 0 2% 0 0;
        }
        &.city {
          float: left;
          width: 38%;
          margin: 0 2% 0 0;
        }
        &.state {
          float: left;
          width: 38%;
          margin: 0;
        }
      }
    }
  }
}

// -----------------------------------------
// New one-page styles for Adyen integration
// -----------------------------------------

@mixin checkout-subtitle {
  @include breakpoint($small-down) {
    border-bottom: 1px solid $color-gray-border;
    line-height: 2;
    padding: 10px $chkt-outer-hspace;
  }
}

// Giftcards form

.giftcard_apply {
  margin-bottom: $chkt-outer-hspace;
  a.white-button {
    border: 0;
    background: none;
    color: $color-black;
    @include breakpoint($medium-up) {
      border: 1px solid $color-black;
      background-color: $color-white;
    }
  }
  .review-panel & .checkout__subtitle {
    @include checkout-subtitle;
  }
  @include breakpoint($medium-up) {
    .has-giftcard {
      margin-bottom: $chkt-outer-vspace;
    }
  }
}

.payment-giftcard {
  $pgc: &;
  margin-bottom: 3em;
  &.expando-block {
    border-bottom: 1px solid $color-gray-border-section;
  }
  &__number {
    @include breakpoint($medium-up) {
      padding-right: 1em;
      float: left;
      width: 50%;
    }
  }
  &__pin {
    @include breakpoint($medium-up) {
      padding-left: 1em;
      float: right;
      width: 50%;
    }
  }
  &__pin.giftcard-pin__content {
    padding-#{$ldirection}: 0;
    width: 100%;
  }
  &__gc-balance-btn,
  &__gc-apply-btn,
  &__gc-remove-btn {
    margin-bottom: $chkt-outer-vspace;
  }
  @include breakpoint($medium-up) {
    &__gc-apply-btn {
      float: right;
      padding-left: 1em;
      width: 50%;
      .btn {
        line-height: 2em;
        width: 100%;
      }
    }
    &__gc-balance-btn {
      float: left;
      padding-right: 1em;
      width: 50%;
      a.btn {
        // override
        line-height: 2em;
        width: 100%;
      }
    }
  }
  &-remove {
    #{$pgc}__number,
    #{$pgc}__pin {
      margin-bottom: $chkt-outer-vspace;
      .label-content {
        font-weight: bold;
      }
    }
  }
}

.gift-card-display {
  color: $color-green;
  font-weight: 700;
  padding: $chkt-outer-vspace 0;
  &:before {
    content: '\2713'; /* check mark */
  }
}

// Payment options form

.payment_form {
  .local-submits .btn-primary {
    height: 3em;
  }

  .payment-option.radio {
    display: block;
    width: 100%;
    & > label {
      border: 1px solid $color-gray-border;
      border-top: 0;
      padding: 1.5em;
      margin: 0;
      &:first-child {
        border-top: 1px solid $color-gray-border;
      }
    }
  }
  .related-media {
    .cc {
      margin-right: 0.5em;
      vertical-align: middle;
    }
    .alipay {
      border: 1px solid $color-gray-border;
      border-radius: 5px;
      height: 26px;
      width: 41px;
    }
    .pp {
      margin-left: 1em;
    }
  }
  .payment-option.radio {
    &:first-child > label {
      border-top: 1px solid $color-gray-border;
    }
    label {
      @include breakpoint($medium-up) {
        display: block;
        width: 100%;
      }
    }
  }

  // Adyen Components
  @include breakpoint($landscape-up) {
    label {
      &.adyen-label-scheme.adyen_payment_type_label,
      &.adyen-label-paypal.adyen_payment_type_label,
      &.adyen-label-alipay.adyen_payment_type_label {
        width: 60%;
      }
    }
  }

  #adyen-components {
    margin: 1em;

    .components-container {
      .adyen-checkout__card__form {
        padding: 1em 0 0;
      }
    }
  }
  .payment-method-label-img {
    width: 72px;
    margin-right: 0.5em;
    vertical-align: middle;
  }
}

// ------------------------------
// Overrides related to Adyen div
// ------------------------------

// Override mixin that moves checkboxes and radio far left offscreen
.chckt-pm__radio-button[type='radio'],
.chckt-checkbox[type='checkbox'] {
  position: inherit;
  left: 0;
}

input.adyen-checkout__card__holderName__input[type='text'] {
  border: 1px solid $color-light-blue;
  border-radius: 6px;
}

.payment-option.radio {
  label {
    &.adyen-checkout {
      &__label,
      &__checkbox {
        margin-bottom: 0;
      }
    }
  }
}

#checkout_payment_review {
  .adyen-payment-method {
    display: block;
    label.adyen_payment_type_label {
      @include swap_direction(padding, 16px 0 8px 24px);
      margin: 0;
      &.single {
        padding: 6px 16px 0;
        .related-media {
          display: none;
        }
        &:before {
          content: none;
        }
      }
    }
    .adyen-checkout__input {
      border-radius: 0;
      border-color: $color-black;
      &--disabled {
        background: $color-white;
        border: none;
      }
    }
  }
  .adyen-component {
    font-family: $main-font;
    @include swap_direction(margin, 16px 0 0 24px);
  }
  .component-error {
    color: $cr19-error;
  }
  .payment-review-message {
    margin-top: 16px;
    p {
      margin-bottom: 0;
    }
    &.submit-message {
      p {
        margin-bottom: 16px;
      }
    }
  }
}

#adyen-payment {
  margin-top: 2em;
  overflow: auto;
  .chckt-sdk {
    font-family: $main-font;
  }
  .chckt-button {
    @include button--secondary;
    height: 3em;
    &--disabled,
    &--disabled:hover {
      background-color: $color-gray-light;
      border: 1px solid $color-gray-light;
      color: $color-gray-dark;
    }
  }
}

// ---------------
// All three steps
// ---------------
.opc__shipping,
.opc__payment,
.opc__review {
  .promo-bca-panel {
    display: none;
  }
}

.default-marker {
  clear: left;
  color: $color-dark-pink;
  display: block;
  font-weight: bold;
}

.billing-address-options {
  .default-marker {
    display: none;
  }
}

.address-name {
  font-weight: bold;
  display: inline-block;
}

.select-address {
  .address-options.form-item {
    label {
      text-transform: none;
      float: #{$ldirection};
    }
    label.address-option-label__new-address {
      text-transform: capitalize;
    }
  }
}

.address-options {
  float: left;
  width: 100%;
  border-bottom: 1px solid $color-black;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.address-option-new-address {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

// ------------
// Sign-in step
// ------------

.opc__signin {
  .sign-in-panel {
    border-bottom: 1px solid $color-gray-border-section;
  }
  .shipping-panel,
  .review-panel,
  .payment-panel {
    display: none;
  }
}

// -------------
// Shipping step
// -------------

.opc__shipping {
  .order-summary-panel {
    padding: 0;
  }
  .shipping-edit-address-content__content {
    margin-bottom: 3em;
  }
  @include breakpoint($medium-up) {
    .order-summary-panel {
      padding: 16px 0;
    }
    .shipping-panel {
      &__title {
        border: 0 !important;
        font-size: 1.2em;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}

// ------------
// Billing step
// ------------

.opc__payment {
  // TODO rename

  input[type='radio'] {
    &,
    &:checked {
      & ~ label {
        &:before {
          font-size: 18px;
          margin-right: 10px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .continue-button-wrapper {
    text-align: right;
  }
  .shipping-panel {
    background-color: $color-gray-background;
    margin: 0;
    .shipping-address-display {
      display: block;
      margin: 0 $chkt-outer-hspace $chkt-outer-hspace;
      padding: 0;
    }
    header {
      @include breakpoint($small-down) {
        margin-bottom: $chkt-inner-vspace;
      }
    }
    &__title,
    a.edit.btn {
      display: none;
    }
    &.checkout__panel {
      display: block;
    }
    .edit.link {
      position: relative;
      float: right;
      right: $chkt-outer-hspace;
      top: 2.3em;
      border-bottom: 1px solid $color-gray-border-section;
      padding: 0;
      line-height: 1;
      z-index: 1;
    }
    .checkout__subtitle {
      @include checkout-subtitle;
      @include breakpoint($small-down) {
        border-top: 1px solid $color-gray-border;
        margin: 0 (-$chkt-outer-hspace);
        font-size: 15px;
      }
    }
    @include breakpoint($medium-up) {
      background-color: $color-white;
      border: 0;
      margin: 30px 0 0;
      .shipping-address-display {
        display: block;
        margin: 0 !important;
        padding: 0;
        width: 100%;
        header {
          border-bottom: 1px solid $color-black;
          margin-bottom: 1em;
          .checkout__subtitle {
            font-weight: 500;
          }
        }
        &__addresses {
          margin-bottom: 0;
        }
      }
      a.edit.btn {
        @include reverse-white-button;
        display: block;
        z-index: 1;
      }
    }
  }
  .address-options__address-container {
    margin-left: 2em;
  }
  .address-option-info {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 1em;
  }
  .address .checkout__subtitle {
    display: none;
  }
  @include breakpoint($medium-up) {
    .collapsed,
    .finished {
      display: none;
    }
    .payment-panel {
      border: 0;
      .checkout__panel-title {
        border: 0;
        font-size: 1.2em;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}

// ------------
// Payment step
// ------------

.opc__review {
  // TODO rename
  &#{&} .payment-giftcard,
  &#{&} .payment-giftcard-remove {
    header {
      display: block;
    }
  }
  .shipping-panel {
    background-color: $color-gray-background;
    margin: 0;
    header {
      @include breakpoint($small-down) {
        margin-bottom: $chkt-inner-vspace;
      }
    }
    .edit.link {
      position: relative;
      float: right;
      right: $chkt-outer-hspace;
      top: 2.3em;
      border-bottom: 1px solid $color-gray-border-section;
      padding: 0;
      line-height: 1;
      z-index: 1;
    }
    .checkout__subtitle {
      @include checkout-subtitle;
      @include breakpoint($small-down) {
        border-top: 1px solid $color-gray-border;
        margin: 0 (-$chkt-outer-hspace);
      }
    }
    @include breakpoint($medium-up) {
      background-color: $color-white;
      border: 0;
      margin: 30px 0 0;
      .shipping-address-display {
        display: block;
        margin: 0 !important;
        padding: 0;
        width: 100%;
        header {
          border-bottom: 1px solid $color-black;
          margin-bottom: 1em;
          .checkout__subtitle {
            font-weight: 500;
          }
        }
        &__addresses {
          margin-bottom: 0;
        }
      }
      a.edit.btn {
        @include reverse-white-button;
        z-index: 1;
      }
    }
  }
  .payment-panel {
    background-color: $color-gray-background;
    margin: 0;
    .edit.link {
      position: relative;
      float: right;
      right: $chkt-outer-hspace;
      top: 1.5em;
      border-bottom: 1px solid $color-gray-border-section;
      padding: 0;
      line-height: 1;
    }
    .address {
      .checkout__subtitle {
        @include checkout-subtitle;
        @include breakpoint($small-down) {
          border-top: 1px solid $color-gray-border;
          margin: 0 (-$chkt-outer-hspace) $chkt-inner-vspace;
        }
      }
    }
    @include breakpoint($medium-up) {
      background-color: $color-white;
      border: 0;
      margin: 30px 0 0;
      .address.block {
        display: block;
        margin: 0 !important;
        padding: 0;
        width: 100%;
        .checkout__subtitle {
          font-weight: 500;
        }
      }
      a.edit.btn {
        @include reverse-white-button;
        z-index: 1;
      }
    }
  }
  .review-panel .checkout__panel-title {
    border: 0;
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
  }
  .shipping-panel .shipping-address-display,
  .payment-panel .address.block {
    display: block;
    margin: 0 $chkt-outer-hspace $chkt-outer-hspace;
    padding: 0;
  }
  @include breakpoint($medium-up) {
    .sign-in-panel {
      display: none;
    }
    .shipping-panel,
    .payment-panel {
      .checkout__panel-title {
        display: none;
      }
    }
    .review-panel {
      border: 0;
      .checkout__subtitle {
        display: block;
      }
    }
  }
  .checkout {
    .payment-giftcard {
      &__gc-remove-btn {
        .gift-removal {
          @include breakpoint($medium-up) {
            position: static;
          }
          width: auto;
          position: relative;
          bottom: 64px;
          border: none;
          background: transparent;
          float: #{$rdirection};
          text-decoration: underline;
          text-decoration-color: $color-gray-border;
          font-weight: bold;
          color: $base-font-color;
          &:hover {
            background: transparent;
            color: $base-font-color;
          }
        }
      }
    }
    .giftcard-notes {
      clear: both;
    }
    .gift-note {
      margin-bottom: 0;
    }
  }
}

// ----------------
// Offer Code Panel
// ----------------

.offer-code-panel {
  .opc__review & {
    display: none;
  }
  .opc__shipping &,
  .opc__payment & {
    margin: 0;
    background-color: $color-gray-border;
    .checkout__subtitle {
      @include checkout-subtitle;
      @include breakpoint($small-down) {
        margin: 0;
      }
    }
    @include breakpoint($medium-up) {
      background: $color-gray-background;
    }
  }
}

// -------------
// Order summary
// -------------

@include breakpoint($small-down) {
  .order-summary-panel {
    background-color: $color-gray-background;
    margin: 0;
    .checkout__sidebar & {
      padding: 0;
    }
    .order-summary {
      &__subtotal-label,
      &__shipping,
      &__total-label {
        text-align: left;
      }
      &__shipping {
        width: 100%;
        select {
          width: 100%;
        }
      }
      &__total-label,
      &__total-value {
        background: $color-gray-background;
        padding: 10px;
      }
      &__total-label {
        padding-left: 10px;
        font-weight: bold;
      }
      &__total-value {
        padding-right: 10px;
      }
      &__content {
        .tax.label {
          text-align: left;
        }
      }
      &-panel {
        &__title {
          @include checkout-subtitle;
          text-align: left;
          .checkout-header {
            &__title__count,
            &__title__count__value {
              display: inline;
            }
          }
        }
      }
      &-panel__label {
        text-align: left;
        width: 65%;
        float: left;
        text-transform: uppercase;
      }
    }
    .discount.label {
      text-align: left;
    }

    // Make the child panels the same width as order summary
    .checkout__panel {
      margin-left: -$chkt-outer-hspace;
      margin-right: -$chkt-outer-hspace;
    }
  }
}

// ---------
// View cart
// ---------

@include breakpoint($small-down) {
  .opc__shipping,
  .opc__payment,
  .opc__review {
    #viewcart-panel {
      background-color: $color-gray-background;
      border-bottom: 1px solid $color-gray-border;
      margin-left: 0;
      margin-right: 0;
      padding-top: 0 !important;
    }
    .viewcart-header .item-count {
      display: none !important;
    }
    .viewcart-header {
      border-top: 1px solid $color-gray-border !important;
      border-bottom: 1px solid $color-gray-border !important;
      padding: $chkt-outer-vspace $chkt-outer-hspace;
      .viewcart-panel__title {
        width: auto;
        padding-bottom: 0;
      }
      .checkout-header__title__count {
        display: inline-block;
      }
    }
    .viewcart-panel {
      &__content {
        margin-left: $chkt-outer-hspace;
        margin-right: $chkt-outer-hspace;
      }
      .edit.link {
        position: relative;
        float: right;
        right: 0;
        top: (-$chkt-outer-vspace);
        border-bottom: 1px solid $color-gray-border-section;
        padding: 0;
        line-height: 1;
      }
    }
    .cart-item {
      &__price {
        float: left;
        width: 75%;
      }
      &__product-name {
        font-size: 14px;
        font-weight: 700;
      }
      &__total {
        margin-top: 0 !important;
      }
    }
  }
}

@include breakpoint($medium-up) {
  .opc__shipping,
  .opc__payment,
  .opc__review {
    .viewcart-panel {
      #shopping-bag-edit {
        // override from _checkout-viewcart.scss
        bottom: auto;
      }
      a.edit.btn {
        @include reverse-white-button;
        top: 10px;
      }
    }
  }
}
