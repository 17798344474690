.product-recommendation {
  padding: 10px 0 65px 0;
  .wrap {
    padding: 0;
  }
  .product-recommendation__headline {
    @include h2;
    text-align: center;
  }
  .product-recommendation__filter-nav {
    text-align: center;
    margin: 0 0 56px 0;
    > li {
      display: inline-block;
      margin: 0 -6px 0 0;
      text-align: center;
      @include h8;
      &:first-child {
        border-left: 1px solid #000;
        .touch & {
          border-left: 1px solid $color-pink;
        }
      }
    }
  }
  .product-recommendation__filter {
    color: #000;
    cursor: pointer;
    height: 32px;
    line-height: 30px;
    display: block;
    border: 1px solid #000;
    border-left: 0;
    padding: 0 27px;
    &:hover {
      //color: $color-pink;
    }
    &.active {
      background: #000;
      color: #fff;
      @include antialiased;
    }
    .touch & {
      border: 1px solid $color-pink;
      border-left: 0;
      color: $color-pink;
      &.active {
        background-color: $color-pink;
        color: #fff;
      }
    }
  }
  .product-recommendation__grid {
    text-align: center;
    min-height: 380px;
    overflow: hidden;
    .mixtup-empty {
      margin: 0;
      &.error {
        color: red;
      }
    }
  }
  .product-recommendation__grid--item {
    display: inline-block;
    vertical-align: top;
    display: none;
    min-width: 120px;
    max-width: 250px;
    text-align: center;
    font-size: 13px;
    margin: 0 -5px 0 0;
    @include breakpoint($medium-up) {
      min-width: 128px;
    }
  }
  .product-thumb {
    position: relative;
    margin: 0;
    .product-thumb__abstract {
      @include opacity(0);
      @include transition(transform 0.3s ease-in, opacity 0.3s ease-in);
      position: absolute;
      bottom: -60px;
      padding: 10px;
      width: 101%;
    }
    .product-thumb__headline {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
    }
    .product-thumb__subline {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
    }
    .product-thumb--price {
      height: 30px;
      line-height: 27px;
      margin: 0 0 3px 0;
    }
    &:hover {
      .product-thumb__abstract {
        @include opacity(1);
        @include transform(translateY(-50px));
      }
    }
  }
}
