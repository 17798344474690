.site-footer {
  @include h9;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
  border-top: 2px solid $color-black;
  @include breakpoint($medium-up) {
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid $color-black;
    .links-shop-about {
      width: 45%;
    }
    .links-behind-terms-guarenteed {
      width: 55%;
      padding-left: 20px;
    }
  }
  .menu__item--lvl-1:first-child {
    @include breakpoint($small-up) {
      margin-top: -20px;
    }
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
  }
  .bb_copyright {
    text-align: left;
    padding: 10px 0;
  }
  &-toggle-device {
    margin-bottom: 15px;
    text-align: left;
    text-transform: uppercase;
    a.toggle-mobile {
      border-bottom: 2px solid $color-black;
      color: $color-black;
      font-family: $main-font;
      font-weight: 500;
      font-size: 14px;
      &.toggle-mobile-alt {
        @include breakpoint($medium-up) {
          display: none !important;
        }
      }
    }
  }
  &__wrap {
    padding: 0;
    margin-top: -30px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
    .checkout-confirmation-page + .site-footer & {
      margin-top: 30px;
      @include breakpoint($medium-up) {
        margin-top: -30px;
      }
    }
  }
  &__links {
    float: none;
    margin: 0;
    width: auto;
    &:last-child {
      .ftr_links:last-child {
        border-bottom: 1px solid #999999;
        @include breakpoint($medium-up) {
          border-bottom: none;
        }
      }
    }
    .ftr_links {
      border-top: 1px solid #999999;
      margin: 0;
    }
    a {
      background: url('/media/images/global/arrow_right_black.png') no-repeat scroll right 7px
        center $color-white;
      display: block;
      font-size: 12px;
      margin-left: 15px;
      margin-right: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @include breakpoint($medium-up) {
      font-weight: 500;
      float: left;
      line-height: 14px;
      width: 50%;
      .ftr_links {
        border-top: medium none;
      }
      a {
        background: none repeat scroll 0 0 $color-white;
        display: inline;
        font-size: 10px;
        margin: 0;
        padding-top: 0;
        padding-bottom: 5px;
        text-transform: uppercase;
        letter-spacing: 0;
      }
    }
  }
  &__middle-row {
    margin: 0 auto 30px;
    @include breakpoint($medium-up) {
      max-width: 1200px;
      padding: 0 50px;
    }
    &.site-footer__trustmark-logo {
      @include breakpoint($medium-up) {
        max-width: 1320px;
      }
    }
  }
  &-section-links {
    font-weight: bold;
    text-align: center;
    a {
      border-right: 2px solid black;
      padding: 0 10px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        border: 0;
      }
    }
  }
  &__main {
    @include pie-clearfix;
    padding: 20px 0;
    text-align: left;
    @include breakpoint($small-up) {
      padding: 0px;
    }
    @include breakpoint($medium-up) {
      display: table;
      width: 100%;
    }
  }
  &__column {
    &:first-child {
      padding: 20px 0 0;
    }
    @include breakpoint($medium-up) {
      display: table-cell;
      width: 33.3%;
      vertical-align: middle;
      padding: 0 20px;
      // border-right: 1px solid $color-light-gray;
      &:first-child {
        padding: 20px 0 16px;
      }
    }
    &:last-child {
      // padding: 10px 20px 0 20px;
      @include breakpoint($medium-up) {
        // border-right: none;
        padding: 6px 0 21px 33px;
      }
    }
    &:nth-last-child(n + 4) {
      @include breakpoint($medium-up) {
        width: 24%;
      }
      &:first-child {
        ~ .site-footer__column {
          @include breakpoint($medium-up) {
            width: 24%;
          }
        }
      }
    }
    .trustmark-logo {
      @include breakpoint($small-up) {
        padding: 20px 0;
        text-align: center;
      }
    }
  }
  a {
    color: $color-black;
    text-decoration: none;
    &:hover {
      color: $color-light-gray;
    }
    @include breakpoint($medium-up) {
      &.store-locator-icon {
        background: url('/media/images/global/store_locator_icon.png') no-repeat 0 0 transparent;
        width: auto;
        height: 17px;
        padding: 2px 0 2px 25px;
        letter-spacing: 0;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 12px;
        display: inline-block;
      }
    }
  }
  .site-footer-store-locator-link {
    @include breakpoint($small-up) {
      margin: 0;
      .ftr_links {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
      a {
        background: url('/media/images/global/arrow_right_black.png') no-repeat scroll right 7px
          center $color-white;
        display: block;
        font-size: 12px;
        padding: 17px 0;
        margin-left: 15px;
        margin-right: 10px;
        text-align: justify;
      }
    }
    @include breakpoint($medium-up) {
      .ftr_links {
        border-top: medium none;
        border-bottom: medium none;
        font-weight: bold;
        text-align: center;
      }
      a {
        background: none repeat scroll 0 0 $color-white;
        display: inline;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.links-shop-about {
  @include breakpoint($small-up) {
    margin-top: 15px;
  }
  @include breakpoint($medium-up) {
    margin-top: 0;
  }
}

///
// Store Locator Link
///
.site-footer-locator-link {
  font-size: 12px;
}

///
// Contact Us Links
///
.site-footer-contact {
  background: #ececec;
  border-bottom: 1px solid $color-light-gray;
  @include breakpoint($medium-up) {
    background: none;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;
    text-align: center;
    padding: 10px 0;
  }
  @include breakpoint($large-up) {
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
  }
  &__menu {
    @include pie-clearfix;
  }
  &__item {
    display: block;
    border-bottom: 1px solid $color-light-gray;
    padding: 20px;
    line-height: 17px;
    @include breakpoint($medium-up) {
      text-align: left;
      display: inline-block;
      margin: 0;
      padding: 0;
      width: 33.3%;
      border: 0;
    }
    &--email,
    &--chat {
      float: left;
      width: 50%;
      padding: 15px 0;
      border: 0;
      @include breakpoint($medium-up) {
        width: 33.3%;
      }
      .site-footer-contact__link {
        padding: 0 20px;
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
      .icon {
        display: block;
        margin-bottom: 7px;
        @include breakpoint($medium-up) {
          display: inline;
          margin-bottom: 0;
        }
      }
    }
    .icon {
      vertical-align: middle;
      @include breakpoint($medium-up) {
        display: inline;
      }
      @include breakpoint($large-up) {
        margin-bottom: 0;
      }
    }
    &--email {
      @include breakpoint($medium-up) {
        padding-left: 17px;
      }
      .site-footer-contact__link {
        border-right: 1px solid $color-light-gray;
        @include breakpoint($medium-up) {
          border: 0;
        }
      }
      .icon {
        font-size: 20px;
        @include breakpoint($medium-up) {
          font-size: 40px;
          left: 0;
          top: -1px;
        }
      }
    }
    &--phone {
      @include breakpoint($medium-up) {
        float: right;
        padding-top: 18px;
        padding-right: 20px;
        padding-left: 15px;
        .site-footer-contact__link {
          letter-spacing: 2px;
        }
      }
      .icon {
        font-size: 29px;
        @include breakpoint($medium-up) {
          font-size: 38px;
          top: -3px;
        }
      }
    }
    &--chat {
      @include breakpoint($medium-up) {
        padding-left: 18px;
      }
      .icon {
        font-size: 22px;
        @include breakpoint($medium-up) {
          font-size: 43px;
        }
      }
    }
  }
  &__link {
    @include h8;
    display: block;
    @include breakpoint($medium-up) {
      white-space: nowrap;
    }
  }
}

///
// Social Links
///
.site-footer-social-links {
  border-bottom: medium none;
  margin-bottom: 0;
  @include breakpoint($medium-up) {
    margin-top: 15px;
    border-bottom: 0;
    margin-bottom: 0;
    padding: 0;
  }
  &__header {
    @include h8;
    margin: 0 0 10px 0;
    width: 100%;
  }
  &__menu {
    padding: 0 15px;
  }
  &__item {
    background-color: $color-black;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
    &:last-child {
      margin-right: 0;
    }
    @include breakpoint($medium-up) {
      margin-right: 2px;
      padding: 1.5px;
    }
    .icon {
      fill: $color-white;
      font-size: 41px;
      height: 40px;
      padding: 8px;
      width: 40px;
      @include breakpoint($medium-up) {
        height: 25px;
        padding: 4px;
        width: 25px;
      }
    }
  }
}

///
// Footer Menu
///
.site-footer__column:nth-child(2) .menu {
  padding: 0 20px 10px 20px;
  border-bottom: 1px solid $color-light-gray;
  margin-bottom: 20px;
  @include breakpoint($medium-up) {
    border-bottom: 0;
    padding: 0;
    margin-bottom: 0;
  }
  &__list {
    @include pie-clearfix;
  }
  &__item {
    @include h9;
    font-family: $main-font;
    font-weight: 400;
    margin-bottom: 13px;
    width: 50%;
    float: left;
    @include breakpoint($medium-up) {
      float: none;
      width: 100%;
    }
    @include breakpoint($large-up) {
      margin-bottom: 7px;
      float: left;
      width: 50%;
    }
  }
}

///
// Language & Locale choosers
///
.locale-select,
.language-select {
  margin-bottom: 10px;
  @include pie-clearfix;
  @include breakpoint($medium-up) {
    display: block;
  }
  &__item {
    float: left;
    border-right: solid 1px $color-black;
    padding-right: 10px;
    margin-right: 10px;
    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }
  &__link {
    @include h9;
    font-family: $main-font;
    &--selected {
      font-family: $main-font;
      &:hover {
        color: $color-black;
        cursor: default;
      }
    }
  }
}

///
// Locale Chooser
///
.locale-select {
  margin-bottom: 30px;
}

///
// Country Chooser
///
.country-select {
  &__menu {
    display: none;
  }
  &__selectbox {
    width: 200px;
    margin: 0 0 22px 0;
    @include breakpoint($large-up) {
      width: 200px;
    }
    &-selectBox-dropdown-menu {
      max-height: 180px;
    }
  }
}

///
// Copyright
///
.site-footer__wrap > .block-template-basic-responsive-v1 {
  padding: 0 20px;
}

.site-fotter-copyright {
  letter-spacing: 0.001em;
  font-size: 10px;
  @include breakpoint($small-up) {
    background: none repeat scroll 0 0 $color-white;
    color: $color-black;
    margin: 0 20px;
    padding: 20px 0;
  }
  @include breakpoint($medium-up) {
    background: none repeat scroll 0 0 $color-black;
    color: $color-white;
    margin: 0;
    padding: 4px 0 5px 0;
  }
}

.site-footer-toggle-device {
  @include breakpoint($small-up) {
    bottom: -25px;
    float: none;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    a.toggle-mobile {
      border-bottom: medium none;
      color: $color-black;
      font-size: 10px;
      padding-bottom: 10px;
      padding-left: 105px;
    }
    a.toggle-mobile:hover {
      color: $color-gray;
    }
  }
  @include breakpoint($medium-up) {
    a.toggle-mobile {
      padding-left: 220px;
    }
  }
}

///
// Sticky elements
///
@include keyframes(stickyOfferBounce) {
  0%,
  20%,
  50%,
  80%,
  100% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(30px));
  }
  60% {
    @include transform(translateY(15px));
  }
  90% {
    @include transform(translateY(5px));
  }
}

.sticky-offer,
.sticky-chat {
  @include transition(bottom 0.2s ease-in);
  @include animation-duration(1s);
  @include animation-fill-mode(both);
  @include antialiased;
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  bottom: -45px;
  padding: 8px 16px 15px;
  text-align: center;
  color: $color-white;
  width: 50%;
  height: 80px;
  border: 1px solid $color-white;
  border-bottom: none;
  &__headline {
    @include h8;
    margin: 0 0 2px;
    padding: 0;
    color: $color-white;
  }
  &__copy {
    font-size: 14px;
    letter-spacing: 0.3em;
    line-height: 20.8px;
    margin: 0;
    padding: 0;
    color: $color-white;
    letter-spacing: 1px;
  }
  &.expanded,
  &:hover {
    bottom: 0 !important;
  }
  &.bounce {
    @include animation-name(stickyOfferBounce);
  }
  .pg-checkout & {
    @include hidden;
  }
}

.sticky-chat {
  left: 0;
  background: $color-black;
  @include breakpoint($medium-up) {
    left: auto;
    right: 220px;
    width: 240px;
  }
  &__icon {
    top: 1px;
    margin-right: 3px;
    font-size: 15px;
    top: -1px;
    position: relative;
    vertical-align: middle;
  }
}

.sticky-offer {
  left: 50%;
  background: $color-black;
  @include breakpoint($medium-up) {
    left: auto;
    right: 16px;
    width: 190px;
  }
}

.sticky-back-to-top {
  @include border-radius(50%);
  @include transition(opacity 0.4s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out);
  opacity: 0;
  text-align: center;
  width: 26px;
  height: 26px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  right: 16px;
  background: $color-white;
  border: solid 1px $color-black;
  display: none;
  pointer-events: none;
  z-index: 0;
  .js-sticky-header & {
    z-index: 100;
    opacity: 1;
    pointer-events: auto;
  }
  &__text {
    @include hide-text;
    display: block;
    line-height: 1px;
  }
  &__icon {
    font-size: 11px;
    position: relative;
    left: 1px;
    top: 2px;
  }
  &:hover {
    border-color: $color-light-gray;
  }
  @include breakpoint($medium-up) {
    display: block;
  }
}
