///
// Forms
///
input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='url'],
input[type='submit'],
textarea {
  @include appearance(none);
  border-radius: 0px;
  outline: 0;
  outline: none;
  border: 0;
  border: 1px solid $color-gray-dark-alt;
  color: $color-gray-dark-alt;
  font-family: $main-font;
  font-size: 13px;
  letter-spacing: normal;
  &:focus {
    border-color: $color-black;
  }
  @include breakpoint($small-down) {
    font-size: 12px;
    border-radius: 5px;
  }
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='url'] {
  height: 25px;
  line-height: 20px;
  padding: 0px 10px;
  @include input-placeholder {
    color: $color-gray-dark;
  }
}

input[type='submit'],
input[type='button'],
button {
  font-family: $main-font;
  background: url('/media/images/global/arrow_right_white.png') no-repeat center right 11px $color-black;
  color: $color-white;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 1px solid $color-black;
  border-radius: 0;
  padding: 5px 27px 6px 13px;
  display: inline-block;
  line-height: normal;
  width: auto;
  overflow: visible;
  &:hover,
  &:focus,
  &:active {
    color: $color-black;
    background: url('/media/images/global/arrow_right_black.png') no-repeat center right 11px $color-white;
  }
  &.button--inverted {
    @include button--inverted;
    border: 1px solid $color-white;
  }
}

input[type='checkbox'] {
  @include input-styled-check('checkbox');
}

input[type='radio'] {
  @include input-styled-check('radio');
}

// iPad specific styles
.touch {
  select {
    @include appearance(none);
    -webkit-border-radius: 0;
    min-width: 150px;
    border: 1px solid #000;
    font-family: $main-font;
    font-size: 13px;
    letter-spacing: normal;
    background: $color-white url('/media/images/global/icon-dropdown.png') no-repeat center right 5px;
    padding: 0 25px 0 10px;
    margin-bottom: 10px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    white-space: nowrap;
  }
}

// Allow only vertical resizing of textareas.
textarea {
  resize: vertical;
  font-size: 13px;
}

label {
  cursor: pointer;
  font-size: 13px;
}

// Remove default fieldset styles.
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Error Messages in RED
input,
select,
a.selectBox,
a.selectbox {
  &.error {
    border: 1px solid $color-red;
  }
}

.error_messages.error li {
  color: $color-red;
  margin-left: 20px;
}

///
// User Login form - custom styling for the user login form, which doesn't follow the normal DOM structure of other forms
///
#user-login {
  text-align: left;
  .device-pc & {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
  }
  .device-mobile & {
    padding: 20px 12px;
  }
  .form-item,
  .form-actions {
    padding-top: 20px;
  }
  .form-item {
    label {
      font-weight: bold;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  .form-text {
    @include breakpoint($medium-up) {
      width: 300px;
    }
  }
  .description {
    font-size: 12px;
  }
  .form-type-textfield {
    background-color: transparent;
    display: block;
  }
}

input[type='email'] {
  width: 100%;
}
