@import 'search_vars';

.header-search {
  @include transition(left 0.3s ease);
  position: fixed;
  width: 100%;
  top: $mobile-nav-height;
  height: 55px;
  padding: 0;
  left: 0;
  z-index: 9991;
  @include breakpoint($medium-up) {
    position: static;
    width: auto;
    height: auto;
  }
  .js-nav-expanded & {
    left: 260px;
  }
  &__close {
    background: transparent;
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 18px;
    text-decoration: none;
    .icon {
      color: $color-black;
      font-size: 20px;
    }
  }
}
