.product-gallery-vertical {
  .spp_wrap {
    .product-gallery {
      &__thumbs {
        display: inline-block;
        height: auto;
        width: auto;
        text-align: left;
        padding: 10px 0 0;
        margin-left: 15px;
      }
      &__thumb {
        margin-left: 7px;
        width: 55px;
        border: none;
        padding-bottom: 12px;
        position: relative;
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
      &__photo--thumb {
        width: 100%;
        border-radius: 50%;
        border: 1px solid $color-light-gray;
      }
      &__photo--large {
        margin-top: 10px;
      }
      @include breakpoint($medium-up) {
        width: 53%;
        margin-top: 0;
        padding-top: 0;
        &__main {
          display: table;
          height: auto;
        }
        &__thumbs {
          width: 55px;
          display: table-cell;
          vertical-align: middle;
          padding: 0;
        }
        &__thumb {
          margin-left: 10px;
          display: block;
        }
        &__photo--large {
          margin-top: 4px;
        }
        &__video--large {
          cursor: pointer;
        }
      }
      @include breakpoint($medium-range1) {
        &__thumbs {
          padding-right: 10px;
        }
      }
      @include breakpoint($xxlarge-range) {
        &__photo--large,
        &__video {
          max-width: 516px;
          margin: auto;
        }
      }
    }
    .product {
      &__size-button,
      &__weight-container {
        display: block;
        margin: 0 auto 15px;
        padding: 8px 0;
        width: 90%;
        font-size: 12px;
        text-transform: none;
        font-weight: normal;
        text-align: center;
        color: $color-dark-pink;
        background: $color-white;
        border: 1px solid $color-dark-pink;
        white-space: normal;
        &.active {
          color: $color-white;
          background: $color-dark-pink;
          border-color: $color-dark-pink;
        }
      }
      &__title {
        font-weight: bold;
      }
      &__price {
        margin-bottom: 7px;
      }
      @include breakpoint($medium-up) {
        &__title {
          font-weight: normal;
        }
        &__details {
          width: 47%;
        }
        &__header {
          margin: 0;
        }
        &__size-button,
        &__weight-container,
        &__qty,
        &__actions {
          width: 160px;
          float: left;
          margin-right: 15px;
          line-height: normal;
        }
        &__qty--button {
          overflow: hidden;
          margin-bottom: 15px;
          .product {
            &__options,
            &__button {
              min-width: 100%;
              margin: 0;
              height: 32px;
            }
          }
        }
        &__actions {
          padding: 0;
        }
        .how-to-use {
          &__wrap {
            border-top: 1px solid $color-dark-pink;
            padding-top: 15px;
            padding-bottom: 40px;
            display: inline-block;
            @include breakpoint($medium-up) {
              width: 100%;
            }
            .spp-tabs__content {
              display: flex;
              justify-content: space-between;
              margin-right: 5%;
            }
          }
          &__title {
            border-bottom: none;
            padding: 10px 0;
            &.active {
              font-weight: bold;
            }
          }
          &__content {
            &.active {
              overflow: auto;
              display: block;
              float: left;
              padding: 10px 10px 0 10px;
              width: 100%;
            }
          }
        }
        @include breakpoint($medium-down) {
          .glam-tips__title,
          .glam-tips__content {
            display: none;
          }
        }
        .spp_customer_reviews {
          clear: both;
        }
      }
    }
    .shade-picker {
      &__selectbox {
        @include breakpoint($medium-up) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.pony_popup_offer_bg {
  &.bg_thankyou {
    #cboxWrapper #cboxContent {
      background: $color-black;
    }
    #cboxLoadedContent {
      .pony-popup-offer {
        background-color: $color-black;
        .popup-offer__heading {
          color: $color-dark-pink;
        }
        .popup-offer__body {
          padding-top: 50px;
        }
      }
    }
    #cboxClose {
      background: none repeat scroll 0 0 transparent;
      &::before {
        content: '';
        width: 14px;
        height: 14px;
        background: url('#{$base-theme-path}img/icons/src/close.svg') no-repeat;
        background-size: cover;
        display: block;
      }
    }
  }
  #cboxWrapper #cboxContent {
    background: $color-white;
  }
  #cboxLoadedContent {
    .pony-popup-offer {
      background-color: $color-white;
      padding: 0;
      &__heading {
        color: $color-dark-pink;
        margin-top: 20px;
        font-size: 30px;
        text-align: center;
        @include breakpoint($small-down) {
          margin-bottom: 15px;
          margin-top: 0;
          line-height: 30px;
        }
      }
      &__body {
        margin: 0;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        .pony-join-mail {
          font-weight: bold;
          margin: 10px;
          font-size: 12px;
          .pony-offer-content {
            color: $color-dark-pink;
          }
        }
      }
      &__sign-up-form {
        .pony-popup-offer {
          &__sign-up-form-error-messages {
            color: $color-red;
          }
          &__submit-wrap {
            input {
              &:first-child {
                margin-left: 0px;
                @include breakpoint($small-port) {
                  margin-right: 0.9em;
                }
                @include breakpoint($small-portup) {
                  margin-right: 1.26em;
                }
                @include breakpoint($medium-up) {
                  margin-right: 25px;
                }
                @include breakpoint($large-up) {
                  margin-right: 15px;
                }
              }
              width: 48%;
              margin: 10px 0;
              @include breakpoint($small-down) {
                margin: 7px 0;
              }
              @include breakpoint($medium-down) {
                width: 47%;
              }
            }
            .pony-popup-offer__email-input {
              width: 100%;
            }
          }
        }
      }
    }
  }
  #cboxClose {
    color: $color-black;
    right: 25px;
  }
}
