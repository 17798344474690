/// External CSS import from typography.com
///
/// This is now being added via template.php

$main-font-light: 'Brandon Text Light', Arial;
$main-font-medium: 'Brandon Text Medium', Arial;
$main-font-bold: 'Brandon Text Bold', Arial;

@import 'overrides/overrides';
@import '../../gg_base/scss/glamglow-base-pc';

// Brandon Text Regular
@font-face {
  font-family: 'Brandon Text';
  src: url('#{$netstorage-fonts-path}Brandon/brandontext_regular-webfont.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

// Brandon Text Light
@font-face {
  font-family: 'Brandon Text Light';
  src: url('#{$netstorage-fonts-path}Brandon/brandontext_light-webfont.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

// Brandon Text Medium
@font-face {
  font-family: 'Brandon Text Medium';
  src: url('#{$netstorage-fonts-path}Brandon/brandontext_medium-webfont.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

// Brandon Text Bold
@font-face {
  font-family: 'Brandon Text Bold';
  src: url('#{$netstorage-fonts-path}Brandon/brandontext_bold-webfont.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: bold;
}

@import 'sections/account';
@import '../../gg_base/scss/50-sections/checkout/opc_updates/opc__update';
@include opc__update__page--checkout;
@include opc_updates__checkout-shipping;
@include opc_updates__checkout-payment;
@import 'sections/checkout';
@import 'sections/checkout-v2';
@import 'sections/contact_us';
@import 'sections/shop_all';
@import 'sections/about';
@import 'sections/satisfaction-guaranteed';
@import 'sections/overlay';
@import 'sections/product-full';
@import 'sections/store_locator';
@import 'sections/poutmud';
@import '../../gg_base/scss/50-sections/cookie_settings_ot';
@import 'components/all';
@import "components/country_select";
@import "components/email_signup";
