.checkout {
  letter-spacing: 0;
  &__sidebar {
    border-top: none;
    .need-help-panel {
      &__content {
        padding-bottom: 30px;
      }
    }
    .shipping.label {
      line-height: 40px;
      .estimated_delivery_date {
        margin-top: 0px;
      }
    }
    .offer-code-panel {
      // padding-top: 10px;
      input.btn[type='submit'] {
        padding: 0 20px 0 10px;
        letter-spacing: 0;
      }
      &__content {
        margin-top: 0;
      }
      .onepage & {
        @include breakpoint($medium-up) {
          margin-top: 12px;
        }
      }
    }
    @include breakpoint($small-down) {
      .cart-item {
        &__desc-container {
          position: relative;
        }
      }
      .viewcart-panel .cart-items {
        margin-left: 0;
        margin-right: 0;
        .cart-item {
          &__desc {
            height: auto;
            margin-bottom: 12px;
          }
          &__size {
            display: none;
          }
          &__total {
            margin-top: -50px;
            position: relative;
            right: 0;
            width: 100%;
            text-align: left;
            line-height: normal;
            float: left;
            padding-left: 0;
            top: 0;
          }
          &__qty {
            width: 33%;
          }
        }
      }
      .order-summary {
        &-panel {
          margin: 0;
          &__title {
            text-align: right;
          }
        }
        &__content div.label {
          &.total {
            font-weight: 400;
          }
        }
      }
    }
    .viewcart-panel {
      letter-spacing: 0;
      &__title {
        padding-bottom: 10px;
        #shopping-bag-title-edit {
          letter-spacing: 0;
        }
      }
      .cart-items {
        .cart-item {
          &__total {
            font-weight: 400;
            font-size: 12px;
            @include breakpoint($medium-up) {
              text-align: right;
              position: static;
              margin-top: 0;
              line-height: 36px;
            }
          }
          &__size {
            color: $color-gray-light-text;
          }
          &__qty {
            font-weight: 400;
            font-size: 12px;
            /* margin-top: 10px; */
          }
          @include breakpoint($medium-up) {
            &__error {
              & p {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
    .viewcart-panel {
      &__title {
        #shopping-bag-title-edit {
          letter-spacing: 0;
        }
      }
      .cart-items {
        .cart-item {
          &__total {
            @include main-font-book;
            font-size: 12px;
            margin-top: 10px;
            @include breakpoint($medium-up) {
              text-align: right;
            }
          }
          &__qty {
            @include main-font-book;
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
    }
    .product {
      border-top: none;
      @include breakpoint($medium-up) {
        margin-top: 0px;
        /* border-top: 1px solid $color-gray-border; */
        &:first-child {
          margin-top: 0px;
          border-top: none;
        }
      }
    }
    .tax.value {
      line-height: normal;
    }
    .order-summary-panel {
      .terms-tooltip {
        .tooltiptext-under {
          @include breakpoint($medium-up) {
            width: 235px;
          }
          &::after {
            @include breakpoint($medium-up) {
              #{$ldirection}: 55%;
            }
          }
        }
      }
    }
  }
  .checkout__panel-title-container {
    text-align: left;
  }
  input[type='submit'] {
    font-size: 14px;
    letter-spacing: 0;
    padding: 5px 24px 5px 10px;
  }
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='number'] {
    @include input-placeholder {
      text-transform: none;
    }
  }
  &__subtitle {
    letter-spacing: 0;
    font-weight: 700;
    font-size: 15px;
  }
  &__content {
    .order-summary-panel {
      border-top: none;
    }
    .viewcart-header .item-count {
      display: none !important;
    }
    .viewcart-panel .cart-items {
      @include breakpoint($small-down) {
        .cart-item {
          &__total {
            padding-top: 0;
            top: inherit;
          }
        }
        .sample .cart-item__remove_column {
          padding: 46px 0 0;
        }
      }
    }
    a.btn.edit {
      background-position: right 11px center;
      padding: 5px 24px 5px 10px;
    }
    @include breakpoint($medium-up) {
      .order-summary__shipping {
        form {
          width: 250px;
        }
      }
    }
  }
  .continue-shopping {
    &:after {
      top: -1px;
      padding-left: 2px;
      font-size: 12px;
    }
  }
  .cart-item__desc-info {
    .color {
      .shade {
        margin-left: 7px;
        float: left;
        line-height: 17px;
      }
      .swatch {
        height: 15px;
        width: 15px;
        @include border-radius(7.5px);
      }
    }
  }
  .viewcart-banners-panel {
    display: none;
  }
}

.viewcart {
  .checkout__sidebar {
    .order-summary-panel {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .offer-code-panel {
      @include breakpoint($medium-up) {
        margin-top: 50px;
      }
      @include breakpoint($small-down) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .promo-bca-panel {
      padding: 12px 0 20px;
      background: $color-white;
      input.promo_bca_button[type='submit'] {
        padding: 0 20px 0 10px;
      }
      .promo-bca-panel__title {
        font-weight: bold;
      }
      .align-right {
        float: left;
      }
      .promo_bca_description {
        width: 51%;
        padding-left: 0px;
      }
      .promo_bca_logo {
        float: right;
        width: 49%;
        position: static;
      }
      .donation_amount {
        float: left;
      }
      .donation_amount_label {
        display: inline-block;
        padding-right: 8px;
        padding-top: 8px;
      }
    }
  }
  &-buttons-panel {
    margin-bottom: 5px;
    a.continue-checkout {
      @include button--secondary;
      @include breakpoint($small-down) {
        padding: 0.75em 1em;
      }
    }
    .eshop-content {
      font-size: 10px;
      margin-left: 22.5%;
      a {
        font-size: 10px;
      }
    }
  }
  &__empty-cart {
    border: none;
  }
  &-banners-panel .banner li {
    @include breakpoint($small-down) {
      padding-right: 0;
    }
  }
  .below-columns {
    border-top: none;
  }
  .viewcart-panel {
    &.active {
      border-top: none;
    }
  }
  .cart-item {
    &__desc-container {
      @include breakpoint($medium-up) {
        display: inline-flex;
      }
      &__price {
        @include breakpoint($medium-up) {
          text-align: $ldirection;
        }
      }
    }
  }
  .order-summary-panel {
    .terms-tooltip {
      bottom: 7px;
      @include breakpoint($medium-up) {
        bottom: 2px;
      }
    }
  }
}

.cart-item {
  &__product-name {
    a {
      letter-spacing: 0;
    }
  }
  &__donation-amount {
    width: 50%;
  }
}

.samples-page {
  letter-spacing: 0;
  .product-size {
    text-align: center;
    margin-top: 5px;
  }
  .sample__shades-container {
    @include breakpoint($medium-up) {
      bottom: 20px;
      text-align: center;
      .shade {
        font-size: 12px;
        float: none;
      }
    }
    .sample__shades {
      border: none;
    }
  }
  .samples-buttons {
    .return-link {
      padding-top: 0;
      @include breakpoint($medium-up) {
        padding-top: 24px;
      }
      a {
        @include l7;
        float: left;
        display: block;
        font-weight: bold;
        text-align: center;
        font-size: 13px;
        letter-spacing: 0;
        margin-top: 20px;
        width: 100%;
        &:after {
          top: -1px;
          padding-left: 2px;
        }
        @include breakpoint($medium-up) {
          margin-top: 5px;
          width: auto;
        }
      }
    }
    input.btn[type='submit'] {
      padding: 0.75em 20px;
    }
    &.bottom {
      @include breakpoint($medium-up) {
        border-top: none;
        padding-top: 28px;
      }
    }
  }
  .skus {
    @include breakpoint($small-down) {
      .sku {
        display: none;
        &:last-child {
          display: block;
        }
      }
    }
  }
  .product-img {
    width: 100%;
  }
  .details {
    width: 100%;
    @include breakpoint($small-down) {
      height: 110px;
      position: relative;
    }
  }
  .samples-panel {
    border: none;
    &__title {
      font-weight: 500;
      text-align: center;
      border-bottom-width: 1px;
      @include breakpoint($medium-up) {
        font-size: 31px;
        padding-bottom: 27px;
        padding-left: 0;
      }
    }
    .product {
      width: 46%;
      padding: 0;
      margin: 0 8% 25px 0;
      padding-top: 10px;
      border-top: none;
      // min-height: 320px;
      @include breakpoint($small-down) {
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      .sample-select-button {
        padding-right: 15px;
        letter-spacing: 0;
      }
      @include breakpoint($medium-up) {
        // width: 40%;
        // margin-left: 20px;
        // margin-right: 20px;
        // padding-top: 0;
        width: 16%;
        min-height: 300px;
        margin: 0 3.9% 40px 0;
        padding: 0;
      }
      .sample-select-button {
        // padding-right: 27px;
        bottom: 0;
        position: absolute;
        padding-right: 0;
        padding-left: 0;
      }
      .product-name {
        font-weight: 400;
      }
      .product-size {
        display: none;
      }
    }
    .product-seperation {
      height: 0;
    }
    .add-button {
      input.btn[type='submit'] {
        letter-spacing: 0;
      }
      @include breakpoint($small-down) {
        float: none;
        margin-bottom: 0;
        /* input.btn[type="submit"] {
          padding: 5px 27px 6px 13px;
        } */
      }
      @include breakpoint($medium-up) {
        margin: 20px 0 0;
      }
    }
    .samples-panel__title {
      font-size: 30px;
      text-transform: uppercase;
      margin-top: 0px;
      line-height: 28px;
      &.dynamic {
        font-size: 20px;
        text-transform: capitalize;
        padding-bottom: 4px;
      }
    }
    .remaining .available {
      padding: 24px;
    }
    .remaining__text--alt {
      font-size: 20px;
      text-transform: capitalize;
      padding-bottom: 4px;
      &.dynamic {
        font-size: 30px;
        text-transform: uppercase;
        margin-top: 0px;
        line-height: 28px;
      }
    }
    .remaining__note {
      display: none;
    }
    .dyn-msg-2 {
      font-size: 20px;
      text-align: center;
      padding-top: 4px;
    }
    .remaining .max {
      padding: 24px;
    }
  }
  .remaining {
    .available,
    .max {
      background: transparent;
      padding: 5px 20px 5px 20px;
      @include breakpoint($medium-up) {
        padding: 24px 0 13px;
      }
    }
    &__text--alt,
    &__header--alt {
      display: block;
    }
    &__date {
      margin-bottom: 20px;
    }
    @include breakpoint($medium-up) {
      &__text {
        font-size: 21px;
        margin: 0;
        &--alt {
          font-size: 21px;
          margin: 0;
        }
      }
      &__date {
        font-size: 21px;
        margin: 0;
      }
    }
  }
}

.remove-sample-container {
  a {
    display: inline-block;
    margin-top: 8px;
    padding-left: 23px;
    @include breakpoint($small-down) {
      padding-left: 20px;
    }
  }
}

.signin-panel__title {
  @include checkout-section-header;
  border-top: none;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0;
  @include breakpoint($small-down) {
    text-indent: 10px;
  }
}

.email-panel__title {
  display: none;
}

.email-panel__title,
.shipping-panel__title,
.payment-panel__title,
.review-panel__title {
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0;
  a {
    font-size: 16px;
  }
  @include breakpoint($small-down) {
    text-indent: 10px;
  }
}

.checkout__panel-title {
  letter-spacing: 0;
}

.checkout__promo-copy {
  text-indent: 10px;
}

.checkout__new-account,
.checkout__return-user {
  padding: 10px;
  border-top: none;
  @include breakpoint($medium-up) {
    width: 55%;
    @include swap_direction(padding, 35px 10px 30px 20px);
    margin-bottom: 40px;
  }
  h3 {
    letter-spacing: 0;
  }
}

.checkout__return-user {
  @include breakpoint($medium-up) {
    width: 55%;
    .form-item {
      width: 44%;
      margin-right: 8px;
      &.password {
        margin-right: 0;
      }
    }
    #checkout_signin-submit {
      left: 20px;
    }
  }
}

.checkout__new-account {
  @include breakpoint($medium-up) {
    .form-item {
      width: 59%;
      &.email-promotions {
        width: 100%;
        padding-bottom: 20px;
        .overlay-link {
          display: none;
        }
      }
    }
  }
}

.shipping-panel {
  &.collapsed {
    border-top: none;
  }
}

.shipping-address-display {
  border-right: none;
}

.privacy-policy-overlay {
  #cboxLoadedContent {
    .site-header,
    .site-footer {
      display: none;
    }
    .site-content {
      .customer-service {
        margin: 0 auto;
        .customer-service-header,
        .customer-service-menu {
          display: none;
        }
        .customer-service-section {
          width: 100%;
          .corporate-privacy {
            border-top: medium none;
          }
        }
      }
    }
  }
}

.payment-panel {
  .payment-option .related-media,
  .payment-control .related-media {
    margin-left: 20px;
    margin-top: 20px;
    display: inline-block;
  }
  .payment-form {
    padding-left: 0;
    padding-right: 0;
    .expires-date__container {
      .expires-date__year {
        margin-top: 20px;
      }
    }
    .cvv {
      input[type='text'] {
        height: 32px;
      }
    }
  }
  #paypal-info {
    @include breakpoint($medium-up) {
      padding-bottom: 15px;
    }
  }
}

.review-panel {
  .content {
    padding-bottom: 20px;
  }
  .submit {
    text-align: right;
  }
}

.offer-code-panel {
  .offer-code__byline {
    display: block;
    margin-bottom: 0;
  }
  .offer-code__one-offer-only {
    display: block;
    margin: 0 0 10px 0;
    font-style: italic;
    color: #888888;
  }
}

.promo-bca-panel {
  input.promo_bca_button[type='submit'] {
    padding: 0 20px 0 10px;
  }
}

.checkout-confirmation-page {
  .confirmation-panel {
    &__content {
      @include breakpoint($medium-up) {
        margin-left: 20px;
      }
    }
    &__title,
    .social-login__container {
      .social-login__title {
        text-indent: 0;
        letter-spacing: 0;
      }
    }
    &__actions {
      a.btn {
        letter-spacing: 0;
      }
    }
  }
  .registration-panel {
    &__title {
      letter-spacing: 0;
    }
  }
}

.onepage {
  .viewcart-panel {
    margin: 0 10px;
    @include breakpoint($medium-up) {
      margin: 0;
    }
  }
}

.remove-sample-container {
  @include breakpoint($small-down) {
    padding: 0;
    position: relative;
    top: 0;
  }
}

.cart-item {
  &__price__mobile-only {
    font-size: 14px;
    @include breakpoint($medium-up) {
      display: none;
    }
    .checkout__sidebar & {
      @include breakpoint($small-down) {
        display: block;
      }
    }
  }
  &.sample &__price__mobile-only {
    display: none;
  }
  &.sample &__qty {
    @include breakpoint($small-down) {
      width: 97px;
      line-height: 30px;
      text-align: left;
    }
  }
  &.sample &__qty-label {
    @include breakpoint($medium-up) {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }
}

.addtobag__favorites {
  display: none;
}

.security-info {
  display: none;
}

.giftcard-check-balance,
.checkout a.btn.edit,
.continue-shopping,
.links-panel .link {
  letter-spacing: 0;
}

.recommended-products-panel {
  display: block;
  .checkout__panel-title {
    &:after,
    &:before {
      border: none;
      width: auto;
      @include breakpoint($medium-up) {
        width: 57px;
      }
    }
  }
  &__title {
    font-size: 17px;
    .start {
      background: transparent url('/media/images/global/icon_start.png') no-repeat scroll center 2px;
      display: inline-block;
      text-indent: -9999px;
      height: 17px;
      width: 14px;
      background-size: 14px 17px;
    }
  }
}

.recommended-product-items {
  .recommended-item {
    @include swap_direction(margin, 0 3% 5% 3%);
    width: 44%;
    min-height: 335px;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0 3% 0 0);
      width: 22.75%;
    }
    .formatted_price {
      text-align: center;
      border: none;
      padding: 0;
    }
    .addtobag {
      text-align: center;
      margin-top: 20px;
    }
    .shopnow {
      margin: 4px 0 0 0;
      text-align: center !important;
    }
    .product_name {
      min-height: 100px;
      @include breakpoint($medium-up) {
        min-height: 75px;
        text-align: center;
      }
      a {
        font-size: 12px;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
      }
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .subtotal {
      margin-top: 20px;
    }
  }
}

#mobile-breadcrumb {
  margin: 20px 0 0;
}

@include breakpoint($small-down) {
  #mobile-breadcrumb {
    margin: 0 10px 10px;
  }
  .checkout a.btn.edit {
    right: 10px;
  }
  .payment-display {
    .checkout__subtitle {
      border-bottom: none;
      margin-bottom: 0;
    }
    .address,
    .payment {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .registration-panel,
  .sign-in-panel,
  .shipping-panel,
  .payment-panel,
  .review-panel {
    margin: 0 10px;
    &.active {
      .checkout__panel-title {
        border-bottom: none;
      }
    }
  }
  .sign-in-panel {
    &.active {
      border-top: none;
    }
  }
  #review-panel {
    &.collapsed {
      .checkout__panel-title {
        border-top: none;
      }
    }
  }
  .checkout__indent {
    padding-#{$ldirection}: 20px;
  }
  .shipping-address-display {
    margin-left: 10px;
  }
  .shipping-edit-content {
    .form-container .address-to-use {
      margin-top: 0;
      margin-bottom: 10px;
    }
    #checkout_shipping_panel {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .payment-panel {
    .payment-option .related-media {
      margin-top: 15px;
    }
    .payment-control.radio {
      margin-bottom: 10px;
    }
    .payment-edit-content {
      margin-top: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .review-panel {
    .messages,
    .content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .checkout__content .guarantee-panel {
    margin-left: 10px;
    margin-right: 10px;
  }
  .viewcart {
    .links-panel {
      margin: 0;
    }
    .viewcart-panel {
      &__title {
        margin-bottom: 0;
      }
    }
    &_subbuttons {
      border-bottom: none !important;
      .button-update-bag {
        background-color: $color-black;
        a.continue-shopping {
          color: $color-white;
          &:after {
            font-size: 9px;
          }
        }
      }
    }
  }
  .customer-service-header {
    margin-bottom: 20px;
    padding: 5px 0;
  }
  .checkout-confirmation-page {
    .banner_top {
      background-size: 100% auto;
      height: 130px;
    }
  }
  .samples-page {
    margin: 0 6.25% 30px;
    .samples-panel__title {
      padding-left: 0;
    }
    .samples-panel__content {
      margin: 0 5%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .checkout {
    &__sidebar {
      .viewcart-header {
        .item-count {
          width: 27%;
        }
        .viewcart-panel {
          &__title {
            width: 42%;
          }
        }
      }
    }
  }
}

// Setting Defaults for Adaptive Placeholder
$adaptive-placeholder: (
  height: 3em,
  margin: 1em,
  border-radius: 3px
);
// Calling adaptive placeholder for input
// code: adplfeature_v1 (used to grep a site for portions of code associated with a given feature)
.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  select {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        margin: map-get($adaptive-placeholder, margin)
      )
    );
    margin-bottom: 0px;
  }
  .select label {
    display: none;
  }
  fieldset {
    overflow: visible;
    max-width: 100%;
  }
  .form-item {
    position: relative;
    overflow: visible;
  }
}

.viewcart,
.samples,
.index,
.confirm {
  .adpl {
    label {
      display: block !important;
      visibility: visible !important;
    }
    select,
    .selectBox {
      height: 3em;
      line-height: 14px;
      padding: 0.5em 1em;
    }
    .selectBox {
      &-label {
        line-height: 3.143em;
      }
      &-arrow {
        line-height: 3.143em;
      }
    }
    .dropdown-select {
      label {
        display: none !important;
      }
    }
  }
  .site-wide-banner {
    display: none;
  }
}

.checkout {
  .shipping-edit {
    &-address-content__content {
      .city-state-zip,
      .phones {
        overflow: visible;
        float: left;
        width: 100%;
      }
    }
  }
  .payment-panel {
    .payment-form {
      .cvv {
        input[type='text'] {
          height: 3em;
        }
      }
      .expires-date {
        &__year {
          margin-top: 0;
        }
      }
    }
  }
}

.confirm {
  .checkout-confirmation-page {
    .registration-panel {
      .checkout-registration__password {
        .label {
          display: block !important;
        }
      }
    }
    .sign-in-confirmation-panel__content {
      .password {
        .label {
          display: block !important;
          @include breakpoint($small-down) {
            font-size: 1em;
            height: 3em;
            line-height: 3em;
            margin-top: 3em;
            margin-top: calc(-3em - 1px);
            margin-bottom: 1em;
            cursor: text;
          }
        }
        input {
          @include border-radius(0px);
        }
      }
    }
  }
}

.social-login {
  &__terms {
    margin-top: 0;
  }
  &__email-opt-in {
    padding-bottom: 20px;
  }
}

.legal-copy {
  clear: both;
  font-size: 12px;
  margin-bottom: 20px;
}
