@mixin opc_updates__checkout-shipping {
  .opc__shipping {
    section.payment-panel,
    section.review-panel,
    section.guarantee-panel {
      display: none;
    }
    .sign-in-panel {
      display: none;
    }
    .links-panel {
      .need-help-panel {
        &__title,
        &__content {
          background: $color-gray-background;
          padding: 15px 17px 10px;
        }
        &__title {
          margin: 0;
          border: none;
        }
        &__content {
          padding: 0 17px 15px;
        }
      }
    }
  }
  .checkout.onepage {
    .postal-code,
    .dropdown-select {
      float: $ldirection;
      width: 27%;
      margin-#{$rdirection}: 6px;
      a {
        height: 3.143em;
      }
    }
    .city {
      float: $rdirection;
      width: 37%;
    }
    .dropdown-select {
      select {
        min-width: 108px;
        height: 44px;
        padding: 0.5em 0.3em;
      }
    }
    .email-panel {
      text-align: #{$ldirection};
      line-height: get-line-height(14px, 18px);
    }
    .country__note {
      font-size: 13px;
    }
    .phones .phone-1 {
      margin-top: 5px;
    }
  }
  .checkout__content {
    .shipping-panel {
      p.edit_shipping_address {
        margin-bottom: 0;
      }
      border: none;
      margin: 0;
      .address-options {
        &.address-option-new-address {
          input {
            display: none;
          }
          label {
            &::before {
              font-size: 20px;
              margin-#{$rdirection}: 10px;
              height: 20px;
              width: 20px;
            }
          }
        }
        input[type='radio'] {
          position: static;
          float: $ldirection;
          visibility: hidden;
          height: 20px;
          width: 20px;
          line-height: 1;
          font-size: 20px;
          #{$ldirection}: 0;
          &::before {
            visibility: visible;
            line-height: get-line-height(14px, 20px);
            position: absolute;
            top: 0;
            #{$ldirection}: 0;
            content: '';
            width: 18px;
            height: 18px;
            background: url('#{$base-theme-path}img/icons/src/radio.svg') no-repeat;
            background-size: cover;
          }
        }
        input[type='radio']:checked {
          &::before {
            content: '';
            width: 18px;
            height: 18px;
            background: url('#{$base-theme-path}img/icons/src/radio_checked.svg') no-repeat;
            background-size: cover;
          }
        }
        label {
          display: inline-block;
          float: $ldirection;
        }
        &__address-container {
          margin-#{$ldirection}: 10px;
          height: auto;
          display: inline-block;
        }
        &__links {
          position: absolute;
          top: 0px;
          #{$rdirection}: 0px;
          a {
            margin: 0 5px 0;
            text-decoration: underline;
            font-size: 12px;
            padding: 0;
          }
        }
      }
      .shipping-address-options {
        label.address-option-label__new-address {
          display: block !important;
          margin-top: 3px;
        }
      }
      .shipping-edit-content {
        padding-bottom: 0;
        float: $ldirection;
        width: 100%;
        .shipping-edit-address-content__content,
        .address-form,
        .select-address {
          float: $ldirection;
          width: 100%;
        }
      }
      .customer-name {
        font-weight: bold;
      }
    }
  }
}
