.site-utils {
  .refer-friend {
    @include main-font-bold;
    @include breakpoint($medium-up) {
      float: left;
      margin: 10px 5px 0 0;
      padding: 0 5px;
      text-transform: uppercase;
      line-height: 10px;
      border-right: 1px solid $color-white;
      a {
        text-decoration: none;
      }
    }
  }
}

.site-footer {
  &__column {
    .refer-friend {
      padding-bottom: 5px;
      a {
        text-decoration: none;
      }
    }
  }
}

@include breakpoint($small-down) {
  body .extole-js-widget-is-modal {
    &.extole-js-widget-view {
      position: fixed;
    }
  }
}
