.extra-illum-moist-balm {
  .product_detail_hero {
    &__img {
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      padding-top: 60px;
      padding-left: 400px;
      max-width: $max-width;
      display: block;
    }
    &__title,
    &__callout,
    &__subhead,
    &__d1,
    &__price,
    &__d2,
    &__head {
      width: 540px;
      text-align: center;
      margin: 0 auto;
    }
    &__body_head {
      height: 75px;
      border-bottom: 1px solid $color-border-gray;
      border-top: 1px solid $color-border-gray;
      margin: 0 auto;
      max-width: $max-width;
      font-size: 22px;
      padding-top: 26px;
    }
  }
  .product_detail_hero__title p {
    font-size: 60px;
    line-height: 1em;
    text-transform: uppercase;
  }
  .product_detail_hero__callout {
    color: $color-border-gray;
    font-family: $main-font;
    font-size: 16px;
    font-weight: 500;
  }
  .product_detail_hero__subhead {
    text-transform: uppercase;
  }
  .product_detail_hero__d1 p {
  }
  .product_detail_hero__price {
    margin: 0 0 30px 0;
    font-size: 16px;
    .button--secondary {
      color: $color-black;
      background-color: transparent;
      border: 1px solid;
      position: relative;
      left: 12px;
    }
  }
  .product_detail_hero__d2 {
    table {
      margin-right: auto;
      width: 100% !important;
    }
    tbody {
      border-bottom: 1px $color-border-gray;
    }
    td {
      border-bottom: 1px $color-border-gray;
    }
  }
  .product_detail_hero__head p {
  }
  .product_detail_hero {
    &__content_item {
      width: $max-width;
      margin: 0 auto;
    }
  }
  .product-steps-product {
    width: 50%;
    height: 350px;
    float: left;
    padding: 24px 24px 24px 0;
    &__product-image {
      width: 200px;
      float: left;
      overflow: hidden;
      img {
        margin: 0 auto;
        max-width: none;
        height: auto;
        vertical-align: bottom;
      }
    }
    &__product-body {
      margin-top: 30px;
      padding-right: 50px;
      .step-number {
        font-size: 22px;
        color: $color-gray-dark;
      }
      .step-title {
        color: $color-gray-dark;
        line-height: 26px;
        margin-top: 9px;
      }
      .product-title {
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 22px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 15px;
      }
      .product-desc {
        font-size: 16px;
        line-height: 24px;
        margin-top: 9px;
      }
      .product-price {
        font-size: 16px;
        margin-top: 18px;
      }
    }
  }
  .prod--PROD26964 {
    border-right: 1px solid $color-border-gray;
    border-bottom: 1px solid $color-border-gray;
  }
  .prod--PROD15703 {
    border-bottom: 1px solid $color-border-gray;
  }
  .prod--PROD20460 {
    border-right: 1px solid $color-border-gray;
  }
  .prod--PROD34408 {
  }
  .callout-alt {
    position: relative;
    left: 18px;
  }
}
