.account {
  .account &__section {
    letter-spacing: 1px;
    padding-top: 50px;
    input[type='submit'] {
      letter-spacing: 0;
    }
  }
  &__return-user {
    .return-user__item a {
      color: $color-gray-light-text;
      font-weight: 500;
      text-decoration: none;
      @include breakpoint($small-down) {
        font-size: 14px;
      }
    }
  }
  &-page__header {
    font-weight: 400;
    letter-spacing: 0.06em;
    padding-bottom: 14px;
    b {
      font-weight: 700;
    }
  }
  .account-past-purchases {
    .shipments-list {
      .product-info {
        &__reviews {
          display: none;
        }
      }
    }
  }
}

.password-request-page {
  letter-spacing: 0;
  input[type='submit'] {
    letter-spacing: 0;
  }
}

.sign-in-page {
  letter-spacing: 0;
  &__login {
    letter-spacing: 0.06em;
    font-weight: 400;
    height: 53px;
    margin-bottom: 11px;
  }
  .section-head__header {
    letter-spacing: 0.06em;
    margin-bottom: 30px;
    @include breakpoint($medium-up) {
      font-weight: 400;
      font-size: 16px;
    }
  }
  input.sign-in-page__submit[type='submit'],
  input.sign-in-page__submit[type='button'] {
    letter-spacing: 0;
    font-weight: 500;
    @include breakpoint($medium-up) {
      font-size: 13px;
      padding: 3px 27px 4px 13px;
      clear: both;
      float: none;
      display: block;
      margin: 0 auto;
    }
  }
}

.new-account {
  &__fieldset {
    @include breakpoint($medium-up) {
      width: 360px;
    }
  }
}

.return-user {
  @include breakpoint($medium-up) {
    &__fieldset {
      width: 360px;
    }
    .account__return-user &__item {
      width: 48.5%;
      &.password_item {
        margin-right: 0;
      }
    }
  }
}

.account-profile,
.account-orders,
.account-address,
.account-payment,
.account-loyalty {
  .section-content {
    letter-spacing: 0;
    font-weight: 500;
  }
  .section-header {
    &__header {
      font-size: 13px;
      color: #444444;
    }
    &__link {
      letter-spacing: 0;
    }
  }
}

.account-address {
  .section-content {
    h4 {
      @include breakpoint($medium-up) {
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }
}

.account-orders {
  @include breakpoint($medium-up) {
    background: none;
    border-top: none;
    width: 33.3%;
    .section-content {
      a {
        text-transform: uppercase;
      }
      .order-detail-item-link {
        display: none;
      }
    }
  }
}

.profile-pic-main #profile_pic_overlay {
  left: -160px;
  display: none;
  h3 {
    display: none;
  }
}

.address-book-page {
  letter-spacing: 0;
  &__header {
    @include breakpoint($medium-up) {
      margin-bottom: 17px;
    }
  }
  .address-book {
    &__header {
      letter-spacing: 0;
      font-weight: 700;
    }
    .section-head {
      letter-spacing: 0;
      @include breakpoint($medium-up) {
        font-size: 14px;
        padding-left: 0;
        margin-bottom: 10px;
      }
    }
    &__others {
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
    }
    &__link {
      letter-spacing: 0;
    }
  }
  .payment-info {
    p.payment-book {
      &__header {
        @include h9;
        padding: 0;
        letter-spacing: 0;
      }
    }
    &__header {
      @include breakpoint($small-up) {
        text-align: left;
        text-indent: 0;
        width: 100%;
      }
      @include breakpoint($medium-up) {
        text-indent: 40px;
        font-weight: 700;
      }
    }
    &__content {
      .section-head {
        letter-spacing: 0;
        @include breakpoint($small-up) {
          font-size: 13px;
          font-weight: bold;
          margin: 30px auto;
          padding: 0;
        }
      }
    }
    &__link {
      letter-spacing: 0;
    }
  }
  .payment-book {
    &__other {
      border: none;
    }
  }
  .payment-page__required-container {
    margin-left: 40px;
  }
  .address-item__billing-link {
    padding-left: 20%;
    @include breakpoint($small-only) {
      padding-left: 0;
    }
  }
  .default-address,
  .default-payment {
    &__button {
      letter-spacing: 0;
    }
  }
}

.address-form {
  &__item {
    &.ship_type,
    &.default_shipping {
      padding: 10px 20px;
    }
    &.default_shipping * {
      float: none;
    }
  }
  &__submit {
    float: right;
    margin-right: 20px;
  }
  &__head {
    text-align: center;
  }
}

.address-form,
.payment-form {
  letter-spacing: 0;
  input[type='submit'] {
    letter-spacing: 0;
  }
  &__subheading {
    letter-spacing: 0;
  }
  .adyen-checkout__card__holderName {
    .adyen-checkout__input {
      font-size: 14px;
      height: 40px;
      padding: 5px 8px;
    }
  }
}

#myaccount-wrapper {
  @include breakpoint($small-down) {
    .address-form {
      #address {
        padding: 0 20px 10px;
        input {
          border-radius: 0;
        }
      }
      &__item {
        input[type='text'] {
          width: 100%;
        }
        &.state_container,
        &.country_container {
          width: 100%;
        }
        &.btn_submit {
          text-align: center;
          .address-form__submit {
            float: none;
            margin-right: 0;
          }
        }
      }
      h1 {
        letter-spacing: 0;
      }
    }
    .breadcrumb {
      margin-top: 20px;
    }
  }
}

.add-payment-page {
  letter-spacing: 0;
  .payment-page__required-container {
    margin-left: 40px;
  }
  .payment-form {
    &__header {
      @include h8;
      border-bottom: 1px solid $color-black;
      border-top: 1px solid $color-gray-light;
      padding: 15px 0;
      margin-bottom: 7px;
      text-align: left;
      display: block;
      margin-top: 10px;
    }
    &__submit {
      margin-left: 40px;
      letter-spacing: 0;
    }
    &__required {
      float: left;
      color: #999;
      font-weight: bold;
    }
    &__item {
      .card_number_field {
        width: 100%;
      }
      label {
        color: #000;
      }
    }
    &__subheading {
      padding-top: 10px;
    }
  }
  .payment-address {
    margin-left: 20px;
    margin-right: 20px;
    &__item {
      input[type='text'] {
        width: 100%;
        border-radius: 0;
      }
      select {
        width: 100%;
      }
      .radio {
        @include breakpoint($small-down) {
          margin-bottom: 10px;
        }
      }
    }
    @include breakpoint($medium-up) {
      margin-left: 40px;
      margin-right: 40px;
    }
    &__header {
      @include h8;
    }
    &__link {
      letter-spacing: 0;
    }
  }
  &__content {
    padding-top: 0;
    .add-payment-page__header {
      width: 100%;
      margin-bottom: 10px;
    }
    .payment-page__required-container {
      margin-left: 20px;
    }
    .payment-submit {
      display: block;
      margin: 0 auto;
      @include breakpoint($small-down) {
        margin-top: 10px;
      }
    }
    @include breakpoint($medium-up) {
      padding-top: 0;
      .payment-page__required-container {
        margin-left: 40px;
      }
    }
  }
  input[type='submit'] {
    letter-spacing: 0;
  }
}

.profile-page {
  &__title_msg {
    border: none;
    margin: 0;
    padding: 0;
  }
  .newsletter-info,
  .sms-promotions {
    @include breakpoint($small-down) {
      margin: 0 20px 0 20px;
    }
  }
  .newsletter-info {
    &__text--new {
      margin-left: 0;
    }
  }
  .sms-promotions {
    margin: 0;
  }
  .sms-info__item {
    margin: 0;
  }
  &__head {
    .profile-page__link {
      margin: 5px 20px;
      @include breakpoint($small-down) {
        margin-top: 20px;
        display: inline-block;
      }
    }
  }
}

.profile-page,
.address-book-page,
.password-request-page,
.password-sent-page,
.password-reset-page,
.past-purchases-page,
.add-payment-page {
  &__header {
    letter-spacing: 0.06em;
    font-weight: 700;
  }
}

.password-request-page,
.password-sent-page,
.password-reset-page {
  .account-utilities__header {
    letter-spacing: 0;
  }
}

.upload-file__value {
  color: #ff4661;
  @include breakpoint($medium-up) {
    color: #000;
  }
}

.favorites-page {
  .my-lists__list-link--add-all {
    color: #000000;
    background-color: transparent;
  }
  .my-lists__list-link--share {
    color: #000;
  }
  .favorite-actions {
    &__link--add {
      margin-bottom: 5px;
      &:before {
        content: '';
      }
    }
  }
}

.messages {
  background: none;
  border: none;
  padding: 0;
}

.account__section,
.add-payment-page {
  .breadcrumb {
    margin-left: 20px;
    margin-top: 20px;
    @include breakpoint($medium-up) {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.payment-delete-confirm {
  .delete {
    &-confirm {
      @include l5;
    }
    &-cancel {
      @include l5;
      margin-left: 50px;
    }
  }
}

.past-purchases {
  &-data-header__item {
    letter-spacing: 0;
  }
  &-page {
    &__no_orders {
      a {
        margin: 0;
      }
    }
  }
}

.order-details-page,
.past-purchases-page {
  letter-spacing: 0;
  .product-info {
    &__re-order,
    &__name a {
      letter-spacing: 0;
    }
    &__size {
      &.active {
        @include breakpoint($small-down) {
          border-top: none;
        }
      }
    }
  }
}

.order-details-page {
  @include breakpoint($medium-up) {
    margin: 145px auto;
  }
}

.favorites-page,
.wishlist-page {
  .product-info {
    &__name a {
    }
  }
}

.order-details {
  letter-spacing: 0;
  .cart-item {
    &__thumb-image {
      width: auto;
    }
  }
}

.account-utilities {
  .sidebar-menu {
    &__item {
      &--account {
        letter-spacing: 0.06em;
        font-weight: 400;
      }
    }
  }
  @include breakpoint($medium-up) {
    padding-top: 7px;
  }
  &__section {
    .sidebar-menu {
      .active {
        border-top: none;
      }
    }
  }
}

.order-confirmation {
  .account-utilities {
    float: left;
    margin-right: 28px;
  }
  .cart-item {
    padding-top: 20px;
    &__thumb {
      float: none;
    }
    &__desc {
      margin-top: 10px;
    }
    @include breakpoint($medium-up) {
      &__thumb {
        width: 80px;
        margin-right: 10px;
        float: left;
      }
      &__desc {
        float: left;
        width: 250px;
        margin-left: 10px;
        margin-top: 0;
      }
      &__qty {
        float: left;
        clear: none;
        padding-top: 0;
        margin: 0 10px;
        width: 14%;
      }
      &__total {
        width: 14%;
        float: left;
        line-height: 36px;
      }
    }
  }
  .order-totals {
    @include breakpoint($medium-up) {
      float: right;
      width: 29%;
    }
    tr,
    td,
    th {
      border: 0;
    }
    td {
      padding: 0;
    }
  }
}

.order-details,
.order-confirmation {
  .cart-item {
    &__total {
      @include breakpoint($small-down) {
        float: left;
        margin-top: 9px;
        margin-left: 20px;
      }
    }
    &.sample &__total {
      @include breakpoint($small-down) {
        margin-left: 0;
      }
    }
  }
}

.upload-file {
  margin: 40px auto;
  width: 350px;
  &__button {
    width: 160px;
  }
  &__value {
    padding: 6px 0 5px;
    left: inherit;
    right: 10px;
  }
  #form--profile--field--UPLOADED_FILE {
    width: 160px;
  }
  &__imposter {
    padding: 5px 10px 6px 2px;
    width: 160px;
    margin-left: 0;
  }
  @include breakpoint($small-down) {
    width: 250px;
    margin: 20px auto;
    &__button,
    &__imposter,
    #form--profile--field--UPLOADED_FILE {
      width: 120px;
    }
    &__imposter {
      margin-left: 0;
      padding-left: 0;
    }
    &__value {
      left: inherit;
      right: 0;
      padding: 5px 0 5px;
      margin-left: 0;
      &:hover {
        background: none;
      }
    }
  }
}

.profile-pic-upload {
  input[type='submit'] {
    margin: 0 auto;
    display: block;
  }
  &__help {
    @include breakpoint($small-down) {
      font-size: 13px;
    }
  }
}

.account-mobile-landing-page {
  letter-spacing: 0;
  .account-landing {
    &-menu {
      .account-landing-menu__item a {
        @include breakpoint($small-down) {
          font-weight: 500;
        }
      }
    }
    &-profile__header {
      letter-spacing: 0.06em;
    }
  }
  input[type='button'],
  input[type='submit'] {
    letter-spacing: 0;
  }
  input[type='text'],
  input[type='password'],
  input[type='submit'] {
    height: 30px;
    font-size: 14px;
  }
  label {
    font-size: 14px;
  }
}

.sign-in-page,
.shipping-billing,
.add-payment-page {
  @include breakpoint($small-down) {
    font-size: 14px;
    input[type='text'],
    input[type='password'],
    input[type='submit'] {
      height: 30px;
      font-size: 14px;
    }
    label {
      font-size: 14px;
    }
  }
}

.sign-in {
  .account-links {
    &__favorites {
      display: none;
    }
  }
}

/**
 * /account/email-signup.tmpl page styles
 * which are specific to this page
 */
.account-email-signup {
  &-inner {
    background: transparent url('/media/export/cms/signup-page_mudmess_bg-compressor_ld.png') no-repeat top center;
    background-size: contain;
    //height: 837px;
    height: 987px;
    margin: auto;
    //width: 1080px;
    max-width: 1320px;
    padding-top: 200px;
    @include breakpoint($small-land) {
      padding-top: 300px;
    }
    @include breakpoint($medium-up) {
      padding-top: 425px;
    }
    @include breakpoint($large-up) {
      //padding-top: 260px;
      padding-top: 550px;
    }
    > * {
      margin-left: 20px;
      margin-right: 20px;
      @include breakpoint($medium-up) {
        margin-left: 0;
        margin-right: 0;
        text-align: center;
      }
    }
    .account-discount-text {
      clear: both;
      color: $color-black;
      letter-spacing: 1px;
      text-align: center;
      font-size: 15px;
      .account-brand-name {
        font-weight: bold;
        text-transform: uppercase;
      }
      .account-discount-range {
        color: $color-dark-pink;
        font-weight: bold;
      }
      .account-discount-offer {
        text-transform: uppercase;
        font-weight: bold;
        padding: 15px 0;
      }
      strong {
        font-weight: 400;
        text-transform: uppercase;
        em {
          font-style: normal;
          font-weight: bold;
        }
      }
    }
    h5 {
      clear: both;
      color: #fff;
      font-size: 0.65em;
      font-weight: 200;
      line-height: 1.4em;
      margin: 0;
      text-align: center;
    }
    form {
      margin-top: 5px;
      .row {
        padding: 10px 0;
        .input-cell {
          text-align: center;
          width: 100%;
          input[type='text'],
          input[type='email'] {
            width: 100%;
            margin-bottom: 1em;
            @include breakpoint($small-land) {
              width: 70%;
            }
            @include breakpoint($medium-up) {
              width: 380px;
              margin-bottom: 0;
            }
            @include input-placeholder {
              text-transform: uppercase;
            }
          }
          input[type='submit'] {
            background: $color-dark-pink;
            border: 1px solid $color-dark-pink;
            color: $color-white;
            font-size: 1.1em;
            font-weight: bold;
            height: auto;
            padding: 7px 20px;
            float: right;
            margin-top: 20px;
            @include breakpoint($medium-up) {
              margin-right: 0;
            }
          }
        }
        &.submit-row {
          padding-top: 0;
          margin: 0 auto;
          clear: both;
          color: $color-white;
          width: 70%;
        }
        &.two-cols .input-cell {
          float: left;
          padding-left: 5px;
          text-align: left;
          width: 50%;
          input[type='text'] {
            width: 185px;
            @include breakpoint($small-down) {
              width: 160px;
            }
          }
          &:first-child {
            padding-left: 0;
            padding-right: 5px;
            text-align: right;
          }
        }
        &.three-cols {
          @include breakpoint($medium-up) {
            clear: both;
            width: 70%;
            margin: 0 auto;
            .input-cell {
              float: left;
              width: calc(100% / 3 - 6px);
              margin-left: 6px;
              input {
                width: 100%;
                height: 35px;
              }
            }
          }
          .required-text {
            font-size: 12px;
            color: $color-gray-dark-alt;
            margin: 0 0 10px;
            @include breakpoint($small-land) {
              padding-left: 15%;
              padding-right: 15%;
            }
            @include breakpoint($medium-up) {
              padding-left: 0;
              padding-right: 0;
              margin-left: 5px;
            }
          }
        }
      }
      .clearfix {
        clear: both;
      }
    }
  }
  @include breakpoint($large-up) {
    #email_signup_extra {
      fieldset {
        margin-left: 60px;
      }
    }
  }
}

.payment-pm__pm-holder iframe {
  height: 20px !important;
}
