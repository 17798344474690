.product-sku-quickview {
  @include transition(opacity 0.2s ease, visibility 0.2s ease);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: -60px;
  z-index: 1;
  &.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  &__inner {
    margin-bottom: 36px;
    position: relative;
    width: 287px;
    border: solid 1px #acacac;
    padding: 30px 25px;
    &:before {
      @include transform(rotate(180deg));
      position: absolute;
      z-index: 2;
      color: #acacac;
      letter-spacing: 0;
      bottom: -14px;
      left: 50%;
      margin-left: -13px;
      content: '';
      width: 14px;
      height: 14px;
      background: url('#{$base-theme-path}img/icons/src/quickshop_arrow.svg') no-repeat $color-white;
      background-size: cover;
      display: block;
    }
  }
  &__trigger {
    position: relative;
    cursor: pointer;
  }
  &__img {
    width: 100%;
  }
  &__headline {
  }
  &__headline-link,
  &__headline-link:hover {
    color: $color-black;
    text-decoration: none;
  }
  &__info {
    @include pie-clearfix;
    border-top: solid 1px $color-black;
    border-bottom: solid 1px $color-black;
    margin-bottom: 23px;
    font-family: $main-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 35px;
    &--shade {
      float: left;
    }
    &--price {
      float: right;
    }
  }
  &__hex {
    @include circle(19px, #999);
    display: inline-block;
    position: relative;
    top: 5px;
  }
  &__actions {
    text-align: right;
  }
  .product__favorite {
    color: $color-black;
    font-size: 26px;
    margin-right: 9px;
    .icon-heart {
      color: $color-pink;
    }
  }
}
